.processamentoCrivo {
  background-color: #fff;
  width: 100vw;
  position: relative;
  padding: 32px 40px 50px 40px;
  .relatorio {
    position: relative;
    border: 1px solid #e7eaec;
    padding-bottom: 50px;
  }
}
