.btn-config {
  background-color: #bbbdbf;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  line-height: 0px;
  padding: 0px;
  border: none;

  svg {
    height: 9px;
    width: 9px;
    margin: 0px;
  }
  
  .config-icon path {
    fill: #ffffff;
  }

  &:focus {
    outline: 0px none;
  }

  &:hover {
    transition: all .4s ease-in-out;
    background-color: #85635B;
  }

  &:active {
    background-color: #85635B !important;
  }
}