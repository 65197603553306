.campo-email-controle-proposta > .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .ant-form-item-explain.ant-form-item-explain-error {
    margin: 0;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    min-height: 14px;
  }
}

.campo-email-controle-proposta .ant-form-item-label > label {
  display: flex;
  line-height: 19.07px;
  height: 19.07px;
  margin-bottom: 8px;
  color: #686b6d;
  font-family: 'Open Sans', sans-serif;
}

.cardControleProposta > .ibox-title {
  padding: 15px !important;

  .title-label h3 {
    margin-top: 0px;
  }
}

.cardControleProposta > .ibox-content {
  padding: 0 15px 15px !important;
}

.campo-email-controle-proposta {
  width: 100%;
}

.cardControleProposta > .ibox-content > .form-row {
  margin-inline: 0;
}

.nao-exibe-credito {
  display: none;
}

.exibe-credito {
  display: inline;
}

@media only screen and (max-width: 768px) {
  .exibe-credito {
    display: none;
  }

  .nao-exibe-credito {
    display: inline;
  }

  .ant-breadcrumb.acessa-tela-controle-proposta {
    font-size: 13px;
  }
}
