@import '../../styles/base/variables';

.service-card {
  background: #ededed;
  border-radius: 20px;
  height: auto;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 10px;
  color: #263659;

  .title-card {
    margin-bottom: 4px;
  }
  .service-name {
    color: $crefaz-blue;
    font: 600 normal 28px/28px sans-serif;
  }

  .wrapper-button-ver-pacotes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    .btn-ver-pacotes {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      width: 100%;

      color: #fff;
      border-radius: 5px;
      padding-left: 20px;
      padding-right: 20px;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      background-color: #25A8A7;

      &:hover{
        outline: 2px solid #2ea9fb !important;
        background-color: #1D8A89;;
      } 

      .eye-icon-crefaz-mais {
        margin-left: 10px;
        path {
          fill: #fff;
        }
      }
    }
  }
}