.tipo-comissao {
  .ant-radio-checked .ant-radio-inner {
    border-color: #fa6e0a;
  }
  .ant-radio-inner::after {
    background-color: #fa6e0a;
  }
  span.ant-radio + * {
    color: #686b6d !important;
    font-size: 13px !important;
  }
}
.campo-total-comissao {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 1px;
  }

  .ant-input {
    width: 173px;
  }

  .tooltip-info {
    position: relative;
    top: 0px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.toolbar-buttons {
  display: flex;
  align-items: flex-end;
}
