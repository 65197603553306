.ant-btn-primary[disabled] {
  span {
    color: white;
  }
}

.table-tabela-juros {
  div.pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-right: -1.75rem !important;
  }
}

#form-tabela-juros {
  .ant-input-affix-wrapper::before {
    display: none;
    content: '';
  }
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 5px;

  label {
    color: #676A6C;
    font-family: "Open Sans", sans-serif;
  }
}

.tipo-juros {
  color: #676A6C;
  font-family: "Open Sans", sans-serif;
}

.btn-xs-block {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.flex {
  display: flex;
  flex-direction: column;

  .ibox-content {
    height: 100%;
  }
}

.tabela-juros-percentuais {
  .ibox {
    width: 100%;
  }
}

.table-responsive {
  border: 1px solid #D9D9D9;
}