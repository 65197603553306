.select-banco {
    color: #676A6C;
    .arrow-color path {
        color: #212529;
    }
    .ant-select-selector {
        border-radius: 1px;
        border: 1px solid #d9d9d9 !important;
        box-shadow: none !important;
        .ant-select-selection-item {
            .numero {
                display: none;
                position: absolute;
                right: 15px;
            }
        }
    }
}

.ant-select-dropdown {
    padding: 0;
    margin: 0;
    .linha {
        border-bottom: 1px solid #d9d9d9;
        min-height: 0;
        padding: 0;
    }
    .ant-select-item {
        &.ant-select-item-option-selected {
            font-weight: 700 !important;
        }
        &:hover {
            background-color: #ececec;
        }
        .nome {
            padding-right: 10px;
            font-size: 11px;
        }
        .numero {
            width: 30px;
        }
    }
    .ant-select-item-option-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .rc-virtual-list-scrollbar {
        display: none;
    }
    .options {
        color: #686B6D;
        font-size: normal;
        padding: 0 16px 0 6px;
        margin: 0;
        font-size: 11.5px;
        font-weight: 400;
        background-color: #fff;
    }
}

@media (max-width: 768px) {
    .ant-select-dropdown.select-bancos {
        width: 100px;
        .ant-select-item {
            .nome {
                width: 85%;
                overflow: hidden;
            }
        }
    }
}