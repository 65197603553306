.tableTarifa {
  td {
    text-align: center;
  }
  th {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .tableTarifa {
    th:nth-child(3) {
      display: none;
    }

    td:nth-child(3) {
      display: none;
    }

    th:nth-child(4) {
      display: none;
    }

    td:nth-child(4) {
      display: none;
    }

    th:nth-child(5) {
      display: none;
    }

    td:nth-child(5) {
      display: none;
    }

    th:nth-child(6) {
      display: none;
    }

    td:nth-child(6) {
      display: none;
    }

    .table-filter-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
