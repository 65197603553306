.card-estatisticas {
  background-color: white;
  border: 1px solid #e7eaec;
  align-items: center;
  height: 100%;

  font-family: 'Open Sans', sans-serif;

  .card-body {
    padding: 15px 16px;
  }
}

.card-header {
  background-color: white;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  /* or 129% */
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Text/Drop */

  color: #6d757d;
}

.card-text {
  font-style: normal;
  font-size: 29px;
  font-weight: 600;
  line-height: 36px;

  display: flex;
  align-items: center;
}

.img {
  float: left;
  padding-right: 12px;
  display: flex;
}
