.header-calculo-luz-dia {
  padding: 12px 22px;
  border: 1px solid #d9d9d9;
  background-color: #f7f7f7;

  h5 {
    margin: 0px !important;
    margin-bottom: 3px !important;
    padding: 0px !important;
    font-size: 13px !important;
    color: #6d757d !important;
    font-weight: 400 !important;
    line-height: 1.1em !important;
  }
  h3 {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 24px !important;
    color: #445566 !important;
    font-weight: 400 !important;
  }

  .col-disponivel {
    svg.icon {
      float: left;
      margin-right: 17px;
      width: 39px;
    }
    .info-texto {
      display: inline-block;
    }
  }
  .col-operador {
    text-align: left;
    padding-left: 0px;
    svg.icon {
      margin-top: 18px;
      display: inline-block;
    }
  }
  .col-debitos-pagar {
  }
  .col-receber {
  }

  .col-icon-exclamation {
    padding-top: 4px;

    svg {
      width: 39px;
      height: auto;
    }
  }
  .col-texto {
    padding-top: 5px;

    p {
      color: #6d757d;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.4em;
      margin: 0px;
    }
  }
  .col-total-debitos {
    padding-top: 2px;

    .align {
      text-align: left;
      display: inline-block;
      width: auto;
      padding-right: 15px;
    }
  }
  

  @media (max-width: 1400px) {
    
    padding-left: 18px;
    padding-right: 18px;

    h5 {
      margin-bottom: 0px !important;
      font-size: 12px !important;
    }
    h3 {
      font-size: 18px !important;
      font-weight: 500 !important;
    }
  
    .col-disponivel {

      svg.icon {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        margin-top: 4px;
      }
    }
    .col-operador {
      
      svg.icon {
        margin-top: 13px;
        width: 12px;
      }
    }

    
    .col-icon-exclamation {
      padding-top: 4px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
    .col-texto {
      padding-left: 3px;
      padding-top: 3px;

      p {
        font-size: 12px;
        line-height: 1.3em;
      }
    }
    .col-total-debitos {
      padding-top: 2px;
      padding-left: 0px;

    }

  }

  @media (max-width: 1200px) {
    
    padding-left: 18px;
    padding-right: 18px;

    h5 {
      margin-bottom: 0px !important;
      font-size: 10.5px !important;
      min-width: 50px !important;
    }
    h3 {
      font-size: 15px !important;
      font-weight: 500 !important;
      min-width: 50px !important;
    }
  
    .col-disponivel {

      .info-texto{
        display: inline-block;
      }
      svg.icon {
        margin-right: 6px;
        width: 21px;
        height: 21px;
        margin-top: 4px;
      }
      padding-right: 0px !important;
    }
    .col-operador {
      padding: 0px !important;
      text-align: center;
      svg.icon {
        margin-top: 13px;
        width: 10px;
      }
    }
    
    .col-disponivel,
    .col-debitos-pagar,
    .col-receber{
      padding: 0 4px;
    }
    
    
    .col-icon-exclamation {
      padding-top: 7px;
      padding-left: 5px;
      text-align: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }
    .col-texto {
      padding-left: 5px;
      padding-top: 3px;

      p {
        font-size: 12px;
        line-height: 1.3em;
      }
    }
    .col-total-debitos {
      padding-top: 2px;
      padding-left: 0px;
      padding-right: 0px;
      .align{
        padding-right: 0px;
      }
    }
  
  }
    
  @media (max-width: 850px) {
      
    padding-left: 17px;
    padding-right: 17px;

    h5 {
      font-size: 9px !important;
    }
    h3 {
      font-size: 12px !important;
    }

    .col-disponivel {

      svg.icon {
        margin-right: 6px;
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
    .col-operador {
      
      svg.icon {
        margin-top: 8px;
        width: 9px;
      }
    }
  }

  

  @media (max-width: 500px) {
    
    padding: 12px 10px !important;

    .col-disponivel {
      padding-left: 0px;
      padding-right: 0px;
      display: inline-block;
      margin-bottom: 15px;
      
      svg.icon{
        margin-left: 8px;
        margin-right: 5px;
        margin-top: 4px;
      }

      .info-texto{
        width: 83.33333% !important;
        float: right;
        padding-left: 4px;
      }

    }
    .col-operador {
      width: 30px;
    }

    .info-texto {
      h3 {
        font-size: 18px !important;
      }
    }
    .icon-calculadora {
      width: 20px;
      height: 20px;
      align-self: center;
      padding-right: 5px;
    }
    .col-debitos-pagar {
      margin-left: 0px;
      margin-bottom: 15px;
      h3 {
        font-size: 18px !important;
      }
    }
    .col-receber {
      margin-left: 0px;
      h3 {
        font-size: 18px !important;
      }
    }

    
    .col-icon-exclamation {
      padding: 0px;
      padding-top: 4px;
      text-align: center;

      svg {
        width: 25px;
        height: 25px;
      }
    }
    .col-texto {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 8px;

      p {
        font-size: 12px;
        line-height: 1.3em;
        text-align: center;
      }
    }
    .col-total-debitos {
      text-align: center !important;
      padding-top: 8px;
      padding-left: 0px;
      padding-right: 0px;
      .align{

        h5{
          display: inline-block;
          margin-right: 7px !important;
          font-size: 12px !important;
        }
        h3{
          display: inline-block;
          font-size: 14px !important;
        }
        padding-right: 0px;
      }
    }

  }

}
