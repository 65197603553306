.insert-icon path {
  fill: #263659 !important;
}

.table-filter {
  order: 1;
}
// .title-label {
//   width: 400px;
// }

.table-action {
  margin-left: 15px;
  order: 2;
}

.tabelaAcompanhamentoList {
  .table-header {
    margin-bottom: 10px;
  }

  .table-responsive {
    width: 100%;
    overflow-x: auto;
  }

  table {
    text-align: center;
    white-space: nowrap;

    thead {
      tr {
        th {
          color: #646b70;
          font-size: 13px;
          font-weight: 600;
          padding-left: 20px;
          padding-right: 20px;

          span {
            margin-left: 5px !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

.ibox-title {
  padding: 15px 25px 8px 15px !important;
}

@media (max-width: 768px) {
  .tabelaAcompanhamentoList {
    th:nth-child(3) {
      display: none;
    }
    td:nth-child(3) {
      display: none;
    }
    th:nth-child(4) {
      display: none;
    }
    td:nth-child(4) {
      display: none;
    }
    th:nth-child(5) {
      display: none;
    }
    td:nth-child(5) {
      display: none;
    }
    th:nth-child(6) {
      display: none;
    }
    td:nth-child(6) {
      display: none;
    }
    th:nth-child(7) {
      display: none;
    }
    td:nth-child(7) {
      display: none;
    }
    th:nth-child(8) {
      display: none;
    }
    td:nth-child(8) {
      display: none;
    }
  }

  .toolbar-acompanhamento-credenciamento{
    width: 100%;
    display: inline-block !important;
    margin-top: 20px;

    button{
      display: inline-block;
      width: 100%;;
    }
  }
}

@media (max-width: 1120px) {
  .title-label {
    max-width: 200px;
  }
}
