.buttons-desk-container {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }

  .ant-dropdown-button {
    margin-left: 10px;
    height: 35px !important;

    .ant-btn {
      height: 35px;
      background-color: #1ab394;
      color: #fff;
      border: 1px solid #16997f;
      border-radius: 0 !important;
    }
  }

  .ant-dropdown-button:hover,
  .ant-dropdown-button:focus,
  .ant-dropdown-button.focus {
    outline: 2px solid #2ea9fb !important;
  }

  .ant-select {
    margin-left: 10px;
    min-width: 130px;
    width: 150px;
  }

  .ant-select:hover,
  .ant-select:focus,
  .ant-select.focus {
    outline: 2px solid #2ea9fb !important;
  }

  .btn-salvar {
    border: 1px solid #16997f;

    .arrow-color {
      svg {
        width: 10.5px !important;
        position: relative;
        top: -2px;
      }
    }
  }

  .btn-externo {
    background: #667788;
    border: 1px solid #445566;
    color: #fff;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }

  .btn-interno {
    background: #ffffff;
    border: 1px solid #445566;
    color: #263659;

    svg {
      path {
        fill: #263659 !important;
      }
    }
  }

  .ant-select-selector {
    background: #263659 !important;
    height: 35px !important;
    color: #fff;
    padding: 2px 15px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #fff;
  }

  .arrow-color {
    color: #fff;

    path {
      fill: #fff !important;
    }
  }

  .btn-orange {
    path {
      fill: #fff;
    }
  }
}

.ant-dropdown-menu {
  padding: 4px;
}

.ant-dropdown-menu-item {
  font-size: 12px;
  line-height: 16px;
  color: #676a6c;
  padding: 7.5px 20px;
}

.ant-tooltip-analise-credito, 
.ant-tooltip-analise-credito .ant-tooltip-content, 
.ant-tooltip-analise-credito .ant-tooltip-content .ant-tooltip-inner,
.ant-tooltip-analise-credito .ant-tooltip-content .ant-tooltip-inner .ibox,
.ant-tooltip-analise-credito .ant-tooltip-content .ant-tooltip-inner .ibox .ibox-content .mensagens {
  max-height: calc(70vh) !important;
}

.ant-tooltip-analise-credito .ant-tooltip-content .ant-tooltip-inner .ibox .ibox-content {
  max-height: calc(70vh - 4em) !important;
}

.ant-tooltip-analise-credito .ant-tooltip-content .ant-tooltip-inner .ibox .ibox-content .mensagens .list-mensagens {
  max-height: calc(70vh - 14em) !important;
}





