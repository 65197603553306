.semi-donut {
  --percentage: 0;
  --fill: rgb(139, 139, 139);
  width: 61px;
  height: 31px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding-top: 20px;
  line-height: 8px;
  &:after {
    content: '';
    width: 61px;
    height: 61px;
    border: 7px solid;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) var(--fill)
      var(--fill);
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
    animation: fillAnimation 1s ease-in;
  }
}
