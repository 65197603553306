.card-listagem-credenciados {
  .ibox-title {
    padding: 15px !important;
  }
  h3 {
    color: #26417b;
    margin-top: 0 !important;
    font-family: 'Open Sans';
    color: #686b6d;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }

  .btn-adicionar-credenciado {
    background-color: #26417b;
    border: 1px solid #1e3361;
    padding: 8px 16px;
    margin-right: 16px;

    svg {
      width: 8px;
      height: 8px;
      margin-right: 7px;
    }
  }
  .btn-filtrar {
    background-color: #e7eaec;
    border: 1px solid #cfd2d4;
    svg {
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .ibox-content {
    padding: 0 15px;

    .box-filtros {
      margin-top: 0;
      margin-bottom: 15px;
      border: 0.5px solid #b8b9bc;
      padding: 22px 22px 3px 15px;
      .col-itens-filter {
        flex: 1;
        padding-right: 0;
        .filter-grid {
          display: grid;
          grid-template-columns: 5fr 7fr 6fr;
          margin-left: 2px;
        }
        .item-filter {
          padding: 0 12px;
          .ant-row.ant-form-item {
            margin-bottom: 19px;
            .ant-col.ant-form-item-label {
              padding-bottom: 5px;
              label {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #686b6d;
              }
            }
          }
        }
      }

      .col-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 15px;
        padding-left: 0;
        button {
          margin-left: 8px;
          &.btn-pesquisar {
            margin-bottom: 16px;
            background-color: #26417b;
            border: 1px solid #1e3361;
            text-align: left;

            svg {
              width: 15px;
              height: 15px;
              margin-right: 10px;
            }
          }
          &.btn-limpar {
            background-color: #e7eaec;
            border: 1px solid #cfd2d4;
            text-align: left;
            svg {
              width: 16px;
              height: 15px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .tabela-listagem-credenciados {
    .table-responsive {
      padding-bottom: 16px;
    }
    .btn-status-listagem {
      cursor: pointer;
      color: #fff;
      padding: 4px 10px;
      border-radius: 2.5px;
      font-size: 10px;
      line-height: 10px;
      text-transform: none;
      height: 20px;
      text-align: center;
      white-space: nowrap !important;
    }
    td {
      font-family: 'Open Sans' !important;
      border: none !important;
      max-width: 110px !important;
    }
    table {
      border: 1px solid #e7eaec;
      th:first-child {
        border-left: 1px solid #e7eaec;
      }
      th:last-child {
        border-right: 1px solid #e7eaec;
      }

      tr {
        height: 30px;
        text-align: center;
        th {
          height: 35px;
          font-family: 'Open Sans' !important;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          color: #3d3d3e;
          padding: 8px 10px;

          span {
            min-width: 75px !important;
            margin-left: 5px !important;

            i {
              color: #373b3e;
            }
          }
        }
        td {
          height: 30px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #686b6d;
          padding: 4px 5px;

          .form-button {
            .btn-icon-editar-list {
              background-color: #d9d9d9;
              border-radius: 50%;
              margin: 0;
              margin-right: -20px;
              height: 15px;
              width: 15px;
              position: relative;
              padding: 0;

              svg {
                width: 7.5px;
                height: 7.5px;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                path {
                  fill: #ffff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tabela-listagem-credenciados {
  .per-page {
    margin: -4px -4px 30px 0;
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .ant-select-selection-item {
    border-radius: 4px;
    height: 28px !important;
    margin-top: -2px;
  }

  .ant-select-arrow {
    margin-top: -7px;
  }

  .ant-select-selector {
    height: 28px !important;
    border: 1px solid #dbdbdc;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    height: 28px !important;
    line-height: 12px;
  }

  .pagination-container {
    margin-top: -15px;
  }
}
