.modal-discador{
    position: fixed;
    right: -1px;
    top: 52%;
    transform: translateY(-50%);
    border: 1px solid #1B3357;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px 0px 0px 24px;
    padding: 17px 15px;
    padding-bottom: 30px;
    background-color: #FFFFFF;
    text-align: center;
    min-width: 290px;
    min-height: 220px;
    overflow: hidden;

    &.bg-erro{
        background-color: #FFC2C2;
    }

    .conexao{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        .spinner{

        }
    }
    .em-chamada{
        h5.mensagem {
            margin-bottom: 12px;
            color: black;
            font-weight: 500;
            font-size: 14px;
        }
        h4.tipo-telefone{
            font-weight: 600;
            font-size: 18px;
            color: #445566;
            margin-bottom: 8px;
        }
        h5.numero-telefone{
            margin-bottom: 12px;
            color: #667788;
            font-weight: 500;
            font-size: 14px;
        }
        p.tempo-chamada{
            margin-bottom: 20px;
            font-size: 12px;
            color: #686B6D;
        }
        .btns-acoes{
            text-align: center;
            margin: 0 -7px;
            display: inline-block;
            width: auto;

            button{
                display: inline-block;
                box-shadow: none;
                padding: 11px 12px;
                border: 1px solid #D9D9D9;
                background-color: #FFFFFF;
                margin: 0 7px;
                transition: .2s;

                svg{
                    margin-bottom: 10px;
                    height: 22px;
                }
                span{
                    display: block;
                    font-size: 11.5px;
                    line-height: 1.1em;
                }

                &.btn-atendeu{

                    span{
                        color: #70D1BD;
                    }
                    &:hover{
                        background-color: #70D1BD;
                        border-color: #70D1BD;
                    }
                }
                &.btn-ocupado{

                    span{
                        color: #FA8B3C;
                    }
                    &:hover{
                        background-color: #FA8B3C;
                        border-color: #FA8B3C;
                    }
                }
                &.btn-cancelar{

                    span{
                        color: #FF8F8F;
                    }
                    &:hover{
                        background-color: #FF8F8F;
                        border-color: #FF8F8F;
                    }
                }

                &:hover{
                    transition: .2s;

                    svg{

                        path{
                            fill: #FFFFFF !important;
                        }
                    }
                    span{
                        color: #ffffff;
                    }
                }
            }
        }
        .atendeu{
            
            h4.titulo{
                color: #1AB394;
                font-size: 17px;
                font-weight: 400;
                line-height: 1em;
                margin-bottom: 22px;

                svg{
                    position: relative;
                    top: -1px;
                    width: 23px;
                    margin-right: 12px;
                }
            }
            button.btn-confirmacao{
                background-color: #1AB394;
                border: 1px solid #16997F;
                color: #FFFFFF;
                font-size: 14px;
                letter-spacing: 0.05em;
                padding: 10px 12px;
                padding-bottom: 9px;
                margin-bottom: 15px;
                line-height: 1em;
                text-transform: uppercase;
                width: 100%;
                transition: .2s;

                svg{
                    width: 14px;
                    position: relative;
                    top: -1px;
                    margin-right: 7px;
                }

                &:hover{
                    background-color: #FFFFFF;
                    color: #16997F;
                    transition: .2s;

                    svg{

                        path{
                            fill: #16997F !important;
                        }
                    }
                }
            }
            button.btn-fechar{
                display: inline-block;
                background-color: transparent;
                border: none;
                box-shadow: none;
                color: #BBBDBF;
                font-size: 14px;
                transition: .2s;
                text-transform: uppercase;
                letter-spacing: 0.03em;

                &:hover{
                    opacity: 0.7;
                    transition: .2s;
                }
            }
        }
    }
    .erro-comunicacao{
        padding-top: 20px;
        padding-bottom: 8px;
        width: 260px;

        svg{
            width: 65px;
            height: 65px;
            margin-bottom: 20px;
        }
        h4{
            margin: 0px;
            font-size: 22px;
            color: #686B6D;
            font-weight: 400;
            line-height: 1.35em;
        }
    }
}