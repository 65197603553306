.widget-acompanhamento-credenciamento {
    box-sizing: border-box;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid #E7EAEC;
    background: #FFFFFF;
    .widget-header {
        padding: 12px 10px;
        border-bottom: 1px solid #E7EAEC;
        /*    display: flex;
    align-items: center;
    justify-content: space-between;
*/
        .ant-tag {
            font-size: 11px;
            padding: 3px 10px;
            margin-right: 0px;
            margin-top: 1px;
            line-height: 1em;
        }
        .widget-title {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
        }
    }
    span {
        margin-top: 3px;
    }
    p {
        margin: 0px;
    }
    .widget-content {
        padding: 20px 16px;
        padding-bottom: 10px;
        .content-data-group {
            margin-bottom: 20px;
        }
        .total-value {
            font-style: normal;
            font-weight: normal;
            font-size: 29px;
            margin-top: 2px;
            line-height: 20px;
            font-weight: 400;
        }
        .group-direcionadas {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .porcentagem {
            color: #1C84C6;
        }
        .porcentagem>svg>path {
            fill: #fff;
        }
        .direcionadas {
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            line-height: 19px;
        }
        span {
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
        }
    }
}