.content-proposta-credito{

    .calculadora-oferta {
        visibility: visible;
        position: absolute;
        top: 60px;
        left: 35px;
        width: 1000px;
        overflow: visible;
        z-index: 1000;
    }
      

    .wrapper-content{
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        padding: 20px 10px 15px;

        .proposta-credito{
            height: 100%;
            position: relative;

            .col-central-comunicacao {
                height: 100%;
                overflow-y: hidden;
                overflow-x: hidden;

                .ibox{
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    height: 100%;
                    position: relative;
                    overflow-y: hidden;
                    margin: 0px;

                    .ibox-content{
                        height: 100%;
                        position: relative;
                        overflow-y: hidden;
                        padding-top: 10px;

                    }
                }
            }

            .ibox{
                border-bottom: 1px solid #e7eaec;
            }
        }
    }

}
