// Text Input

// .ant-input {
//   width: 100%;
// }

.ant-select {
  width: 100%;
}

.ant-picker {
  width: 100%;
}

// Steps

.ant-steps-item-container {
  display: flex;
  align-items: center;
}
