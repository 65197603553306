.impressao-documentos {
  .btn-doc-blue-disabled {
    background-color: #7283a9;
    color: #fff;

    path {
      fill: #e5a060;
    }

    &:hover {
      color: #e5a060 !important;
    }
  }

  .btn-doc {
    border: 1px solid #9baebf;
    font-weight: 600;
    font-size: 11px;
    text-transform: none;
    width: 100%;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 15px;
    height: auto;

    &:hover {
      color: #fa6e0a;
    }

    svg {
      width: 15px;
      margin-right: 10px;
    }
  }

  .btn-doc-white {
    background-color: #ffffff;
    color: #445566;
  }

  .btn-doc-blue {
    background-color: #26417b;
    color: #fff;

    path {
      fill: #fa6e0a;

      &:hover {
        color: #fff;
      }
    }
  }

  .form-row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .btn-docs-group {
    margin-top: 20px;

    .row {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .btn-docs-group .col,
  .btn-docs-group [class*='col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.btn-orange {
  padding: 7px 10px;

  // path {                  Essa regra provoca mudança global na cor do ícone
  //   fill: #fff!important; Favor implementar de forma local
  // }
}
