// Colors
$green: #1ab394;
$red: #ff4d4f;
$orange: #ff7817;
$blue: #263659;
$brown: #85635b;
$gray: #dee2e6;
$white: #ffffff;
$border: #2ea9fb;
.btn {
  border-radius: 0;
  font-weight: 400;
  height: 35px;
  letter-spacing: 0.25px;
  padding: 7px 15px;
  text-transform: uppercase;
}

.btn:disabled {
  background: #f3f4f5;
  border: 1px solid #bbbdbf;
  color: #bbbdbf;
}

.btn > svg {
  margin-top: -3px;
}

// Green
.btn-green {
  background-color: $green;
  border-color: $green;
  color: $white;
}

.btn-green:hover,
.btn-green:focus,
.btn-green.focus {
  background-color: darken($green, 5%);
  border-color: darken($green, 5%);
  outline: 2px solid $border !important;
  color: $white;
}

.btn-green.disabled,
.btn-green:disabled {
  background-color: darken($green, 3%);
  border-color: darken($green, 3%);
}

.btn-green > svg path {
  fill: $white;
}

//Red
.btn-red {
  background-color: $red;
  border-color: $red;
  color: $white;
}

.btn-red:hover,
.btn-red:focus,
.btn-red.focus {
  background-color: darken($red, 5%);
  border-color: darken($red, 5%);
  outline: 2px solid $border !important;
  color: $white;
}

.btn-red.disabled,
.btn-red:disabled {
  background-color: darken($red, 3%);
  border-color: darken($red, 3%);
}

.btn-red > svg path {
  fill: $white;
}

.btn-red-outline {
  background-color: none;
  border: 1px solid $red;
  color: $red;
}

.btn-red-outline:hover,
.btn-red-outline:focus,
.btn-red-outline.focus {
  border: 1px solid $red;
  outline: 2px solid $border !important;
  color: $red;
}

.btn-red-outline.disabled,
.btn-red-outline:disabled {
  background-color: darken($red, 3%);
  border-color: darken($red, 3%);
}

.btn-red-outline > svg path {
  fill: $red;
}

// Orange
.btn-orange {
  background-color: $orange;
  border-color: $orange;
  color: $blue;
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange.focus {
  background-color: darken($orange, 5%);
  border-color: darken($orange, 5%);
  outline: 2px solid $border !important;
  color: $blue;
}

.btn-orange.disabled,
.btn-orange:disabled {
  background-color: darken($orange, 3%);
  border-color: darken($orange, 3%);
}

.btn-orange > svg path {
  fill: $blue;
}

.btn-orange-outline {
  background-color: none;
  border: 1px solid #e66c15;
  color: $orange;
}

.btn-orange-outline:hover,
.btn-orange-outline:focus,
.btn-orange-outline.focus {
  background-color: #ffd2b2;
  border: 1px solid #e56c15;
  outline: 2px solid $border !important;
  color: $orange;
}

.btn-orange-outline.disabled,
.btn-orange-outline:disabled {
  background-color: darken($orange, 3%);
  border-color: darken($orange, 3%);
}

.btn-orange-outline > svg path {
  fill: $orange;
}

// Blue
.btn-blue {
  background-color: $blue;
  border-color: $blue;
  color: $white;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue.focus {
  background-color: darken($blue, 5%);
  border-color: darken($blue, 5%);
  outline: 2px solid $border !important;
  color: $white;
}

.btn-blue.disabled,
.btn-blue:disabled {
  background-color: darken($blue, 3%);
  border-color: darken($blue, 3%);
}

.btn-blue > svg path {
  fill: $white;
}

// Gray
.btn-gray {
  background-color: $gray;
  border-color: $gray;
  color: $blue;
}

.btn-gray:hover,
.btn-gray:focus,
.btn-gray.focus {
  background-color: darken($gray, 5%);
  border-color: darken($gray, 5%);
  outline: 2px solid $border !important;
  color: $blue;
}

.btn-gray.disabled,
.btn-gray:disabled {
  background-color: darken($gray, 3%);
  border-color: darken($gray, 3%);
}

.btn-gray > svg path {
  fill: $blue;
}

//Brown
.btn-brown {
  background-color: $brown;
  border-color: #523d38;
  color: $white;
}

.btn-brown:hover,
.btn-brown:focus,
.btn-brown.focus {
  background-color: darken($brown, 5%);
  border-color: darken($brown, 5%);
  outline: 2px solid $border !important;
  color: $white;
}

.btn-brown.disabled,
.btn-brown:disabled {
  background-color: darken($brown, 3%);
  border-color: darken($brown, 3%);
}

.btn-brown > svg path {
  fill: $white;
}
