.filled {
  .ant-select-selector {
    background: #E7EAEC!important;
    border-radius: 2px!important;
    border: 0!important;

    color: #263659;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
  }
}
.arrow-color {
  color: #263659;
}

.select-modalidade {
  .ant-select-selector {
    background: #263659!important;
    border: 0!important;

    color: #fff;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
  }
  .arrow-color {
    color: #fff;
  }
}


