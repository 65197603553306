.widget-relatorio-analitico {
  
  box-sizing: border-box;
  border-radius: 2px 2px 0px 0px;
  border: 1px solid #E7EAEC;
  background: #F3F3F4;

  .widget-header {
    padding: 12px 15.38px 16px 14.95px;
    border-bottom: 1px solid #E7EAEC;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .widget-title {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 400;
      color: #676A6C;
    }

    .widget-container-tag {

      font-size: 10px;
    height: 20px;
    margin: 0;
    padding-inline: 9px 8px;
    }
  }

  .widget-content {
    padding: 15px 10px 27px 10px;

    .total-value {
      font-style: normal;
      font-weight: normal;
      font-size: 45px;
      line-height: 20px;
      font-weight: bold;
    }

    .group-direcionadas {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .porcentagem {
      color: #1C84C6;
      padding-right: 2.58px;
    }

    .direcionadas {
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      padding-right: 3px;
    }
  }
}
