.analise-crivo {
  .card-icon-title {
    margin-bottom: 16px;
  }

  .itens-crivo {
    padding-left: 5px;
    margin-bottom: 50px;

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        width: 100%;
        line-height: 1.1em;
        font-size: 13.3px;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .ellipse {
          margin-right: 5px;
          width: 11px !important;
          height: 11px !important;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .charts-info {
    padding-top: 15px;
    border-top: 2px solid #d9d9d9;
    margin: 0 auto;
    width: 95%;

    .col-chart {
      padding: 0 5px !important;
      width: 48%;

      .card-chart {
        padding: 5px;
        padding-bottom: 7px;

        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
        border-radius: 3px;

        .card-chart-title {
          font-size: 13px;
          line-height: 1.2em;
        }
        .card-chart-subtitle {
          font-size: 11.5px;
          b {
            margin: 0px !important;
          }
        }
        .semi-donut {
        }
        .date {
        }
      }
    }

    .slick-slider {
      position: relative;

      .slick-arrow {
        transform: translateY(-50%);

        svg {
          path {
            fill: #ededed !important;
            stroke: #ededed;
            stroke-width: 100px;
            stroke-linejoin: round;
          }
        }
        &:hover {
          svg {
            path {
              fill: #cccccc !important;
              stroke: #cccccc;
            }
          }
        }
      }

      .slick-list {
        overflow: hidden;
        padding-bottom: 10px;
        .slick-slide {
          width: 50%;
          display: inline-block;
        }
      }
      .slick-dots {
        list-style: none;
        width: 100%;
        text-align: center;
        margin: 0px;
        padding: 0px;
        position: relative;
        top: -4px;

        li {
          display: inline-block;
          line-height: 1em;
          margin: 0 3.5px;

          button {
            background-color: #f0f0f0;
            color: #f0f0f0;
            width: 10px !important;
            height: 10px !important;
            display: inline-block;
            border-radius: 50%;
            cursor: pointer;
            border: none !important;
            outline: none !important;
            font-size: 3px;
            padding: 0px;
          }
          &.slick-active {
            button {
              background-color: #c2c2c2;
              color: #c2c2c2;
            }
          }
        }
      }
    }
  }
}
