.collapse-historicos .table thead tr th {
  color: #3d3d3e !important;
  font-size: 14px;
}

.collapse-historicos .table tbody tr td {
  font-size: 14px !important;
}

div .table > thead:first-child > tr:first-child > th {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #3d3d3e !important;
  font-weight: 600;
  line-height: normal;
}

div .table > thead:first-child > tr:first-child > th > span {
  margin-left: 10px !important;
}

div .table > thead:first-child > tr:first-child > th > span i {
  font-size: 10px;
}

.title-situacao {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #f7f7f7;
  text-align: center;
  padding: 6px 20px;
  line-height: 16.34px;
}

.collapse-historicos .table tbody tr td {
  font-family: 'Open Sans', sans-serif;
  padding: 4px 10px !important;
}

.collapse-historicos .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #f7f7f7;
}

.ant-collapse {
  margin-top: 20px !important;
}

.text-include-icon {
  .ant-collapse-header-text {
    padding-left: 20px;
    position: absolute;
    left: 10px;
  }
}