.proposta-servico {
  .ant-select-disabled, .ant-input[disabled] {
    background-color: #e7eaec !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: #e7eaec !important;
  }
  .ant-select-disabled.selectTipoTelefone .ant-select-selector, .ant-picker.ant-picker-disabled{
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #e7eaec !important;

  }

  .container-nome-pacote {
    .ant-select-selection-item span {
      padding: 0;
    }
  }

  .info-valor-adicional {
    font-size: 9px;
    color: red;
    margin-top: 3px;
  }

  .wrapper-buttons-cadastrar {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 32px;

    button {
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 1px;
      outline: none !important;
      box-shadow: none !important;
      height: 40px;
    }

    .btn-cadastrar-outro-produto {
      padding: 4px 25px;
      margin-right: auto;
      background: #26417b;
      border: 1px solid#1E3361;
      color: white;

      border-radius: 1px;
      outline: none !important;
      box-shadow: none !important;

      &:hover {
        background: white;
        border-color: #26417b;
      }

      &:hover span {
        color: #26417b;
      }

      svg {
        margin-right: 7px;
        position: relative;
        top: -2px;
      }
    }

    .btn-cadastrar-outro-produto:hover svg path {
      fill: #26417b;
    }

    .btn-cadastrar-proposta-crefaz-mais {
      background-color: #1ab394;
      border: 1px solid #16997f;
    }

    .btn-cadastrar-proposta-crefaz-mais:hover,
    .btn-cadastrar-proposta-crefaz-mais:focus {
      color: #1ab394;
      border-color: #1ab394;
      background-color: #fff;

      span {
        color: #1ab394 !important;
      }

      svg path {
        fill: #1ab394 !important;
      }
    }
  }

  .btnAddInputs {
    background-color: #26417b;
    color: white;
    width: auto;
    height: 32px;
    margin-top: 21px;
    text-transform: uppercase;
    outline: none !important;
    margin-right: 0;

    svg {
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus {
      color: #26417b;
      background-color: #fff;
      border-color: #26417b;
    }

    &:hover svg path,
    &:focus svg path {
      fill: #26417b !important;
    }
  }

  .btnRemoveInputs {
    background-color: #dc3545;
    color: white;
    width: 100%;
    height: 32px;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 22px;
    text-transform: uppercase;
    outline: none !important;

    svg {
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus {
      color: #dc3545;
      background-color: #fff;
      border-color: #dc3545;
    }

    &:hover svg path,
    &:focus svg path {
      fill: #dc3545 !important;
    }
  }
  .ant-form-item {
    margin-bottom: 15px;

    .ant-form-item-label {
      margin-bottom: 7px;
      line-height: 1em;
      padding-bottom: 0px;
      overflow: initial;
      label {
        margin: 0px;
      }
    }
  }

  .tag-color {
    display: inline-block;
    min-width: 75px;
    padding: 3px 20px;
    border-radius: 2.5px;
    font-size: 12px;
    line-height: 1em;
    opacity: 1;
    margin-top: 5px;
    margin-right: 0px;
  }
}
