.cards-credenciamento {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

@media (max-width: 1304px)
{
    .cards-credenciamento {
        grid-template-columns: repeat(3, 1fr);
        
    }

    .analise-final {
        grid-column-start: 1;
        grid-column-end: 4;
    }
    
}

@media (max-width: 768px){

    .cards-credenciamento{

        display: inline-block;
        margin: 0 -3px;

        & > div{
            width: 50%;
            display: inline-block;
            padding: 0 3px;
        }

        .widget-acompanhamento-credenciamento{

            .widget-header{
                padding: 8px 5px;

                .widget-title{
                    font-size: 11px;
                    font-weight: 600;
                    height: 30px;
                    line-height: 1.3em;
                    vertical-align: middle;
                    display: table-cell;
                }
            }
            .widget-content{
                padding: 8px 10px;
                padding-bottom: 6px;
                margin-bottom: 6px;

                & > div:nth-child(2){
                    text-align: right;
                    width: 100%;
                    display: inline-block !important;
                }

                .content-data-group{
                    margin-bottom: 10px;

                    h2{
                        font-size: 24px;
                    }
                }
                p.porcentagem{
                    font-size: 10px;
                    font-weight: 400;
                    padding: 4px 6px !important;
                    display: inline-block;
                    margin-right: -5px;

                    svg{
                        width: 10px;
                        height: 10px;
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
    }

}