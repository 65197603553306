.modal-motivos {
  min-width: 1000px;
  max-height: 650px;
  color: #686b6d;

  .pesquisa {
    .filter-input {
      width: 100%;
    }
  }

  .ant-modal-body {
    padding-top: 13px;
    padding-right: 13px;
    padding-left: 13px;
    padding-bottom: 5px;
  }

  .ant-checkbox-inner {
    height: 14px;
    width: 14px;
    border-radius: 0px !important;
    border: 1px solid #6d757d;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1ab394;
    border-color: #1ab394;
  }
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #1ab394;
  }

  .motivos {
    padding: 15px 15px 15px 30px;
    border: 1px solid #d9d9d9;
    max-height: 340px;
    overflow-y: scroll;
    overflow-x: hidden;
    .checkbox-container {
      width: 50%;
      display: flex;
      align-items: center;

      margin-bottom: 10px;

      .checkbox {
        width: 13px;
        margin-right: 9px;
      }
      .checkbox-text {
        font: 13px/13px sans-serif;
        margin-right: 40px;

        line-height: 19px;
      }
    }
  }

  .ant-modal-footer {
    border-top: none;
    padding-right: 13px;
  }

  .button-negar {
    background: #ed5565;
    padding: 5px 22.5px;
    border-radius: 2.5px;
    margin-right: 0 !important;
  }

  .motivo-title {
    color: #26417b;
    font: 18px/19.5px sans-serif;
  }

  .motivo-textarea {
    resize: none;
    &::placeholder {
      color: #6d757d;
    }
  }
}
