.card-icon {
  border: 1px solid #D9D9D9;
  background-color: white;
  padding: 15px;
  width: 100%;
  height: 100%;
}

.card-icon-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #445566;
  display: flex;
  // align-items: center;
  
    justify-content: space-between;
    align-items: flex-start;
  

  svg {
    width: 18px;
    position: relative;
    top: -1px;
  }
}

.card-icon-content {

  .col, [class*="col-"] {
    padding-right: 15px!important;
    padding-left: 15px!important;
  }
}
