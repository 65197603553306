// Variables, Mixins
@import 'base/variables';
@import 'base/mixins';

// INSPINIA Theme Elements
@import 'base/typography';
// @import 'base/glyphicons';
@import 'base/navigation';
@import 'base/top_navigation';
@import 'base/buttons';
@import 'base/badges_labels';
@import 'base/elements';
@import 'base/sidebar';
@import 'base/base';
@import 'base/pages';
@import 'base/chat';
@import 'base/metismenu';
@import 'base/spinners';

// Landing page
@import 'base/landing';

// RTL Support
@import 'base/rtl';

// For demo only - config box style
@import 'base/theme-config';

// INSPINIA Skins
@import 'base/skins';
@import 'base/md-skin';

// Media query style
@import 'base/media';

// Custom style
// Your custom style to override base style
@import 'base/custom';

// antd Override Componentes
@import 'base/antd.scss';

// // resolve a exibição de error especifico
// // ResizeObserver loop limit exceeded
.error-body {
  iframe#webpack-dev-server-client-overlay{display:none!important}
}

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  body {
    overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}

html {
  font-size: 14px;
}
