.topo-relatorio-esteira-credito {
  width: 100%;
  display: flex;
  justify-content: space-between;
    
  .title-esteira{
    white-space: nowrap;
    width: fit-content;
  }
}


.filter-container {
  background-color: #f7f7ff;
  border: 1px solid #b8b9bc;
  padding: 15px;
  margin-bottom: 1rem;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-actions {
  width: 200px;
}

.filter-form {
  width: 100%;
  box-sizing: border-box;
  margin-right: 1rem;
}

.filter-item {
  margin-bottom: 0.75rem !important;
}

.filter-icon path {
  fill: #263659;
}

.filter-icon {
  margin-right: 10px;
}

.esteiraList tbody {
  text-align: center !important;
}

.esteiraList thead {
  text-align: center !important;
}

.btn-novo-fluxo, .btn-novo-fluxo:hover {
  border: none;
  background-color: transparent;
}

.modal-novo-fluxo {
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;

  .icon {
    margin: 20px 0;
  }

  h1 {
    font-weight: 600;
    font-size: 28px;
    color: #686B6D;
    line-height: 38px;
  }

  label {
    float: left;
    color: #676a6c;
  }

  .modal-input {
    margin: 14px 0;
  }

  .modal-error {
    text-align: left;
    font-size: 11px;
  }

  .modal-input-text:disabled{
    color:  #686B6D;
  }

  .btn {
    height: 45.4px;
    width: 145.11px;;
    border-radius: 3px;
    background-color: #26417B;    
    font-weight: 600;
    margin-top: 11px;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.25px;
  }
  
  .error-txta{
    background-color: #ffc2c2;
    border-color: #ed5555;
  }
}

@media (max-width: 1240px) {
  .esteiraList {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .filter-wrapper {
    flex-direction: column;
  }
  .filter-actions {
    width: 100%;
  }
  .filter-form {
    margin-right: 0;
  }

  .relatorio-esteira-credito {
    .table-responsive {
      display: inline-block;
      width: 100%;
      overflow-x: scroll;

      table {
        width: 100%;

        td {
          &:nth-child(8) {
            position: initial !important;
            display: none !important;
          }
        }
      }
    }
  }

  .topo-relatorio-esteira-credito {
    display: inline-block !important;

    .toolbar {
      display: inline-block !important;
      width: 100%;
      margin-top: 20px;

      button {
        display: inline-block !important;
        width: 100%;
      }
    }
  }
}

.ant-tooltip.tooltip-historico-esteira-credito {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      right: 7px;
      width: 15px;
      .ant-tooltip-arrow-content {
        width: 11px;
        height: 11px;
      }
    }

    .ant-tooltip-inner {
      .outsideBorder {
        padding: 10px 20px;
        padding-right: 12px;

        p {
          font-size: 13px;
          line-height: 1.55em;
          font-weight: 600;
          margin: 0px;
          margin-bottom: 10px;
        }

        .tabelaHistorico {
          background-color: transparent !important;

          .table-responsive {
            max-height: 350px;
            padding-right: 6px;
            margin-bottom: 5px;

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            &::-webkit-scrollbar {
              width: 3px;
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background: #ff7817;
              border-radius: 5px;
            }
          }
          .table {
            background-color: #fff;
            thead {
              tr {
                th {
                  color: #646b70;
                  font-size: 13px;
                  font-weight: 400;

                  span {
                    font-size: 11px;
                    color: #373b3e;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  color: #686b6d;
                  font-size: 13px;
                  font-weight: 400;
                  padding-top: 5px;
                  padding-bottom: 5px;

                  .tag-color {
                    font-weight: 400;
                    font-size: 10px;
                    letter-spacing: 0.04em;
                    line-height: 1em;
                    padding: 5px 12px;
                    width: 85%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .esteiraList {
    th:nth-child(1) {
      display: none !important;
    }
    td:nth-child(1) {
      display: none;
    }
    th:nth-child(2) {
      display: none;
    }
    td:nth-child(2) {
      display: none;
    }
    th:nth-child(3) {
      display: none;
    }
    td:nth-child(3) {
      display: none;
    }
    th:nth-child(5) {
      display: none;
    }
    td:nth-child(5) {
      display: none;
    }
    th:nth-child(6) {
      display: none;
    }
    td:nth-child(6) {
      display: none;
    }
    th:nth-child(7) {
      display: none;
    }
    td:nth-child(7) {
      display: none;
    }
    th:nth-child(8) {
      display: none;
    }
    td:nth-child(8) {
      display: none;
    }
    th:nth-child(9) {
      display: none;
    }
    td:nth-child(9) {
      display: none;
    }
    th:nth-child(11) {
      display: none;
    }
    td:nth-child(11) {
      display: none;
    }
  }
}

.outsideBorder {
  color: white;
  font-size: 13px;
  font-weight: 600;
  background-color: #435361;
  padding: 20px;
}

.tabelaHistorico {
  margin-top: 5px;
  background-color: white !important;
}

@media (max-width: 500px) {
  .topo-relatorio-esteira-credito {
    .title-esteira{
      white-space: initial;

    }
  }
  
}