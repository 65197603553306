.tooltip-chamada {
    display: flex;
    width: 100%;
    justify-content: center;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        color: white;
        font-size: 10px;
        width: 75px;
        height: 20px;
    }
}

.table-historico {
    .ant-table-wrapper {
        .ant-table {
            border: none;
        }
    }
}

.coluna-vencimento-historico-cia {
    text-align: center !important;
}