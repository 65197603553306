.modal-confirma-inativacao {
  button {
    width: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-cancel {
    background-color: #ED5565 !important;
    color: white !important;
    margin-right: 10px !important;
    width: 50px !important;
  }
  .btn-confirm {
    width: 50px !important;
  }
  svg path {
    font-weight: bold;
    color: white;
  }
}
