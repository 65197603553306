.ant-tooltip.tooltip-reconsulta.tooltip-proposta-credito {

    min-width: 300px;
  
    .ant-tooltip-content {
      .ant-tooltip-inner {
  
        padding: 0 !important;
        border-bottom: 6px solid;
        border-left: 8px solid;
        border-right: 8px solid;
        border-top: 6px solid;
        border-color: rgb(67, 83, 97);
        border-radius: 6px;
  
        .outsideBorder {
          padding: 7px 1px 7px 7px;
          .tabelaHistorico {
            .table-responsive {
              max-height: 305px;
              thead {
                th {
                  width: auto !important;
                }
              }
              tbody {
                td {
                  text-align: center;
                }
              }
              border: none !important;
            }
          }
        }
      }
    }
  }