.title-indicador {
    font-weight: 600;
    font-size: 14px;
    color: rgba(104, 107, 109, 0.7);
}

.result-indicador {
    font-weight: 600;
    font-size: 22px;
}

.line {
    border: 1px solid #E5E6E7;
}

.bsCard {
    max-width: 18rem;
}

.indicador {
    position: relative;
    padding: 0px;
    margin: 0px;
    margin-top: 11px;
}

.indicador-operatividade {
    width: 30%;
    min-width: 340px;
}

.indicador-tempo-medio {
    width: 17%;
    min-width: 200px;
}

.indicador-outros {
    width: 12.5%;
    min-width: 140px;
}

@media (max-width: 1450px) {
    .indicador-operatividade {
        width: 60%;
    }
    .indicador-tempo-medio {
        width: 39%;
    }
    .indicador-outros {
        width: 24%;
    }
}

@media (max-width: 870px) {
    .indicador-operatividade {
        width: 100%;
        min-width: 100%;
        padding: 0 6px;
    }
    .indicador-tempo-medio {
        width: 100%;
        min-width: 100%;
        padding: 0 6px;
    }
    .indicador-outros {
        width: 50% !important;
        overflow: hidden;
        display: inline-block;
        min-width: 90px;
        padding: 0 6px;
    }
    .list-indicadores{
        display: inline-block !important;
        width: 100%;
        margin: 0 -6px;
    }

    .card-estatisticas{

        .card-header{
            font-size: 12px;
        }
        .card-body{
            padding: 12px 15px;

            .card-content{

                .img{

                    svg{
                        width: 24px;
                    }
                }
                p{
                    font-size:21px;
                    position: relative;
                    top: -1px;
                    display: inline-block;
                }
            }
        }
    }
}