// .btn-orange {
//   height: 40px;
//   font-weight: 600;
//   font-style: normal;
//   border-radius: 0;
// }

.observacao {
  margin-top: 30px;
  color: #676a6c;
  text-align: justify;
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 30px;
}
