body {
  position: relative;
}

.modal-bloquear-inativar-usuario {
  .ant-modal-close:focus {
    outline: 0;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 27.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    margin-right: 6.35px;
    font-weight: 600;
    svg {
      path {
        fill: rgba(73, 80, 87, 0.4);
        width: 13px;
        height: 15.7px;
      }
    }
  }
  .ant-modal-header {
    .ant-modal-title {
      .title-label {
        h3 {
          font-size: 14px;
          color: #26417b;
          margin-top: 0;
          margin-bottom: 6px;
        }
      }
    }
  }
  .ant-modal-body {
    label {
      color: #686b6d;
      font-size: 14px;
      font-family: 'Open Sans';
      margin-bottom: 5px;
    }
    textarea {
      resize: none;
    }
    .ant-select {
      margin-bottom: 17.5px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;

      .btn-cancel {
        display: flex;
        align-items: center;
        border-radius: 0;
        padding: 12.5px 15px;
      }

      .btn-save {
        border-radius: 0;
        margin-left: 16px;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 9px;
        }
      }
    }
  }
}

.svg-save {
  width: 15px !important;
  margin-right: 6px !important;
}
.overflow {
  overflow: auto !important;
}
.modal-warning {
  .ant-modal-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-close:focus {
    outline: 0;
  }
  .ant-modal-close {
    svg {
      path {
        fill: rgba(73, 80, 87, 0.4);
      }
    }
  }
  .icon {
    padding-top: 35px;
  }
  .text-modal {
    min-height: 89px;
    color: #686b6d;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    margin: 10px 25px 11px 25px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .btn-area {
    margin-bottom: 25px;
    display: flex;
    .btn-confirm {
      height: 40px;
      width: 100px;
      padding: 10px 42px 10px 38px;
      background: #26417b;
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
    }
    .btn-cancel {
      margin-right: 16px;
      height: 40px;
      width: 100px;
      padding: 10px 42px 10px 38px;
      background: #e7eaec;
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      color: #263659;
    }
  }
}
.cadastro-usuario-page {
  max-width: 100%;

  .ant-select-selector {
    height: 30px !important;
  }

  .perfil-usuario-select .ant-select-selector {
    height: auto !important;
  }

  .wrapper-certificacoes .item-certificacoes {
    padding-right: 16px !important;
  }

  .titulo-pagina-cadastro-usuario ibox-title:first-child {
    padding-bottom: 0px !important;
  }

  label.label-switch {
    font: normal 400 10px 'Open Sans', sans-serif !important;
    margin: auto;
    vertical-align: middle;
    display: flex;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    color: #3d3d3e !important;
  }
  .tooltip-info-pep {
    margin-left: 3px;
    color: #26417b;
  }
  .switch-red-if-off {
    margin-left: 10px;
    margin-right: 16px;
    width: 28px;
    height: 12px;
    .ant-switch-handle {
      margin: auto;
      top: 0px;
      left: 0;
      width: 12px;
      height: 12px;
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        margin: auto;
        top: 0px;
        left: 100%;
        transform: translateX(-100%);
        width: 12px;
        height: 12px;
      }
    }
  }
  .ibox-content {
    padding: 15px !important;
  }

  .title-label {
    max-width: 100%;
    h3 {
      color: #26417b;
    }
  }

  .form-cadastro-usuario {
    margin-left: -8px;
    margin-right: -8px;
    & > div {
      padding: 0 8px;
    }
    .form-title {
      display: flex;
      align-items: center;
      color: #263659;
      font: normal 600 14px/19.5px 'Open Sans', sans-serif;
      white-space: nowrap;
      margin-top: 6.5px;
      margin-bottom: 16px;
      .cube {
        width: 12px;
        height: 12px;
        background-color: #ff7817;
        margin-right: 5px;
      }
      .bullet-info-icon {
        margin-left: 10px;
      }
      .switch-midia {
        margin: auto;
        margin: 6px 0 0 15px;
        width: 180px;
      }
      .atuacao-promotora-switch {
        margin-bottom: 0;
        margin-top: 6px;
      }
    }
    .ant-form-item {
      display: inline-block;
      margin-bottom: 16px;
      width: 100%;
      label {
        height: auto;
        margin-bottom: 5px;
        font: normal 400 14px/19px 'Open Sans', sans-serif;
      }
      .ant-input {
        border-radius: 0;
        padding: 5.5px 10px;
        line-height: normal;
        max-height: 30px;
      }
    }
  }
  .card-collapse-logs {
    .ibox-content {
      padding-top: 20px;
    }
    .ant-collapse {
      margin-top: 0 !important;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .ant-collapse-header {
        color: #445566;
        font: 400 normal 13px/20px 'Open Sans', sans-serif;
        padding: 8px 16px 8px 40px;
        .ant-collapse-arrow {
          padding: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .tabela-listagem-usuarios {
      .ant-collapse-content-box {
        padding: 0 !important;
        border: 0;
      }
      .table-responsive {
        margin-bottom: 0;
        border: 0;
        .table {
          thead {
            border-bottom: 1px solid #dbdbdc;
            th {
              font: 600 normal 14px/19.07px 'Open Sans', sans-serif;
              color: #3d3d3e;
              span {
                color: #373b3e;
              }
            }
          }
          tbody {
            border: none;
            tr {
              &:nth-child(2n -1) {
                background-color: #f7f7f7;
              }
              td {
                font: 400 normal 13px/17.7px 'Open Sans', sans-serif;
              }
            }
          }
          td,
          th {
            width: 1%;
            text-align: center;
            border: none;
          }
        }
      }
    }
  }
  .btn-acao {
    height: 35px;
    margin-left: -3px;
    margin-top: 15px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    outline: none !important;
    border: 1px solid #cfd2d4;

    color: white;

    svg {
      margin-right: 10px;

      path {
        fill: #fff !important;
      }
    }
  }
  .col-btn {
    text-align: right;
    .btn-acao {
      margin-top: 22px;
      font: normal 400 14px/19px 'Open Sans', sans-serif;
      svg {
        margin-bottom: 4px;
        width: 13px;
        height: 13px;
        margin-right: 7.58px;
      }
    }
  }
  .btn-acao.btn-enviar {
    background-color: #26417b;
    text-transform: uppercase;
    white-space: nowrap;
    &:hover,
    &:focus {
      color: #26417b;
      background-color: transparent;
      border-color: #26417b;

      svg path {
        fill: #26417b !important;
      }
    }
  }
  .btn-cancelar {
    background-color: #e7eaec;
    color: #445566;
    text-transform: uppercase;
    span {
      font: normal 400 14px/22px 'Open Sans', sans-serif !important;
    }
    height: 35px;
    margin-top: 0 !important;
  }
  .btn-acao.btn-salvar {
    background-color: #1ab394;
    padding: 7px 15px 7px 15px;
    margin-left: 30px;
    svg {
      margin-bottom: 4px !important;
    }
    &.save {
      span {
        margin-top: -5px;
      }
    }
    &:hover,
    &:focus {
      color: #1ab394;
      background-color: transparent;
      border-color: #1ab394;

      svg path {
        fill: #1ab394 !important;
      }
    }
  }

  .campo-rede-social {
    border-radius: 2px;
  }

.validade-certificacao .ant-picker.ant-picker-disabled {
  box-shadow: none !important;
}
  }

.viewport-cadastro-usuarios {
  width: 101%;
  display: flex;
  position: relative;
  .btn-flutuante-archive-pen {
    transition: 0s !important;
    right: 11px !important;
    border: 1px solid #16997f;
    background-color: #1ab394;
    &:hover,
    &:focus {
      border: 1px solid #16997f;
      background-color: #fff;
      svg path {
        fill: #1ab394;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    right: 50px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 0 !important;
    svg {
      margin: 0;
      width: 16px;
      height: 16px;
      align-self: center;
    }
  }
}

html {
  overflow-y: scroll;
}

@supports (scrollbar-gutter: stable) {
  html {
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
}

@supports (overflow-y: overlay) {
  html {
    overflow-y: overlay;
    scrollbar-gutter: auto;
  }
}
.cadastro-usuarios-conversation-messages {
  .ant-tooltip-inner {
    transform: translateY(-14px);
    width: 301px !important;
    margin-left: 50px;
  }
  .ant-tooltip-arrow {
    width: 35px;
    height: 35px;
    transform: translate(38%, -10%);
    .ant-tooltip-arrow-content {
      border-radius: 2px;
      width: 20px;
      height: 20px;
    }
  }

  .ibox-content {
    margin-top: 10px !important;
    padding-right: 13px !important;
  }
  .title-label {
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      width: 75px;
      height: 2px;
      background-color: #ff7817;
    }
  }
  .mensagens {
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 73%;
      top: 68px;
      z-index: 100;
      left: 60px;
      background-color: #dcdcdc;
    }
  }
  .list-mensagens {
    height: calc(77vh - 14em) !important;
    position: relative;
  }
  .item-mensagem {
    position: relative;
    direction: ltr !important;
    margin-bottom: 0 !important;
    .texto-mensagem {
      padding-top: 5px;
      flex: 1;
      margin-right: 5px;
      .remetente-title {
        color: #686b6d !important;
        font: normal 600 13px/15px 'Open Sans';
        margin-bottom: 5px !important;
        margin-top: 3px;
      }
      p {
        font: normal 400 13px/15px 'Open Sans';
        color: #686b6d !important;
      }
    }
    .info-autor {
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      .icon-perfil {
        margin: 0;
        border: 1px solid #dcdcdc;
        background-color: blue;
        width: 25px;
        height: 25px;
        text-align: center;
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        margin-right: 1px !important;
        &.perfil-vendedor {
          background-color: #e7eaec !important;
          border: 1px solid #dcdcdc !important;
          svg path {
            fill: #676a6c !important;
          }
        }
        &.perfil-crefaz {
        }
      }
      .data-hora {
        margin-top: 40px;
        white-space: word-wrap;
        margin-right: 2px;
        display: block;
        top: 0;
        transform: none;
        .data {
          max-width: 30px;
        }
        margin-left: 20px;
      }
    }
    .texto-mensagem {
      border-bottom: 1px solid #dcdcdc;
      margin-bottom: -1px;
    }
  }
  .form-row-mensagem {
    margin-left: 49px !important;
    margin-right: 12px;
    .ant-form-item-control-input-content {
      .ant-input {
        border-radius: 0;
        margin-left: -3.5px;
      }
    }
  }
  .btn-salvar {
    margin-top: 15px !important;
    font-family: 'Open Sans' !important;
  }
}

.bullet-info-icon {
  margin-left: 6px !important;
  &.pep {
    margin-left: 5px !important;
    margin-bottom: 3px;
  }
}

.tooltip-pep {
  max-width: 584px;
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 15px;
  border-radius: 3px;
  .ant-tooltip-inner {
    padding: 12px 8px 14px 10px;
    margin-left: -20px;
  }
  .ant-tooltip-arrow {
    overflow: visible;
    transform: translateY(-9px);
    left: 8px;
    z-index: -1;
  }
  .ant-tooltip-arrow-content {
    background-color: transparent !important;
    transform: rotate(0deg);
    width: auto;
    height: auto;
    &::after {
      content: url('../../assets/images/icons/tooltip-pointer.svg');
    }
  }
  &.ant-tooltip-placement-bottomLeft {
    .ant-tooltip-arrow-content {
      transform: rotate(180deg);
      margin-left: 28px;
      margin-top: 30px;
    }
    .ant-tooltip-arrow {
      left: 4px;
    }
  }
}

.switch-separator {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .switch-midia {
    margin-bottom: 0px !important;

    .ant-form-item-control-input {
      min-height: min-content !important;
      margin-bottom: 16px !important;
    }

    .label-switch-termo-midia {
      margin-bottom: 0 !important;
    }

    .termo-midia-button {
      margin-bottom: 0 !important;
    }
  }
}

.tooltip-dados-gerais {
  margin-right: 20px;
  max-width: 584px;
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 15px;
  border-radius: 3px;
  .ant-tooltip-inner {
    padding: 12px 8px 14px 10px;
  }
  .ant-tooltip-arrow {
    overflow: visible;
    transform: translateY(-4px);
    left: 4px;
    z-index: -1;
  }
  .ant-tooltip-arrow-content {
    background-color: transparent !important;
    transform: rotate(0deg);
    width: auto;
    height: auto;
    &::after {
      content: url('../../assets/images/icons/tooltip-arrow-wide.svg');
    }
  }
  &.ant-tooltip-placement-bottomLeft {
    .ant-tooltip-arrow-content {
      transform: rotate(180deg);
      margin-left: 28px;
      margin-top: 21px;
    }
  }
  ul {
    margin: 0 !important;
  }
}

.hierarquia-tooltip-title {
  font-weight: 700;
  font-family: 'Open Sans';
  list-style-type: none !important;
}
.hierarquia-tooltip-body {
  font-family: 'Open Sans';
  font-weight: 400;
  list-style-type: none !important;
}
.deletar-certificacao-contato-button {
  background-color: #ed5565;
  border: 1px solid #d44c5a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41.68px;
  height: 35px;
  border-radius: 0;
  margin-top: 22px;
  &:hover {
    background-color: #ed5565;
    border: 1px solid #d44c5a;
    outline: 0;
  }
  &:focus {
    background-color: #ed5565;
    border: 1px solid #d44c5a;
    outline: 0 !important;
  }
  svg {
    transform: scale(1.2);
  }
}

.switch-todas-as-unidades {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
  margin: 15px 8px;
  margin-left: 0 !important;
  &.disabled {
    height: 20.2px;
    color: #9d9d9d !important;
  }
  .ant-switch {
    min-width: 40px;
    &.ant-switch-disabled {
      height: 20.2px;
      min-width: 18px;
      border: 1px solid #f3f4f5;
      background-color: #fff !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
    }
  }
}

.cadastro-usuarios-historico-hierarquia {
  background-color: #fff;
  .ant-collapse-header {
    background-color: #f7f7f7;
  }
  .ant-collapse-content-box {
    max-height: 271px;
    margin-right: 5px;
    overflow: auto;
    padding: 15px 15px 0 15px;
    margin-bottom: 15px;
    font: 400 14px/19px 'Open Sans', sans-serif;
    &::-webkit-scrollbar-track {
      background-color: #fff;
      margin-top: 15px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #dbdbdc;
      border-radius: 5px;
    }
    .evento-hierarquia {
      color: #686b6d;

      h3 {
        margin: 0;
        margin-top: 5px;
        color: #686b6d;
      }
      &:first-child {
        h3 {
          margin-top: 0px;
        }
      }
      .evento-hierarquia-item {
        .dot {
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }
}

.ant-select-selection-overflow-item {
  .ant-select-selection-item-content {
    color: #686b6d;
    font-family: 'Open Sans' !important;
  }
  .ant-select-selection-item {
    background-color: #e7eaec !important;
    border-radius: 5px;
  }
}
.ant-select-selection-item-remove {
  margin-left: 7px !important;
  padding-right: 5px;
  margin-top: 0.5px;
  path {
    fill: #686b6d !important;
  }
}

.ant-form-item-label > label::after {
  content: '' !important;
}
.cadastro-usuario-page {
  .ant-form-item label {
    color: #686b6d;
    font-family: 'Open Sans';
  }

}
.ant-form-item-control-input-content {
  color: #686b6d !important;
  font-family: 'Open Sans';
}

.btn-certificacao {
  margin-left: -10px;
}

#navbar-toggle {
  border-radius: 3px !important;
}

.page-heading {
  height: 35px !important;
}
.ant-breadcrumb {
  margin-top: -4px !important;
}
.ant-breadcrumb-separator {
  font-weight: bold;
}

.ibox-title {
  padding: 15px 15px 20px !important;
}
.d-flex > .ant-row.ant-form-item {
  margin-bottom: 6px;
}

.card-tooltip-messages
  .ant-tooltip-content
  .ant-tooltip-inner
  .ibox
  .close-tooltip {
  margin: 0;
  top: 3px;
  width: 0px;
  height: 0px;
  right: 21px;
}

.viewport-cadastro-usuarios .btn-flutuante-archive-pen svg {
  width: 12px;
  height: 12px;
}

#close-button-tooltip {
  svg path {
    fill: #9baebf !important;
  }
  &:hover {
    opacity: 1 !important;
  }
}

.atuacao-promotora-switch {
  width: auto !important;
}

.switch-todas-as-unidades-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
  margin-bottom: 3px;
}

.separation-label-switch .switch-todas-as-unidades {
  margin-left: 0;
}

.switch-margin {
  margin-bottom: 5px;
}

.separation-label-switch .label-switch {
  height: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  cursor: context-menu !important;
}

.separation-label-switch {
  display: flex;
  align-items: center;
}

.atuacao-promotora-switch .ant-form-item-control {
  height: 0;
  width: 0;
}

.contato-container.ant-form-item-control-input-content {
  display: flex;
}

.contato-container {
.phone-button,
.phone-button:hover, .phone-button:focus, .phone-button:active {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:3px;
  padding: 5px 12px;
  border-radius: 0px;
  border: 1px solid #1e3361;
  background-color: #26417b;
  color: white;
  width: auto;
  height: 30px;
  margin: 0px !important;
  margin-right: 15px;
  line-height: 1.2em;
  text-transform: uppercase;
  outline: none !important;
  svg {
    width: 15px;
    height: 15px;
  }
}

.phone-button .text-phone-button {
  letter-spacing: 0.175px;
}

  .ant-form-item-control-input-content {
    display: flex;
  }
}

.contact-columns {
  padding: 0px !important;
}
.cadastro-usuario-page .contact-columns {
  .table > tbody > tr > td {
    text-align: center;
  }

  .table-responsive table {
    margin-bottom: 0px;
  }
}

.no-phone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding-block: 2px;
  
  .no-phone-message {
  font-family: 'Open Sans' sans-serif;
  color: #bbbdbf;
  font-size: 14px;
  font-weight: 600;
  }
 
}

.icon-error-contained {
  width: 23px;
  height: 23px;
}

.contact-columns .mensagem-container {
  background-color: #fff;

}

.disabled-pointer {
  cursor: not-allowed;
}

.ant-form-item-has-error .ant-input-number:not([disabled]):hover, .ant-form-item-has-error .ant-picker:not([disabled]):hover  {
  border-color: #ed5555 !important;
  background-color: #ffc2c2 !important;
}
