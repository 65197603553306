.radio-tabs {
  .ant-radio-group {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: rgba(40, 40, 41, 0.7);
  }

  .ant-radio-button-wrapper {
    border: 1px solid #e5e6e7 !important;
  }

  .ant-radio-button-wrapper-checked {
    color: rgba(40, 40, 41, 0.7) !important;
    border: 1px solid #e5e6e7 !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #d9d9d9;
  }

  .ant-radio-button-checked {
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
    border: 1px solid #e5e6e7;
  }
}

@media (max-width: 576px) {
  .radio-tabs {
    display: flex;
    flex-direction: column;
  }
}
