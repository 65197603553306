.btn-edit {
  background-color: #bbbdbf;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 0px;
  padding: 0px;
  border: none;

  .edit-icon path {
    fill: #ffffff;
  }

  &:focus {
    outline: 0px none;
  }

  &:hover {
    transition: all .4s ease-in-out;
    background-color: #26417b;
  }

  &:active {
    background-color: #26417b !important;
  }
}