body {
  -webkit-print-color-adjust: exact;
}

* {
  margin: 0;
  padding: 0;
}

.bg-white {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: relative;
}

.header {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 79px;
  width: 100%;
  background-color: #26417b;
  margin-bottom: 20px;
  border-right: 0;
}

.header .title {
  font: 500 24px/33px sans-serif;
  margin: 14px 0 2.62px 0;
}

.header .subtitle {
  font: 400 13px/15px sans-serif;
  color: #e1e1e1;
  margin-bottom: 14.38px;
}

.header .title,
.header .subtitle {
  margin-left: 18px;
}

.logo {
  margin: 11px 17px;
}

.tabela {
  font: normal 400 9px/10px sans-serif;
  width: calc(100%);
  border: 1px solid #e7eaec;
  color: #646b70;
  border-collapse: collapse;
}

.tHead .tHeadItem {
  height: 35px;
  font-weight: 600;
  border-bottom: 1px solid #e7eaec;
}

.tBody {
  border-top-color: transparent;
}

.trBody .tBodyItem {
  padding: 0 1px;
}

.trBody .tBodyItem:last-child {
  border-right: 1px solid #e7eaec;
}

.tHead .tHeadItem:first-of-type {
  padding-left: 10px;
}

.tHead .tHeadItem:last-of-type {
  padding-right: 10px;
  border-right: 1px solid #e7eaec;
}

.trBody .tBodyItem:last-of-type {
  padding-right: 10px;
}

.tHeadItem {
  text-align: center;
}

.tBody {
  text-align: center;
  font: normal 400 9px/10px sans-serif;
  color: #646b70;
}

.trBody {
  padding: 0 10px;
}

.trBody:nth-child(2n-1) {
  background-color: #f7f7f7;
}

.tBody .tBodyItem {
  height: 30px;
}

.paginacao {
  margin: 10px 40px 15px 0;
  text-align: right;
  page-break-after: always;
}

@page {
  margin: 45px 30px 45px 30px;
  size: landscape;
  position: relative;
}
