.pre-analise-produto-plugin {
    border: 1px solid #D9D9D9;
    padding: 20px;
    transition: 0.4s;

    .bloco-informacao {
        height: 56px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .imagem {
            width: 56px;
            margin-right: 16px;
            padding: 16px;
            border: 1px solid #d9d9d9;
        }

        .titulo {
            width: 100%;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: #4E545A !important;
        }
    }

    .row-convenio label span {
        margin: 0;
    }

    .ant-radio-group {
        display: flex;

        .ant-radio-wrapper {
            display: flex;
            align-items: center;

            span {
                font-size: 16px;
            }

            span.ant-radio+* {
                padding-right: 0px;
                padding-left: 5px;
            }

            .ant-radio-wrapper:hover .ant-radio,
            .ant-radio:hover .ant-radio-inner,
            .ant-radio-input:focus+.ant-radio-inner {
                border-color: #1AB394;
            }
        }

        span.ant-radio-checked {
            .ant-radio-inner {
                border-color: #1AB394;
            }
        }

        .ant-radio-inner {
            width: 24px;
            height: 24px;

            &:hover {
                border-color: #1AB394;
            }

            &::after {
                top: 3px;
                left: 3px;
                height: 16px;
                width: 16px;
                background-color: #1AB394;
            }
        }
    }

    .classificao {
        flex: 1;
        max-width: 100%;

        margin-top: 14px;

        .ant-form-item {
            margin-bottom: 0px;
        }
    }

}

.bloco-radius-buttons {
    .form-item-error {
        display: none;
    }
}

.ant-form-item-has-error {
    .pre-analise-produto-plugin {
        border: 1px solid #ED5565;
    }

    .bloco-radius-buttons {
        display: flex;
        align-items: center;

        .form-item-error {
            display: flex;
            transition: 0.4s;
            font-size: 14px;
            font-weight: 600;
            color: #ED5565;
        }
    }    
}