@media (max-width: 576px) {
  .tabelaListagem {

    // th:nth-child(2) {
    //   display: none;
    // }

    // td:nth-child(2) {
    //   display: none;
    // }

    th:nth-child(3) {
      display: none;
    }

    td:nth-child(3) {
      display: none;
    }

    th:nth-child(4) {
      display: none;
    }

    td:nth-child(4) {
      display: none;
    }

    th:nth-child(5) {
      display: none;
    }

    td:nth-child(5) {
      display: none;
    }

    th:nth-child(6) {
      display: none;
    }

    td:nth-child(6) {
      display: none;
    }

    th:nth-child(7) {
      display: none;
    }

    td:nth-child(7) {
      display: none;
    }

    th:nth-child(8) {
      display: none;
    }

    td:nth-child(8) {
      display: none;
    }

    th:nth-child(9) {
      display: none;
    }

    td:nth-child(9) {
      display: none;
    }

    .table-filter-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.listagem-produtos-parametros{

  .ibox-title{
    padding-right: 7px !important;

    .table-header{
      width: 100%;

      .filter-input{
        padding: 2.5px 10px;

        input{
          font-size: 12.5px;
          line-height: 1em;
          font-style: italic;
        }
      }

      .botao-filtrar{
        margin-left: 20px;
        padding: 6px 14px;
        background-color: #E7EAEC;
        border: 1px solid #CFD2D4;
        height: 35px;
      }
    }

    .col-filter-parametro-produtos{
      padding-right: 0px;
      padding-top: 15px;
    }
  }

  .ibox-content{
    

    .ant-collapse{
      background-color: transparent;
      border: none;

      .ant-collapse-item{
        margin-bottom: 5px;
        background-color: #F7F7F7;
        border: 1px solid #DEE2E6;

        .ant-collapse-header{
          padding: 6px 15px;
          position: relative;

          .ant-collapse-arrow{
            position: relative;
            display: inline-block;
            line-height: 1em;
            margin-right: 12px;
            width: 6px;
            padding: 0px;
            top: 50%;
            left: auto;
            transform: translateY(-25%);
          }
        }
        .ant-collapse-content{


          .ant-collapse-content-box{
            padding: 0px;

            .tabelaListagem{

              .table-responsive{
                margin-bottom: 0px;

                table{

                  thead{

                    tr{

                      th{
                        color: #646B70;
                        font-size: 13px;

                        span{
                          margin-left: 4px !important;

                          i{
                            font-size: 11px;
                          }
                        }
                      }
                    }
                  }
                  tbody{

                    tr{

                      td{
                        color: #686B6D;
                        font-size: 13px;
                        padding: 8px 20px;
                        line-height: 1.2em;

                        &:last-child{
                          padding-left: 5px;
                          padding-right: 15px;
                          text-align: right;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .btn-edit{
      width: 16px;
      height: 16px;
      background-color: #D9D9D9;
      border-color: #D9D9D9;

      &:hover{
        background-color: #26417b;
        border-color: #26417b;
      }

      svg{
        width: 9.5px !important;
        height: 9.5px !important;
      }
    }

    .btn-list{
      border: 1px solid #DEE2E6;
      padding: 6px 20px;

      svg{
        
        path{
          fill: #445566 !important;
        }
      }
    }
    
  }
}


.novoGrupoProduto {
  padding: 5px;
  // width: 89%;
  border: 1px solid;
  border-color: #DEE2E6;
  background-color: #F7F7F7;



  padding-left: 10px;

}


.icon-cancel {
  width: 15px;
  height: 15px;

  margin-left: 5px;
  cursor: pointer;
}


.novoGrupoProduto {
  width: 100%;
  background-color: #F7F7F7;


  &:focus {
    background-color: #F7F7F7;

  }

  &:hover {
    background-color: #F7F7F7;

  }



}

.novoGrupoProduto {

  .ant-input {
    background-color: #F7F7F7 !important;
    border: none !important;

    &:focus {
      background-color: #F7F7F7 !important;

      border: none !important;
    }

    &:hover {
      background-color: #F7F7F7 !important;

      border: none !important;
    }

    &:active {
      background-color: #F7F7F7 !important;

      border: none !important;
    }
  }
}


.novoGrupoProduto {

  .ant-input-group-addon {
    border: none !important;
  }
}

.tabelaListagem tbody{
  text-align: center !important;
}

.tabelaListagem thead{
  text-align: center !important;
}

@media (max-width: 1200px) {

  .tabelaListagem{

    .table-responsive{
      width: 100%;
      overflow-x: auto;

      table{
        white-space: nowrap;
      }
    }
  }

}