.ant-collapse-content-box {
  height: 100%;
}

.table-logs-module {
  .table {
    tbody tr:nth-of-type(odd) {
      background-color: #f7f7f7 !important;
    }
    tbody tr:nth-of-type(even) {
      background-color: #ffffff !important;
    }
    span.ml-1 {
      margin-left: 10px !important;
    }

    th {
      height: 44px !important;
      border-top: 1px solid #dbdbdc !important;
      border-bottom: 1px solid #dbdbdc !important;
      background-color: white;
      font-size: 14px;
      width: 16.666% !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 19px !important;
      color: #3d3d3e !important;
    }
    tr {
      font-size: 13px;
      text-align: center;
    }
  }


    .table-responsive {
      table.table {
        margin-bottom: 0;
        width: 100%;
        th,
        td {
          width: 1%;
          text-align: center;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          border: none;
        }
        thead {
          border-bottom: 1px solid #dbdbdc !important;

          th {
            font-weight: 600;
            color: #3d3d3e;
            height: 44px;
            span {
              margin-left: 10px !important;
            }
          }
        }
        tbody {
          border: none;
          tr {
            td {
              color: #3d3d3e !important;
            }
            td:first-child {
              color: #686b6d;
              font-weight: 400;
            }
            &:nth-child(2n - 1) {
              background-color: #f7f7f7;
            }
          }
        }
      }
    }

  .pagination {
    border-radius: 2px !important;
    margin-right: 10px;
    margin-top: 8px;
    li a {
      color: rgba(33, 37, 41, 0.6);
    }
    li:first-child,
    li:last-child {
      display: none;
    }
    li:nth-child(2),
    li:nth-last-child(2) {
      a > i::before {
        content: 'Anterior';
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        color: rgba(33, 37, 41, 0.6);
      }
    }
    li:nth-last-child(2) {
      a > i::before {
        content: 'Próxima';
        font-family: 'Open Sans' !important;
        font-size: 12px !important;
      }
    }
  }
}
