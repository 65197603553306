.cadastro-usuario-page .wrapper-units {
  max-width: 100%;
  padding: 0 !important;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .select-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px 3px;
    height: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    font-size: 14px;
    .text-select {
      text-transform: capitalize;
    }
  }

  .select-option:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
  }

  .focus-mode {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .disabled-input {
    background-color: #f5f5f5;
    cursor: not-allowed;
    color: blue;
  }

  svg.icon-check,
  .icon-check-collapse {
    width: 18px;
    height: 18px;
    margin-left: 11px;

    path {
      fill: #1b2a4e;
    }
  }

  .icon-check-collapse {
    text-align: end;
  }

  .units-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: relative;
    z-index: 1000;
    background: #fff;
    margin-top: 9px;
    border-radius: 3px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

    overflow: auto;
    max-height: 220px;

    .wrapper-item {
      width: 100%;

      .sub-level {
        border-left: 1px solid #26417b;
      }
    }

    .item-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 7px !important;
      &:hover {
        background: #ececec;
        cursor: pointer;
      }
      .unit-name {
        padding: 6px 3px;
        color: #3D3D3E;
      }
      .selected-option-text {
        color: #1b2a4e;
        font-weight: 600;
      }
    }
  }

  .icon-arrow-select {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
}

.component-label {
  color: #686b6d;
  margin-bottom: 5px;
}

.single-item {
  padding: 6px 5px;

  .unit-selected .unit-name {
    padding-left: 16px;
  }
}

.label-units {
  font: normal 400 14px / 19px 'Open Sans', sans-serif;
  margin-bottom: 5px;
}

.option-selected-list {
  background-color: white;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    color: #686b6d;
    font-family: sans-serif;
  }

  .top-unit {
    padding-inline: 6px;
    color: #686B6D;
    font-weight: 400;
    padding: 6px 5px;
  }

  .subunidade-container {
    font-family: 'Open Sans', sans-serif;
    border-left: 1px solid #1b2a4e;
    padding-left: 7px;
    margin-left: 12px;
  }

  .element-unit {
    font-family: 'Open sans', sans-serif;
    color: #686B6D;
  }

  .element-unit.unit-selected {
    padding: 0px;
  }

  .unit-selected {
    color: #1B2A4E;
    font-weight: 600;
    padding: 6px 5px;
    svg.icon-check {
      width: 18px;
      height: 18px;
      margin-left: 11px;
      margin-bottom: 2px;
  
      path {
        fill: #1b2a4e;
      }
    }
  }
}

.units-options::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.units-options::-webkit-scrollbar-thumb {
  background-color: #dbdbdc;
  border-radius: 3px;
}

.input-unidades {
  width: 100%;
  border: none;
  outline: none;
}

.wrapper-collapse {
  display: flex;
  overflow-y: scroll;
  max-height: 187px;
  margin-block: 16px 20px;

  text-transform: uppercase;

  .unidade-collapse {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 5px;

    svg.icon-check {
      width: 18px;
      height: 18px;
      margin-left: 11px;
      margin-bottom: 2px;

      path {
        fill: #1b2a4e;
      }
    }

    .arrow-select-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px;
        height: 12px;
        path {
          fill: #1b2a4e;
        }
      }
    }
  }

}

.wrapper-collapse::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

.wrapper-collapse::-webkit-scrollbar-thumb {
  background-color: #fa6e0a;
  border-radius: 3px;
}

input.input-unidades:focus::placeholder,
input.input-unidades.placeholder-value::placeholder {
  color: #bfbfbf;
}

input.placeholder-value::placeholder {
  color: #686b6d;
}

input.input-unidades::placeholder {
  color: #686b6d;
}

.input-unidades.has-single-value::placeholder {
  color: #000000d9;
}
