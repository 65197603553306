.login-container {
  width: 100vw;
  height: 100vh;
}

.login-brand {
  background-color: #f3f3f4;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #293846;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.login-title {
  margin-bottom: 2rem;

  & h2 {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 2px;
  }

  & > .underline {
    border: 2px solid #ffffff;
    width: 35px;
  }
}

.login-input {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #7e7e7e;
  color: #7e7e7e;
}

.login-input:focus,
.login-input:hover {
  border-color: #7e7e7e;
  box-shadow: none;
}

.login-item label {
  color: #fff;
  font-size: 13px;
  padding-left: 10px;
}

.login-error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4d4f;
}
