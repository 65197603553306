@media (max-width: 576px) {
  .tabelaObrigatoriedade {

    width: 100%;

    th:nth-child(3) {
      display: none;
    }

    td:nth-child(3) {
      display: none;
    }
  }

  .tabelaDocumentos {

    width: 100%;

    th:nth-child(2) {
      display: none;
    }

    td:nth-child(2) {
      display: none;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
  }

}


@media (min-width: 1200px) {

    .addFields{
      margin-right: 28px;
      margin-left: 25px;
    }

 }

 @media (min-width: 768px) {

  .addFields{
    margin-right: 38px;
    margin-left: 75px;
  }

}





.diaPrimeiroVencimento .ant-select-selection-item {
  background-color: #1AB394;
  color: white;
}

.diaPrimeiroVencimento .ant-select-selection-item .ant-select-selection-item-remove {
  color: white;
}


.insert-icon {
  width: 15px;
  height: 15px;
}

.ant-pagination-item-active a:not([href]):not([tabindex]) {
  background-color: #263659 !important;
}

.ant-pagination-item-active {
  border-color: #263659 !important;
  border: 1px solid #263659 !important;
}


.form-parametros-produtos{

  .title-tabela-docs{
    padding-top: 20px;
    margin-bottom: 15px;
    .ContainerColor{
      background-color: #445566 !important;
      display: inline-block;
      top: 1px;
      margin-right: 5px;
    }
    h4.titleMsg{
      display: inline-block;
      margin: 0px;
      padding-top: 0px;
      line-height: 1em;
      font-size: 13.5px;
      font-weight: 600;
      letter-spacing: 0.02em;
    }
  }

  .form-row{
    
  }

  .form-group{
    margin: 0px;
    display: inline-block;
    width: 100%;
  }

  label{
    color: #686B6D;
    font-size: 13px;
    line-height: 1.2em;
    margin-bottom: 6px;
  }

  .ant-form-item{

    margin-bottom: 16px;

    .tooltip-info{
      position: relative;
      top: 0px;
      margin-left: 5px;
    }
    
    .ant-form-item-label{
      line-height: 1em;
      padding: 0px;
      margin-bottom: 6px;

      label{
        color: #686B6D;
        font-size: 13px;
        line-height: 1.2em;
        margin: 0px;
      }
    }

    &.form-item-input-switch{
      flex-direction: initial;
      -ms-flex-direction: initial;
      margin-bottom: 9px;

      .ant-form-item-label{
        display: inline-block;
        width: auto;
        margin: 0px;
        padding: 0px;
        margin-right: 7px;
        margin-top: 4px;
      }

      .ant-form-item-control-input{
        min-height: auto;
      }
    }


    
    &.form-item-input-com-switch{
      
      .ant-form-item-label{
        margin-bottom: 5px;
        label{

          button{
            margin-left: 5px;
          }
        }
      }
    }
    
    .ant-select{
      
      .ant-select-selector{
        cursor: pointer;

        .ant-select-selection-overflow{

          .ant-select-selection-overflow-item{

            .ant-select-selection-item{
              padding: 4px 8px;
              border: 1px solid #E7EAEC;
              background-color: transparent;

              .ant-select-selection-item-content{
                font-size: 13px;
                color: #5D6062;
                line-height: 1em;
                margin-right: 7px;

              }
              .ant-select-selection-item-remove{
                position: relative;
                width: 9px;
                padding-right: 2px;
                transition: .2s;

                &:hover{
                  opacity: .7;
                  transition: .2s;
                }

                span{
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 9px;

                  svg{
                    width: 9px;

                    path{
                      fill: #495057 !important;
                      opacity: 0.8;
                    }
                  }
                } 
              }
            }
          }
        }
      }
    }

    &.input-tags-primeiro-vencimento{
        
      .ant-select{
        
        .ant-select-selector{
          cursor: pointer;

          .ant-select-selection-overflow{

            .ant-select-selection-overflow-item{

              .ant-select-selection-item{
                background-color: #1AB394;
                border: 1px solid #1AB394;
                border-radius: 0px;
                padding: 4px 6px;

                .ant-select-selection-item-content{
                  color: #ffffff;
                  margin-right: 5px;
                }
                .ant-select-selection-item-remove{

                  span{

                    svg{

                      path{
                        fill: #ffffff !important;
                        opacity: 0.8;
                      }
                    }
                  } 
                }
              }
            }
          }
        }
      }
    }

  }

  .table-responsive{
    margin-bottom: 0px;
  }
  .table{

    thead{
      tr{
        th{
          color: #646B70;
          font-size: 13px;
          font-weight: 400;
          padding: 4px 6px;

          span{
            margin-left: 5px;
            i.fa{
              font-size: 11px;
            }
          }
        }
      }
    }
    
    tbody{
      tr{
        td{
          color: #686B6D;
          font-size: 13px;
          font-weight: 400;
          padding: 6px 5px;
          text-align: center;

          .td-align-left{
            text-align: left;
            padding-left: 12px;
          }

        }
      }
    }

    &.table-final-add{
      width: 100%;
      border: 1px solid #DEE2E6;
      position: relative;
      top: -1px;
      background-color: #fff;

      td{
        padding: 4px 5px !important;
        position: relative;

        p{
          font-size: 13px;
          color: rgb(73, 80, 87);
          padding: 0px 6px;
          margin: 0px;
        }
        
        .ant-form-item{
          margin: 0px;

          .ant-form-item-control{

            .ant-form-item-control-input{
              min-height: auto;
              .ant-form-item-control-input-content{

                input{
                  font-size: 13px;
                  color: rgba(73, 80, 87, 0.5);
                  padding: 2px 6px;
                }
              }
            }
          }
        }

        .ant-select{

          .ant-select-selector{
            height: 26px;
            text-align: left;
            padding-left: 5px;
            padding-right: 5px;

            .ant-select-selection-search{
              
              input{
                height: 25px;
              }
            }
            .ant-select-selection-placeholder{
              font-size: 13px;
              font-weight: 400;
              padding: 6px 3px;
              line-height: 1em;
            }
            .ant-select-selection-item{    
              color: #686B6D;
              font-size: 13px;
              font-weight: 400;
              padding: 6px 3px;
              line-height: 1em;
              text-align: left;
            }
          }
        }
        
      }
    }
  }
  
  .ant-select-arrow{
    top: 48%;
    right: 8px;
  }
    
  .tabela-documentos{
      
    &.tabelaObrigatoriedade tbody {
      text-align: center;

      tr{

        td:nth-child(1){
          width: 12%;
        }
        td:nth-child(2){
          width: 54%;
        }
        td:nth-child(3){
          width: 27%;
        }
        td:nth-child(4){
          width: 7%;
        }
      }
    }
    &.tabelaObrigatoriedade .table-final-add{
      td:nth-child(1){
        padding-right: 0px !important;
      }
      td:nth-child(2){
        padding-left: 16px !important;
      }
    }

    &.tabelaObrigatoriedade thead {
      text-align: center;
    }

    &.tabelaDocumentos tbody {
      text-align: center;

      tr{

        td:nth-child(1){
          width: 60%;
        }
        td:nth-child(2){
          width: 32%;
        }
        td:nth-child(3){
          width: 8%;
        }
      }
    }
    &.tabelaDocumentos .table-final-add{
      td:nth-child(1){
        padding-left: 10px !important;
      }
      td:nth-child(2){
        padding-left: 14px !important;
      }
    }

    &.tabelaDocumentos thead {
      text-align: center;
    }
  }
  
  .btn-edit-table-documentos{
    width: 16px;
    height: 16px;
    svg{
      width: 10px;
      height: 10px;
    }
  }
  
  .btn-confirma-table-documentos{
    background-color: #1AB394;
    width: 16px;
    height: 16px;
    border: 1px solid #1AB394;
    border-radius: 50%;
    line-height: 0px;
    padding: 0px;
    svg{
      width: 10px;

      path{
        fill: #ffffff !important;
      }
    }
    &:hover{
      background-color: #16997F;
      border-color: #16997F;
    }
  }

  .buttons{
    margin-top: 35px;
    
    .btn-salvar{
      padding-left: 18px;
      padding-right: 18px;
      display: inline-block;
      border: 1px solid #16997F;

      svg{
        margin-top: -1px;
        path{
          fill: #fff !important;
        }
      }
    }
  }

  .inputs-add-tarifas{
    align-items: flex-end !important;
    display: flex;
    .btnAddInputs{
      margin: 0px;
      margin-bottom: 16px;
    }

    .form-row{
      margin: 0;

      & > div{
        padding: 0;
      }
      
      & > div:nth-child(1) .ant-select-selector{
        border-radius: 2px 0 0 2px;
        border-right: none;
      }
      & > div:nth-child(2) .ant-select-selector{
        border-radius: 0 2px 2px 0;
      }
    }

  }
  
  .btnAddInputs{
    background-color: #26417B;
    color: white;
    width: auto;
    height: 32px;
    padding-left: 22px;
    padding-right: 22px;
    margin: 0px;
    margin-top: 25px;
    line-height: 1.2em;
    text-transform: uppercase;
    outline: none !important;
  
    svg{
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: 0px;
    }
  
    &:hover,
    &:focus{
      background-color: transparent;
      color: #26417B;
      border-color: #26417B;
    }
  
    &:hover svg path,
    &:focus svg path{
      fill: #26417B !important;
    }
  }
}

.ant-tooltip.tooltip-parametro-produtos{

}
.ant-tooltip.tooltip-parametro-produtos .ant-tooltip-inner{
  padding: 8px 17px;
  font-size: 13px;
  line-height: 1.3em;
}

@media (max-width: 992px){

  .table-header{

    .title-label{
      margin-bottom: 20px;
    }
  }
  
  .btnAddInputs{
    height: 32px;
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 25px;
    line-height: 1.2em;
    text-align: center;
    width: 100% !important;
  
    svg{
      width: 12px;
      margin-right: 4px;
    }
  }

}