.body-content {
  background: #f7f7f7;
  border: 0.5px solid #d9d9d9;
  padding: 10px 20px;

  h3 {
    color: #263659;
    font-size: 16px;
    font-style: normal;
    line-height: 19.5px;
    min-width: 100px;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 13.3px;
    line-height: 19px;
    color: #445566;
  }

  .react-tabs__icon-title-group {
    svg {
      width: 16px;
    }
  }

  .card-icon {
    padding: 10px 9px;

    .card-icon-title {
      .title {
        font-size: 13.3px;

        svg {
          width: 16px;
        }
      }
    }
  }

  .col-esquerda {
    padding: 0 15px;

    .cols-topo {
      margin-bottom: 10px;

      .col-status-operatividade {
        padding: 0 5px;
        width: 30%;
        flex: 0 0 30%;
        max-width: 30%;
      }
      .col-informacoes-cliente {
        padding: 0 5px;
        width: 40%;
        flex: 0 0 40%;
        max-width: 40%;
      }
      .col-etapa-atual {
        padding: 0 5px;
        width: 30%;
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
    .col-tabs {
      padding: 0 5px;
    }
  }

  .col-direita {
    padding: 0 5px;
  }
}
.card-tooltip-messages {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      border: 0;
    }
  }
}

@media (max-width: 800px) {
  .card-mesa-credito {
    .buttons-desk-container {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
}

@media (max-width: 500px) {
  .card-mesa-credito {
    .collapse-historicos {
      .ant-collapse-header {
        font-size: 10px !important;
      }

      .ant-collapse-extra {
        width: 30%;
      }
    }
    .ibox-title {
      display: block;
      height: 325px;
    }
    .title-label {
      padding-bottom: 25px;
    }
    .buttons-desk-container {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    .row .cols-topo {
      display: block;
    }

    .row .cols-topo .col-status-operatividade {
      margin-right: 0px;
      max-width: 100% !important;
      width: 100% !important;
    }
    .col-informacoes-cliente {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 0px;
      max-width: 100% !important;
      width: 100% !important;
    }
    .col-etapa-atual {
      margin-right: 0px;
      max-width: 100% !important;
      width: 100% !important;
    }

    .react-tabs {
      display: block;
    }
    .react-tabs__tab-list {
      width: 100%;
      border: 0px !important;
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: 20px;
    }
    .react-tabs__tab{
      padding: 8px 10px;
      font-size: 12px;
    }
    .react-tabs__tab--selected {
      border-right: 1px solid #d9d9d9;
    }

    .react-tabs__content-holder {
      margin-bottom: 20px;
      display: block;
      width: 100% !important;
      border-left: 1px solid #d9d9d9;
    }
  }
}
