img.remove-multi-email-input-icon
 {
  width: 15px;
  height: 15px;
}

.react-multi-email {
  display: flex;
  gap: 2px 0;
  padding: 3px 10px;
  align-items: center;
  align-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  box-sizing: border-box;

  > span {
    color: #4e545a;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    padding: 0;
  }

  span[data-placeholder] {
    line-height: 19.07px;
    padding: 0;
    left: 0.7em;
  }

  [data-tag] {
    padding: 0;
    margin: 0 5px 0 0;
    height: 23px;
  }

  [data-tag] [data-tag-handle] {
    margin-inline: 0 2px;
  }

  [data-tag] [data-tag-item] {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 2px 5px 2px 5px;
    line-height: 19.07px;
    color: #686b6d;
  }

  input {
    padding: 0 !important;
    height: 23px;
    box-sizing: border-box;
  }
}

.campo-email-controle-proposta .ant-form-item-has-error {
  .ant-form-item-control-input-content {
    input {
      background-color: #ffc2c2;
    }

    input:focus {
      background-color: white;
    }

    span {
      color: #4e545a !important;
    }
  }

  .ant-form-item-control-input-content:hover {
    input {
      background-color: white;
    }
  }
}

.campo-email-controle-proposta {
.ant-form-item-has-error {
  .react-multi-email.focused {
    input:focus {
      background-color: #ffc2c2;
    }
    background-color: #ffc2c2;
    border-color: #ed5555;
  }

  .react-multi-email.focused:hover {
    input:focus {
      background-color: #fff;
    }
    background-color: #fff !important;
  }
}
}