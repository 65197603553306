.list-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-item-icon {
  width: 30px;
  text-align: center;
}

.list-item-label {
  flex-grow: 10;
  margin-left: 1rem;
}

.list-item-value {
  // margin-right: 1rem;
  color: #c4c4c4;
  text-align: right;
}

.list-item-action {
  margin-top: -4px;

  & img {
    cursor: pointer;
  }
}
