@import '../../styles/base/variables';

.product-card {
  background: #ededed;
  border-radius: 20px;
  height: auto;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 10px;
  color: $crefaz-blue;

  .topo-aprovacao {
    text-align: center;
    display: flex;
    justify-content: flex-end;
  }

  .aprov-facilitada {
    font: normal 400 13px/17.7px sans-serif;
    background-color: #1ab394;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;

    .info-icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      path {
        fill: #fff;
      }
    }
  }
  .title-card {
    margin-bottom: 4px;
  }
  .product-name,
  .value {
    color: $crefaz-blue;
    font: 600 normal 28px/28px sans-serif;
  }

  .value {
    font: 600 16px/20px sans-serif;
  }
  .value-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .value {
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font: normal 600 16px/19px 'Open Sans', sans-serif;
      text-align: center;
    }

    .btn-orange {
      color: #fff;
      height: 43px;
      width: 100%;
      border-radius: 5px;
      padding-left: 20px;
      padding-right: 20px;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      .reload-icon {
        margin-left: 10px;
        path {
          fill: #fff;
        }
      }
    }
  }
}

.cp-refin {
  background: #667788;
  color: #fff !important;

  .product-name,
  .value {
    color: #fff;
  }
}

@media (max-width: 581px) {
  .product-card {
    .value-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .value {
        border-radius: 20px;
        padding: 8px 50px;
        width: 100%;
      }
    }
    .btn-blue {
      margin-top: 5px;
      width: 100%;
    }
  }
}
