.filter {
  .ant-select{

    .ant-select-selector{
      cursor: pointer;

      .ant-select-selection-overflow{

        .ant-select-selection-overflow-item{

          .ant-select-selection-item{
            padding: 4px 8px;
            border: 1px solid #E7EAEC;
            background-color: transparent;

            .ant-select-selection-item-content{
              font-size: 13px;
              color: #5D6062;
              line-height: 1em;
              margin-right: 7px;

            }
            .ant-select-selection-item-remove{
              position: relative;
              width: 9px;
              padding-right: 2px;
              transition: .2s;

              &:hover{
                opacity: .7;
                transition: .2s;
              }

              span{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 9px;

                svg{
                  width: 9px;

                  path{
                    fill: #495057 !important;
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  background: #F7F7F7;
  padding: 15px 25px;
  border: 0.5px solid #B8B9BC;
  margin-bottom: 20px;


  label {
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    padding: 0;
  }

  .col-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    button {
      width: 108px;
      font-weight: 400;
      color: #fff;
      height: auto;
      font-weight: 400;
      line-height: 19px;
      font-size: 13px;
    }
  }  
}

.filter-item-container {
  text-align: start;
  margin: 0;
  margin-bottom: 15px !important;
 }

 .ant-form-vertical .ant-form-item-label {
   padding-bottom: 7px;
 }

.filter-actions .clear-button {
  margin-top: 10px;
}