/* Only demo */

@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.btn-toggle {
  margin: 14px;
}

// .navbar-header {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-left: 0.75rem;
// }

// .navbar-top-links {
//   margin-right: 0.75rem;
// }

// .navbar-top-links li a:last-child {
//   padding: 20px 0px;
// }

// Form control status error
.form-text {
  font-size: 10px;
  margin-top: 5px;
  font-weight: 400;
  line-height: 10px;
}

.form-text.has-error {
  color: $red;
}

.btn-form-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.p-invalid {
  color: red;
}

// div[role=alert] {
//   color: #FA6E0A;
//   font-size: 10px;
//   font-weight: 400;
// }

.ant-form-item-has-warning .ant-input {
  border-color: #fa6e0a;
  background-color: #fae9c3;
}

.ant-form-item-has-warning .ant-select-selector {
  border-color: #fa6e0a !important;
  background-color: #fae9c3 !important;
}

.ant-form-item-has-warning .ant-picker ant-picker-focused {
  border-color: #fa6e0a !important;
  background-color: #fae9c3 !important;
}

.ant-form-item-has-warning .ant-input-number-input-wrap {
  border-color: #fa6e0a !important;
  background-color: #fae9c3 !important;
}

.ant-form-item-has-warning .ant-form-item-control-input {
  border-color: #fa6e0a !important;
  background-color: #fae9c3 !important;
}

.ant-form-item-explain-warning {
  color: #fa6e0a !important;
  font-size: 10px;
  margin-top: 5px;
}

.ant-form-item-has-error {
  .ant-input {
    border-color: #ed5555;
    background-color: #ffc2c2 !important;
  }
  .ant-picker {
    border-color: #ed5555;
    background-color: #ffc2c2;
  }
  .ant-select-selector {
    border-color: #ed5555 !important;
    background-color: #ffc2c2 !important;
  }
  .ant-picker .ant-picker-focused input {
    border-color: #ed5555 !important;
    background-color: #ffc2c2 !important;
  }
  .ant-picker .ant-picker-focused {
    border-color: #ed5555 !important;
    background-color: #ffc2c2 !important;
  }

  .ant-input-number-input {
    border-color: #ed5555 !important;
    background-color: #ffc2c2 !important;
  }

  .ant-input-number-input-wrap {
    border-color: #ed5555 !important;
    background-color: #ffc2c2 !important;
  }

  .ant-picker .ant-form-item-control-input {
    border-color: #ed5555 !important;
    background-color: #ffc2c2 !important;
  }
}

.ant-form-item-explain-error {
  color: #ed5555;
  font-size: 10px;
  margin-top: 5px;
}

.valor-alterado-luz-dia-input {
  border-color: #fa6e0a !important;
  background-color: #fae9c3 !important;
}

.valor-alterado-luz-dia-explain {
  color: #fa6e0a !important;
  font-size: 10px;
  margin-top: 5px;
}

.ant-form-item-children-icon {
  margin-top: -13px !important;
}

.ant-picker-input {
  flex-direction: row-reverse;
}

.ant-picker-suffix {
  margin-right: 10px;
}

.ant-picker-suffix {
  margin-right: 10px;
}

.ant-btn {
  font-weight: 400 !important;
  white-space: normal !important;
}

.ant-btn:focus {
  outline-width: 2px !important;
  outline-color: #2ea9fb !important;
  outline-style: solid !important;
}

.ant-popover-message-title {
  width: 250px;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #00000f !important;
  border-left-color: #00000f !important;
}

.page-item.active .page-link {
  background-color: #263659;
  border-color: #263659;
}
.ant-select-dropdown {
  width: auto !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ececec !important;
}

.filled {
  .ant-select-selector {
    background: #e7eaec !important;
    border-radius: 2px !important;
    border: 0 !important;

    color: #263659;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
  }
}

.table-responsive {
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    height: 5px;
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }

  table {
    margin-bottom: 4px;
  }
}
