.wrapper-produtos-financeiros-title-icon {
  display: flex;
  align-items: center;
  margin: 0 0 14px 10px;

  .title-produtos-financeiros {
    color: #495057;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-left: 10px;
  }

  .circle-icon-produtos-financeiros {
    width: 21px;
    height: 21px;

    path {
      fill: #208d81;
    }
  }
}

.wrapper-card-crefaz-mais {
  &.has-product{
    margin-top: 54px;
  }

  .crefaz-mais-logo {
    width: 142px;
    margin: 0 0 20px 8px;
  }
}