// Basic Colors
$navy: #1ab394; // Primary color
$dark-gray: #c2c2c2; // Default color
$blue: #1c84c6; // Success color
$lazur: #23c6c8; // Info color
$yellow: #f8ac59; // Warning color
$red: #ed5565; // Danger color

// Crefaz Colors
$crefaz-blue: #263659;
$crefaz-orange: #ff7817;
$crefaz-gray-dark: #686b6d;
$crefaz-gray-light: #686b6d;

// Various colors
$text-color: #676a6c; // Body text
$gray: #f3f3f4; // Background wrapper color
$light-gray: #d1dade; // Default label, badge
$label-badge-color: #5e5e5e;
$light-blue: #f3f6fb;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// Basics
$basic-link-color: #337ab7;

// IBOX colors ( default panel colors)
$border-color: #e7eaec; // IBox border
$ibox-title-bg: #ffffff; // IBox Background header
$ibox-content-bg: #ffffff; // IBox Background content

// Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: image-url('./patterns/shattered.png');

// Border radius for buttons
$btn-border-radius: 3px;

// Navigation
$nav-bg: $crefaz-blue;
$nav-profile-pattern: image-url('./patterns/header-profile.png');
$nav-profile-pattern: $crefaz-blue;
$nav-text-color: #a7b1c2;
