.modal {
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  .container {
    background-color: #fff;
    width: 90%;
    height: 90%;
  }
  .modal-header {
    top: 10px;
    width: 100%;
    height: auto;
  }
  .modal-body {
    width: 100%;
    height: auto;
  }
  .close {
    background-color: transparent;
    border: none;
    outline: none;
    width: 10px;
    height: 10px;
    &:before,
    &:after {
      content: ' ';
      position: absolute;
      width: 1.75px;
      height: 12.5px;
      background-color: rgba(73, 80, 87, 0.4);
      float: right;
      cursor: pointer;
      display: flex;
      position: relative;
      top: 5px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
