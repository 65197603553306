.notas-fiscais-table {
  .table-responsive {
    margin-top: 5px;

    table.table {
      border: 1px solid #d9d9d9;

      thead {
        border: none;

        tr {
          border: none;

          th {
            text-align: center;
            border: none;
            padding: 10px 7px;
            color: #646b70;
            font-size: 13px;
            font-weight: 600;
            white-space: nowrap;

            span {
              margin-left: 5px;

              .fa {
                font-size: 10.5px;
                color: #373b3e;
              }
            }
          }
        }
      }

      tbody {
        border: none;

        tr {
          border: none;

          td {
            text-align: center;
            border: none;
            white-space: nowrap;

            color: #686b6d;
            font-size: 13px;
            padding: 6px 7px;

            .ant-switch {
              display: inline-block;
              height: 12px;
              width: 24px;
              min-width: 24px;

              .ant-switch-handle {
                width: 12px;
                height: 12px;
                top: 0px;
                left: -1px;

                &:before {
                  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
                }
              }

              &.ant-switch-checked {
                .ant-switch-handle {
                  right: 0px !important;
                  left: auto !important;
                }
              }

              &.ant-switch-disabled {
                opacity: 1 !important;
                cursor: default;

                .ant-switch-handle {
                  cursor: default;
                }
              }
            }
          }
        }
      }

      .link-download {
        cursor: pointer;
        transition: 0.2s;
        width: 13px;
        height: 15px;
        padding-top: 0;

        path {
          fill: #c4c4c4 !important;
        }

        &:hover {
          opacity: 0.8;
          transition: 0.2s;
        }
      }
    }
  }
}
