.ant-form-item-has-error.ant-form-item-has-feedback{
    
    .ant-input.ant-input-phone-call{

        .ant-input-suffix{

            display: none;
        }
    }
}

.ant-input.ant-input-phone-call{

    position: relative;

    .input.ant-input{
        padding-right: 26px;
    }

    .ant-input-suffix{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 100%;
        padding: 0px;
        
        button.sufix-icon{
            height: 100%;
            width: 100%;
            line-height: 1em;
            background-color: #263659;
            border: none;
            box-shadow: none;
            padding: 0 8px;
            transition: .2s;

            &:hover{
                opacity: .85;
                transition: .2s;
            }

            svg{
                width: 15px;

                path{
                    fill: #ffffff;
                }
            }
        }
    }

    &::before{
        content: "";
        display: none !important;
    }
}