.topo-relatorio-comissoes-notas-fiscais {
  width: 100%;

  .title-notas-fiscais {
  }
  .botoes {
    button {
      &.btn-anexar-notas {
        text-transform: none;
        color: #6d757d;
      }

      &.btn-filtrar {
      }

      &:hover,
      &:focus {
        outline: 2px solid #2ea9fb !important;
      }
    }
  }
}
