.tabelaListagemCadastro {
  td:nth-child(3) {
    display: none;
  }
  th:nth-child(3) {
    display: none;
  }
}

@media (min-width: 1030px) {
  .tabelaListagemCadastro {
    table {
      overflow: hidden;
    }
  }
}

@media (max-width: 576px) {
  .tabelaListagemCadastro {
    .table-responsive {
      overflow-x: hidden !important;
    }
    .table-filter-container {
      display: flex;
      flex-direction: column;
    }
    td:nth-child(n + 2) {
      display: none;
    }
    th:nth-child(n + 2) {
      display: none;
    }

    td:nth-child(3) {
      display: table-cell;
    }
    th:nth-child(3) {
      display: table-cell;
    }

    th:nth-child(8) {
      display: table-cell;
    }
    th:nth-child(9) {
      display: table-cell;
    }
    td:nth-child(8) {
      display: table-cell;
    }
    td:nth-child(9) {
      display: table-cell;
    }
  }
}
@media (max-width: 390px) {
  .table-responsive {
    width: 100%;
    overflow-x: scroll;
    .table-striped {
      white-space: wrap;
    }
  }
  td:nth-child(3) {
    width: 100%;
  }
  th:nth-child(3) {
    width: 100%;
  }

  td:nth-child(8) {
    display: table-cell;
    position: relative;
  }
  td:nth-child(9) {
    display: table-cell;
    position: relative;
  }
  th:nth-child(8) {
    display: table-cell;
    position: relative;
  }
  th:nth-child(9) {
    display: table-cell;
    position: relative;
  }
}

.cadastroPessoaContainer {
  @media (max-width: 1024px) {
    .table-responsive {
      width: 100%;
      overflow-x: scroll;
      .table-striped {
        white-space: nowrap;
      }
    }
  }

  .full-table {
    border: 1px solid #e7eaec;
  }

  .tabelaListagemCadastro .table-responsive {
    border: 1px solid #d9d9d9;
  }
  .tabelaListagemCadastro .table-responsive tr td {
    border-top: 1px solid #d9d9d9;
  }
  .tabelaListagemCadastro .table-responsive tbody tr:nth-of-type(odd) {
    background-color: #f7f7f7;
  }

  .tabelaListagemCadastro tbody {
    text-align: center !important;
  }

  .tabelaListagemCadastro thead {
    text-align: center !important;
    background-color: transparent;
  }

  .table thead tr {
    background-color: transparent;
  }
  .table thead tr th {
    font-size: 13px;
    color: #686b6d;
    background-color: transparent;
    text-align: center;
    border: none !important;
  }

  .table tbody tr td {
    font-size: 13px;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #686b6d;
    text-align: center;
    cursor: default;
  }
  .ant-input:placeholder-shown {
    font-style: italic;
  }
  .btn-edit {
    width: 15px;
    height: 15px;
    border: none;
    svg {
      width: 8.5px;
      height: 8.5px;
    }
  }
}

.cadastroPessoaContainer {
  .btn-filtrar {
    border: 1px solid #cfd2d4;
    outline: none !important;

    svg {
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .ibox-content {
    padding-top: 15px;
  }

  .box-filtros {
    background-color: #f7f7f7;
    border: 1px solid #b8b9bc;
    padding: 20px;
    margin-bottom: 20px;

    .col-inputs {
      padding-top: 10px;

      .form-row {
        margin: 0 -7px;

        & > div {
          padding: 0 7px;
        }
      }

      .ant-form-item {
        margin-bottom: 19px;

        .ant-form-item-label {
          margin: 0px;
          padding: 0px;
          margin-bottom: 6px;
          line-height: 1em;

          label {
            line-height: 1em;
            margin: 0px;
            color: #686b6d;
            font-size: 13px;
          }
        }
        .ant-form-item-control {
          outline: none !important;
          border: none !important;
          box-shadow: none !important;

          .ant-form-item-control-input {
            outline: none !important;
            border: none !important;
            box-shadow: none !important;

            input {
              border: 1px solid #d9d9d9;
              padding: 6px 10px;
              background-color: #ffffff;
              border-radius: 0px;
              font-size: 13px;
              line-height: 1em;
              min-height: 0px;
              color: #6d757d;
              outline: none !important;
              box-shadow: none !important;
              width: 100%;

              &::placeholder {
                color: #bbbdbf;
              }
            }
          }
        }
      }
    }

    .col-botoes {
      padding-top: 0px;
      padding-left: 15px;

      button {
        width: 100%;
        display: inline-block;
        box-shadow: none !important;
        outline: none !important;
        text-align: left;
        height: auto;
        padding: 9px 15px;
        padding-right: 5px;
        line-height: 1.1em;

        svg {
          margin-right: 10px;
          width: 15px;
          height: auto;
        }
        &.btn-pesquisar {
          margin-bottom: 15px;
          border: 1px solid #1e3361;
          background-color: #26417b;

          &:hover {
            background-color: #1e3361;
          }
        }
        &.btn-limpar {
          border: 1px solid #cfd2d4;
        }
      }
    }
  }
}
