.credenciado-page-styles {
  .ibox-title {
    padding: 15px 32px 16px 15px !important;

    h3 {
      color: #26417b;
      margin-top: 0 !important;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
    }
    .card-buttons {
      display: flex;

      button,
      select {
        display: flex;
        margin-right: 10px;
        align-items: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .btn-cancelar {
        background-color: #e7eaec;
        border: 1px solid #cfd2d4;
      }
      .btn-salvar {
        svg {
          margin: auto;
          width: 16px;
          height: 15px;
          margin-right: 9px;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      .btn-status {
        width: 123px;
        background-color: #26417b;
        border: 1px solid #1e3361;
        color: #ffff;
        display: flex;
        align-items: flex-start;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        svg {
          path {
            fill: #ffff;
          }
        }
        .ant-select-selector {
          background-color: transparent;
          border: none;
          .ant-select-selection-item {
            line-height: 13px;
            padding: 10px 23px 10px 1px;
          }
        }
      }
    }
  }
  .historico-dados-credenciado-panel {
    .ant-collapse-header {
      position: relative !important;
      color: #445566 !important;
      font: 400 normal 13px/19.5px 'Open Sans', sans-serif;
      padding-top: 8px !important;
      padding-bottom: 7px !important;
      padding-left: 29px !important;
      .anticon {
        padding: 0 !important;
        position: absolute;
        transform: translateY(-50%);
        top: 50% !important;
        left: 10px !important;
      }
    }
    .ant-collapse-content-box {
      padding: 0;
      .table-responsive {
        table.table {
          margin-bottom: 0;
          width: 100%;
          th,
          td {
            width: 1%;
            text-align: center;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-size: 14px;
            line-height: 19px;
            border: none;
          }
          thead {
            border-bottom: 1px solid #dbdbdc !important;

            th {
              font-weight: 600;
              color: #3d3d3e;
              height: 44px;
              span {
                margin-left: 10px !important;
              }
            }
          }
          tbody {
            border: none;
            tr {
              td {
                color: #3d3d3e;
              }
              td:first-child {
                color: #686b6d;
                font-weight: 400;
              }
              &:nth-child(2n - 1) {
                background-color: #f7f7f7;
              }
            }
          }
        }
      }
    }
  }

  .dados-credenciado-page {
    .ant-input,
    .ant-select-selector,
    .ant-picker {
      border-radius: 0 !important;
    }
  }
}
.rc-virtual-list {
  max-width: auto;
}

.pagination {
  margin-right: 25px;
  li a {
    color: rgba(33, 37, 41, 0.6);
  }
  li:first-child,
  li:last-child {
    display: none;
  }
  li:nth-child(2),
  li:nth-child(7) {
    a > i::before {
      content: 'Anterior';
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      color: rgba(33, 37, 41, 0.6);
    }
  }
  li:nth-child(7) {
    a > i::before {
      content: 'Próxima';
    }
  }
}

.btn-status-dropdown {
  &:nth-child(1) {
    width: 100% !important;
  }
}

.ant-collapse-content-box {
  padding: 0 !important
}

.ant-collapse-header {
  position: relative !important;
  color: #445566 !important;
  font: 400 normal 13px/19.5px 'Open Sans', sans-serif;
  padding-top: 8px !important;
  padding-bottom: 7px !important;
  padding-left: 29px !important;
  .anticon {
    padding: 0 !important;
    position: absolute;
    transform: translateY(-50%);
    top: 50% !important;
    left: 10px !important;
  }
}
