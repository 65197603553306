.card-pacote-servico{
    padding: 8px;
    background-color: #F4F4F4;
    border-radius: 8px;
    width: 240px;
    max-height: fit-content;
    transition: background-color 0.1s ease;
    font-family: "Open Sans";

    &.not-best-choice{
        padding-top: 35px;
    }
    &:hover{
        cursor: pointer;
    }

    &.selected {
        background-color: #369B7F;
        .indication{
            .info{
                color: #FFFFFF;
            }
            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }
    }

    .indication{
        justify-content: center;
        align-items: end;
        display: flex;
        gap: 4px;
        margin-bottom: 8px;

        .info{
            color: #088173;
            font-size: 10px;
            font-weight: 400;
        }
    }

    .infos-pacote{
        display: flex;
        flex-direction: column;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #FFFFFF;
        padding: 16px 18px 8px 18px;

        .title{
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            margin-bottom: 4px;
            color: #263659;
            line-height: 22px;
        }
        .sub-title{
            font-weight: 600;
            font-size: 10px;
            text-align: center;
            margin-bottom: 8px;
            color: #7D869B;
        }
        .infos-price{
            display: flex;
            gap: 5px;
            justify-content:center;
            .cifrao{
                color: #267468;
                font-weight: 700;
                font-size: 7.89px;
                padding-top: 8px;

            }
            .price{
                color: #267468;
                font-weight: 700;
                font-size: 40px;
                text-align: center;
                line-height: 48px;
            }
            
        }
        .period{
            color: #9A9B9C;
            font-weight: 700;
            font-size: 10px;
            text-align: center;

        }
    }
    .infos-expanded{
        display: flex;
        gap: 6px;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #F4F4F4;
        background-color: #FFFFFF;
        padding: 8px 16px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &.expanded-list,&.border-rad{
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .title{
            color: #267468;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
        }

        svg {
            transition: transform 0.3s ease;
          }
      
          .rotated {
            transform: rotate(180deg);
          }

    }

    .benefits-list {
        display: flex;
        padding: 4px 8px 8px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        background-color: #ffffff;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &.border-rad{
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    
        .item-list {
            display: flex;
            gap: 4px;
            align-items: center;
          
            .description{
                color: #263659;
                font-size: 10px;
                font-weight: 400;
            }
        }
    }
    .add-list{
        border-top: 1px solid #F4F4F4;
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #FFFFFF;
        text-align: start;
        color: #263659;
        font-size: 12px;
        .infos-add-list{
            display: flex;
            gap: 4px;
        }
        .font-normal{
            font-weight: 400;
        }

        .price-add{
            display: flex;
            gap: 4px;
            align-items: center;
            margin-top: 10px;
            margin-bottom:20px;
            .price{
                font-weight: 700;
            }

        }
        
    }
    .ant-radio-group {
        .ant-radio-wrapper {
            span {
                color: #3D3D3E;
                font-family: "Open Sans";
                font-size: 14px;
                font-weight: 400;
            }

            span.ant-radio+* {
                padding-right: 0px;
                padding-left: 5px;
            }
           
            .ant-radio-wrapper:hover .ant-radio,
            .ant-radio:hover .ant-radio-inner,
            .ant-radio-input:focus+.ant-radio-inner {
                border-color: #1AB394;
            }
        }

        span.ant-radio-checked {
            .ant-radio-inner {
                border-color: #1AB394;
            }
        }

        .ant-radio-inner {

            &:hover {
                border-color: #1AB394;
            }

            &::after {
                background-color: #1AB394;
            }
        }
    }
}