.processamentoCrivo {
    .RelatorioResponse {
        .title {
            margin-top: 20px;
            margin-left: 5px;
            font-size: 24px;
            line-height: 32.68px;
            color: #383838;
        }
        .line {
            display: flex;
            align-items: center;
            width: 100%;
            height: fit-content;
            border: none;
            color: #686B6D;
            padding-left: 10px;
            padding-top: 2px;
            padding-bottom: 2px;
            .circle {
                width: 10px;
                height: 10px;
                background-color: #ED5565;
                border-radius: 50%;
                margin-right: 5px;
            }
            .crit {
                display: flex;
                .strong {
                    margin-right: 3px;
                }
                margin-right: 10px;
            }
            .return {
                display: flex;
                .strong {
                    margin-right: 3px;
                }
                margin-left: 10px;
            }
            .strong {
                font: bold 14px/19px sans-serif;
            }
            .response {
                margin: 0;
            }
            .bar {
                width: 1px;
                height: 18px;
                background-color: #585656;
            }
        }
        .titleDriver {
            height: 20px;
        }
    }
}