.export-buttons {
  .ant-btn {
    font-weight: 600;
    color: #686b6d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: 1px solid #9baebf;
  }

  .anticon svg {
    width: 16px;
  }
}

.card-proposta-visao-analitica {
  padding-inline: 0px;
}

.analitico {
  .tag-color {
    min-width: 60px;
  }
}

@media (max-width: 576px) {
  .analiticoList {
    th:nth-child(2) {
      display: none;
    }

    td:nth-child(2) {
      display: none;
    }
    th:nth-child(3) {
      display: none;
    }

    td:nth-child(3) {
      display: none;
    }
    th:nth-child(4) {
      display: none;
    }

    td:nth-child(4) {
      display: none;
    }
    // th:nth-child(5) {
    //   display: none;
    // }

    // td:nth-child(5) {
    //   display: none;
    // }
  }
  .btns {
    width: 100%;
    display: flex;
  }
}

.analitico {
  .clockIcon {
    margin-right: 2px !important;
    margin-bottom: 2px !important;
  }

  .titulo {
    align-content: flex-end;

    h3 {
      margin: 0;
    }
  }
  .widget-relatorio-analitico {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    .widget-title {
      font-weight: 600;
    }
    .first-data-group {
      text-align: right;
    }
    .direcionadas {
      margin-bottom: 5px;
      margin-top: 0px;
    }

    .porcentagem {
      margin-bottom: 0cm;
      margin-top: 15px;
    }

    .group-direcionadas {
      margin-bottom: -9px;
    }
  }
  .tabela {
    .title-label {
      margin-top: 10px;
    }

    tbody {
      border-top: none;
    }

    th {
      font-weight: normal;
      font-size: 13px;
      color: #646b70;
      border-bottom: none;

      .fa-sort {
        color: #373b3e;
        font-size: 10.5px;
      }
    }

    td {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 13px;
      color: #686b6d;
      border-top: none;

      .ant-tag-has-color {
        font-weight: 600;
        font-size: 11px;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #263659;
    font-weight: 550;
  }

  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-nav-wrap {
    flex: none !important;
    max-width: 70%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav-wrap::before {
    position: absolute;
    right: 50px;
  }

  .ant-tabs-nav {
    align-content: center !important;
    justify-content: center;
  }

  .ant-tabs-extra-content :hover {
    path {
      fill: #cccccc;
    }
  }

  .arrow-left {
    height: 31px;
    margin-right: 5px;
  }

  .arrow-right {
    height: 31px;
    margin-left: 5px;
  }

  .ant-tabs-tab {
    margin-right: 0px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    color: #bbbdbf;
    font-size: 18px;
  }

  .ant-tabs-nav-list {
    background: white;
  }

  .ant-tabs-nav-list:last-child {
    border-right: 1px solid #dadada;
  }

  .ant-tabs-tab:hover {
    color: #263659;
  }

  .ant-tabs-ink-bar {
    background: #fa6e0a;
    bottom: inherit !important;
  }

  .table-responsive {
    border: 1px solid #d9d9d9;
  }

  .Container {
    margin-right: 5.5px;

    .btn-white {
      border: 1px solid #9baebf;
    }

    .btn-gray {
      border: 1px solid #cfd2d4;
    }
  }
}

