@import '../../styles/base/variables';

.product-card-form {
  border-radius: 20px;
  border: 2px solid #ededed;

  .product-card-header {
    background: #ededed;
    height: 100px;
    padding: 20px;
    border-radius: 15px 15px 0px 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .product-name {
      font-size: 28px;
      color: $crefaz-blue;
    }

    .title-buttons {
      display: flex;

      .btn-orange {
        height: 43px;
        border-radius: 5px;
        font-weight: 600 !important;
      }

      .btn-blue {
        height: 43px;
        border-radius: 5px;
        font-weight: 600 !important;
      }
      .x-button {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px 14px;
        margin: 0 10px 0 16px;
        font: normal 600 17px/19.5px sans-serif;
        border-radius: 5px;
        color: #263659;
        outline: none;
        border: 2px solid #263659;

        &:hover {
          border: 2px solid #2ea9fb;
        }
      }
    }
  }

  .cp-refin {
    background: #667788;
    color: #fff !important;

    .product-name {
      color: #fff;
    }

    .x-button {
      background-color: #263659 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px 14px;
      margin: 0 10px 0 16px;
      font: normal 600 17px/19.5px sans-serif;
      border-radius: 5px;
      color: #fff !important;
      outline: none;

      &:hover {
        border: 2px solid #2ea9fb;
      }
    }
  }

  .product-card-content {
    padding: 20px;
  }
}

@media (max-width: 581px) {
  .product-card-form {
    .product-card-header {
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
