.icone-arquivo {
    cursor: pointer;
    .ant-upload-list-item {
        .ant-upload-list-item-info {
            .ant-upload-span {
                .ant-upload-list-item-thumbnail {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .ant-upload-list-item-actions {
            a {
                opacity: 1 !important;
            }
        }
    }
}

.proposta-credito-uploads {

    .titulo-arquivo {
        background-color: #263659;
        border-radius: 2px;
        padding: 0 4px;
        height: 50px;
        position: relative;
        h5 {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.03em;
          text-align: center;
          color: #fff !important;
          position: relative;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          outline: none !important;
          box-shadow: none !important;
        }
      }

    .ant-select-selector {
        background-color: #263659 !important;
    }

    &.arquivos-pendentes {
        border-color: #ed5555 !important;
        background-color: #ffc2c2 !important;
    }
    border: 2px dashed #d9d9d9;
    padding: 23px 23px;
    padding-right: 10px;
    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-select-picture-card {
        width: 145px;
        height: 155px;
        margin: 0 20px 20px 0;
        &:hover {
            border-color: #455667;
        }
    }
    .ant-upload-list-picture-card-container {
        height: auto;
        p.data-upload-item {
            color: #495057;
            opacity: 0.8;
            text-align: center;
            width: 100%;
            line-height: 1em;
            font-size: 12px;
            font-style: italic;
            padding-right: 3px;
            margin-bottom: 8px;
        }
        .ant-upload-list-item {
            margin-bottom: 3px;
            height: 155px;
            background-color: #ffffff;
        }
        .ant-select {
            .ant-select-selector {
                background-color: #263659;
                border-radius: 2px;
                padding: 0 4px;
                font-size: 11.5px;
                text-align: center;
                color: #fff !important;
                height: 52px;
                position: relative;
                outline: none !important;
                box-shadow: none !important;
                .ant-select-selection-placeholder {
                    height: auto;
                    line-height: 0.6em;
                    overflow: visible;
                    position: absolute;
                    color: #fff !important;
                    opacity: 0.5;
                    top: 48%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .ant-select-selection-item {
                    color: #fff !important;
                    white-space: normal;
                    height: auto;
                    line-height: 1.35em;
                    width: 100%;
                    overflow: visible;
                    position: absolute;
                    color: #fff !important;
                    opacity: 0.8;
                    top: 48%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    &:after {
                        content: "";
                        display: none;
                    }
                }
            }
            .ant-select-arrow {
                right: 7px;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 0px;
                svg {
                    width: 9px;
                }
            }
        }
    }
    .ant-upload {
        .ant-upload-list-item {
            padding: 0px;
        }
        .anticon-plus {
            margin-bottom: 20px;
            svg {
                width: 30px;
                height: 30px;
            }
            svg path {
                fill: #445566;
            }
        }
        .action-anexar {
            font-size: 14px;
            color: #686b6d;
            line-height: 1.1em;
            padding: 0 20px;
        }
    }
}

.dropdown-select-upload-proposta-credito {
    .ant-select-item-option {
        padding: 7px 12px;
        .ant-select-item-option-content {
            white-space: normal;
            font-size: 12px;
            line-height: 1.25em;
        }
    }
}

.modal-preview-upload {
    .ant-modal-content {
        padding: 25px 55px 25px 25px !important;
        max-height: 100% !important;
        overflow-y: hidden !important;
    }
    .ant-modal-body {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        overflow-y: hidden !important;
    }
    object {
        max-height: 100%;
        top: 0;
        position: absolute;
        margin: 0 !important;
    }
    .file-preview-container {
        max-height: 100% !important;
        position: relative;
        overflow-y: hidden !important;
        padding: 0 !important;
    }
}

.modal-preview-image {
    overflow: auto ;
    max-height: 100% ;
  }

.com-titulo {
    .ant-modal-content {
        padding: 25px 25px 81px 25px !important;
    }
}
