
// Estilização Modal Unidades
.ant-modal-header{
    border-bottom: none;
    .title-label{
        .title-line{
            width: 20px;
        }
    }
}
.ContainerColor {
    width: 12px;
    height: 12px;
    background-color: #ff7817 !important;
    position: relative;
    top: -1px;
    margin-left: 5px;
    margin-right: 0;
}

.ant-modal-header{
    padding: 15px !important;
}
.ant-modal-body{
    padding: 5px 15px 21px 15px !important;
    .ant-form-item{
        margin-bottom: 0 !important;
        .ant-form-item-control{
            margin-bottom: 15px !important;
        }
        .ant-form-item-label{
            padding-bottom: 0;
        }
    }
    .col-modal-cadastro-unidade{
        .dados-gerais{
            .header-sub-item{
                padding-top: 0 !important;
                .titleMsg{
                    margin-right: 11px;
                }
                .ant-form-item{
                    .ant-switch{
                        margin-right:4px;
                        height: 12px;
                        &.ant-switch-checked{
                            .ant-switch-handle{
                                left: calc(100% - 9px - 2px) !important;
                            }
                        }
                        .ant-switch-handle{
                            top: 0;
                            left: -1px;

                        }
                    }
                }
                .switch-dado{
                    .ant-form-item{
                        .ant-form-item-control{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .btn-trash{
            cursor: pointer;
            background-color: #ED5565;
            border: 1px solid #D44C5A;
            color: white;
            border-radius: 0px;
            outline: none !important;
            padding: 7px 13px;
            svg{
            width: 13px;
            }

            &:hover,
            &:focus{
            color: #D44C5A;
            background-color: #fff;
            border-color: #D44C5A;
            }

            &:hover svg path,
            &:focus svg path{
            fill: #D44C5A !important;
            }

            &:disabled{
            background-color: #eeeeee;
            border-color: #cccccc;

            svg path{
                fill: #cccccc !important;
            }
            }
        }
        .dados-bloquear-regiao{
            .col-button-remove{
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            .col-button-regiao{
                display: flex;
                justify-content: flex-end;
                padding-right: 0;
                .btn-regiao{
                    margin-top: 5px;
                    background-color: #ffffff;
                    border: 1px solid #26417B;
                    color: #26417B;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;

                    svg{
                        margin-right: 6px;
                        path{
                            fill:#26417B
                        }
                    }

                }
            }
        }
        .dados-produto{
            .col-modalidade{
                display: flex;
                margin-bottom: 9px;

                .label-modalidade{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    color: #686B6D;
                    margin-right: 5px;

                }
                .ant-radio-group{
                    .ant-radio-checked {
                        .ant-radio-inner {
                            border-color: #E5E6E7 !important;
                        }
                    }
                    .ant-radio-inner::after{
                        background-color: #1AB394;
                    }

                }

            }
            .col-new-info{
                padding: 0 !important;
                display: flex;
            }
            .col-button{
                display: flex;
                justify-content: right;

                .btn-produtos{
                    margin-top: 5px;
                    background-color: #ffffff;
                    border: 1px solid #26417B;
                    color: #26417B;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;

                    svg{
                        margin-right: 6px;
                        path{
                            fill:#26417B
                        }
                    }

                }

            }
            .col-button-remove{
                display: flex;
                justify-content: center;
                margin-top: 19px;
            }
        }
        .col-btn-cadastro{
            display: flex;
            justify-content: flex-end;
            margin-top: 25px;
            .btn-cadastro{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
            }

        }
        .dados-hierarquia{
            .comercial-title{
                margin-top: 9px;
                margin-bottom: 14px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #26417B;

            }

        }
        .table-responsive{
            white-space: nowrap;
            tr{
                text-align: center;
            }
        }
    }
}

.tipo-modalidade-error {
  color: #ff4d4f !important;
  font-size: 10px !important;
  margin-left: 15px !important;
  margin-top: -7px;
  margin-bottom: 15px;
}
