.wrapper-sistema-indisponivel {
  background-color: #293846 !important;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    margin: auto;
    align-items: center;
    width: fit-content !important;
    padding: 40px 44px !important;
    border: 0 !important;
    border: 1px solid #e7eaec;
    border-radius: 2px !important;

    .logo-crefazon {
      width: 230px;
      height: 31px;
    }
  }

  .card .msg {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    line-height: 38px;
    color: #686b6d;
    margin-top: 32px;
    margin-bottom: 15px;
  }

  .card .text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 19px;
    color: #686b6d;
    margin-bottom: 32px;
    text-align: center;
  }

  .card .text .link {
    color: #ff7817;
    text-decoration: none;
  }

  .card .text .link:hover {
    opacity: 0.8;
  }
}
