@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.filter-container {
  background-color: #f7f7f7;
  border: 0.5px solid #b8b9bc;
  margin-top: 1rem;
  padding: 1rem;
}

.pagination {
  justify-content: flex-end !important;
}

.justify-content-between {
  .title-line {
    margin-bottom: 15px;
  }
}
.justify-content-end {
  display: flex;

  a {
    color: #818485;
    .toolbar-button {
      width: 100%;
    }
  }
}
.filter-acompanhamento-proposta {
  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
}
  .ant-form-item-label {
    padding-bottom: 3px;
  }
  .ant-form {
    label {
      font-size: 13px;
    }
  }
  .ant-picker {
    input {
      border: none;
    }
    .ant-picker-range-separator {
      padding-left: 0px;

      svg {
        width: 22px;
        height: auto;
        position: relative;
        left: -5px;
        top: -2px;

        path {
          fill: #6d757d !important;
        }
      }
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-suffix {
      margin-right: 0px;
    }
  }
}

.wrapper-buttons-crefaz-mais-financeiro {
  .button-financeiro-selected,
  .button-crefaz-mais-selected {
    color: #fff;
    outline: none !important;

    &:hover {
      outline: none !important;
    }
  }

  .button-financeiro-unselected,
  .button-crefaz-mais-unselected {
    color: #26417b;
    border: 1px solid #26417b !important;

    &:hover {
      outline: none !important;
    }
    &:disabled{
      background-color: #FFFFFF !important;
      opacity: 1;
      &:hover {
        background-color: #FFFFFF !important;
        color: #26417b;
      }
    }
  }
}



.ant-select-selection-item path {
  fill: #ff7817;
}

.ant-select-item-option-content path {
  fill: #ff7817;
}

.search-icon,
.erase-icon {
  width: 15px;
}

.search-icon path {
  fill: #ffffff;
}

.erase-icon path {
  fill: #263659;
}

.phone-icon path {
  fill: #ff7817;
}

.question-icon {
  min-width: 20px;

  circle {
    fill: #ff7817;
  }
  path {
    fill: #fff;
  }
}

.writing-label {
  color: #6d757d;
}

.toolbar-button {
  color: #6d757d;

  svg {
    width: 15px;
  }

  path {
    fill: #263659;
  }

  svg {
    width: 15px;
  }
  border: 1px solid #263659;

  &:hover {
    background-color: #263659;
    color: #fff;
    border: 1px solid #2ea9fb;
  }

  &:hover path {
    fill: white;
  }
}

.filter-icon {
  margin-right: 10px;
}

.field-control {
  margin-bottom: 0;
}

.toolbar-button {
  padding: 7px;
  border: #263659 1px solid;
}

.footerToolbar {
  margin-left: 0px;
  margin-right: 5px;
  a {
    color: #818485;
    .toolbar-button {
      width: 100%;
    }
  }
}

.modal-reanalise {
  max-width: 543px !important;
  margin-top: 30px;
  h1 {
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 28px;
    color: #686b6d;
    text-align: center;
  }
  .icon {
    display: flex;
    justify-content: center;
  }
  label {
    color: #686b6d !important;
    margin-bottom: 5px;
  }

  .txta-motivo {
    resize: none;
    padding: 10px;
  }

  .label-historico {
    font-weight: 600;
  }

  .ant-upload-list,
  .ant-collapse-item {
    text-align: left;
  }

  .ant-collapse-content-inactive {
    display: none;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  .label-anexo {
    margin-top: 18px;
  }

  .ant-collapse-content-active {
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  .ant-form-item-explain-error {
    text-align: left;
  }

  .error-txta {
    background-color: #ffc2c2;
    border-color: #ed5555;
  }

  .collapse-historicos {
    text-align: left;
    label {
      margin-bottom: 12px;
      margin-top: 28px;
      font-size: 14px;
      float: none;
    }
    .ant-collapse-item {
      margin-bottom: 0 !important;
    }
    .ant-collapse {
      label {
        color: #686b6d;
        margin: 0;
        margin-top: 12px;
        margin-left: 10px;
        font-size: 14px;
        line-height: 19px;
      }
      .ant-collapse-header {
        color: #686b6d !important;
        font-size: 14px;
      }
      .txta-motivo {
        background: #f3f4f5;
        border: 1px solid #d9d9d9;
        margin-bottom: 18px;
      }
      .ant-collapse-content-box {
        background: #f7f7f7;
        border: 1px solid #dee2e6;
        padding: 10px;

        label {
          margin: 0;
          margin-bottom: 13.5px;
        }
      }
    }
  }

  .icone-arquivo {
    cursor: pointer;
    text-align: left !important;
    .ant-upload-list-item {
      .ant-upload-list-item-info {
        .ant-upload-span {
          .ant-upload-list-item-thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .ant-upload-list-item-actions {
        a {
          opacity: 1 !important;
        }
      }
    }
  }

  .btn-confirmar-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .btn-confirmar-reanalise {
    margin-top: 30px !important;
    padding: 12.7px 20px !important;
    font-size: 14px !important;
    font-family: 'Open Sans';
    font-weight: 600 !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    background: #26417b !important;
    border-radius: 3px !important;
    height: auto !important;
    max-width: 130px;
  }

  .ant-upload-list-item-info::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: ' ';
  }
  .proposta-credito-uploads {
    background-color: #fff;
    border: 2px dashed #d9d9d9;
    padding: 23px 23px;
    padding-right: 10px;
    .ant-upload-list-picture-card-container,
    .ant-upload.ant-upload-select-picture-card {
      width: 145px;
      height: 155px;
      margin: 0 20px 20px 0;
      &:hover {
        border-color: #455667;
      }
    }
    .ant-upload-list-picture-card-container {
      height: auto;
      p.data-upload-item {
        color: #495057;
        opacity: 0.8;
        text-align: center;
        width: 100%;
        line-height: 1em;
        font-size: 12px;
        font-style: italic;
        padding-right: 3px;
        margin-bottom: 8px;
      }
      .ant-upload-list-item {
        margin-bottom: 3px;
        height: 155px;
        background-color: #ffffff;
      }
      .ant-select {
        .ant-select-selector {
          background-color: #263659;
          border-radius: 2px;
          padding: 0 4px;
          font-size: 11.5px;
          text-align: center;
          color: #fff !important;
          height: 52px;
          position: relative;
          outline: none !important;
          box-shadow: none !important;
          .ant-select-selection-placeholder {
            height: auto;
            line-height: 0.6em;
            overflow: visible;
            position: absolute;
            color: #fff !important;
            opacity: 0.5;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .ant-select-selection-item {
            color: #fff !important;
            white-space: normal;
            height: auto;
            line-height: 1.35em;
            width: 100%;
            overflow: visible;
            position: absolute;
            color: #fff !important;
            opacity: 0.8;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
            &:after {
              content: '';
              display: none;
            }
          }
        }
        .ant-select-arrow {
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          margin-top: 0px;
          svg {
            width: 9px;
          }
        }
      }
    }
    .ant-upload {
      .ant-upload-list-item {
        padding: 0px;
      }
      .anticon-plus {
        margin-bottom: 20px;
        svg {
          width: 30px;
          height: 30px;
        }
        svg path {
          fill: #445566;
        }
      }
      .action-anexar {
        font-size: 14px;
        color: #686b6d;
        line-height: 1.1em;
        padding: 0 20px;
      }
    }
  }
}

@media (max-width: 1020px) {
  .justify-content-between {
    flex-direction: column;
    .justify-content-end {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      .m-2 {
        display: none;
      }
    }
  }
}

@media (max-width: 798px) {
  .justify-content-end {
    display: flex !important;
    flex-direction: column;
  }
  .field-control {
    margin-bottom: 10px;
  }

  .toolbar-topo-acompanhamento {
    margin: 0px;
    width: 100%;
    padding: 0 15px;
    margin-top: 15px;
    display: flex;

    .footerToolbar {
      width: 100%;
      display: inline-block;
      margin: 0px !important;
      line-height: 1em;

      span.m-1 {
        display: none;
      }

      svg {
        width: 12px;
      }

      a {
        display: inline-block;
        width: 100%;
        margin-bottom: 8px;

        button {
          margin: 0px !important;
        }
      }

      button {
        font-size: 11px;
        display: inline-block;
        width: 100%;
        margin-bottom: 8px;
      }
    }
    .btn-filtrar {
      width: 100%;
      font-size: 11px;
      display: inline-block;

      svg {
        width: 12px;
        margin-right: 4px !important;
      }
    }
    .justify-content-end {
      flex-direction: column;
    }
  }

  .pagination-container {
    width: 100%;
    display: inline-block;

    .pagination {
      width: 100%;
      display: inline-block;
      text-align: center;

      & > li {
        display: inline-block;
      }
    }
  }
}

.ant-tag-close-icon {
  vertical-align: middle;
}

.btn-situacao {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 5px;
  .icon {
    margin-right: 2px;
  }
}
.send-icon {
  path {
    fill: #6d757d;
  }
}

.tooltip-title {
  font: 13px/15px sans-serif;
  line-height: 18px;
}
.tooltip-list {
  font: 13px sans-serif;
  text-align: left;
  margin-top: 5px;
  padding-left: 15px;
}

.trash-icon {
  path {
    fill: #6d757d;
  }
}

.modal-preview-upload {
  ._loading_overlay_wrapper {
    overflow: hidden !important;
  }
}
