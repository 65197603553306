.insert-icon path {
  fill: #ffffff;
}

.ant-switch.switch-red-if-off:not(.ant-switch-checked) {
  background-color: #ed5565;
}

.indicadores-analise-proposta {
  .card-operatividade {
    padding-bottom: 6px;

    .card-operatividade-header {
      margin-bottom: 8px;
    }

    .card-operatividade-content {
      .nomeStatus {
        padding-top: 0px !important;
        padding-left: 4px !important;
        padding-bottom: 0px !important;
        margin-bottom: 10px !important;
      }

      .canalAtuacao {
        padding-left: 4px;
        clear: both;

        h5 {
          float: left;
          font-weight: 400;
          font-size: 13px;
          color: #686b6d;
          line-height: 1em;
          width: 40%;
          margin-top: 7px;
        }

        .select {
          float: right;
          width: 60%;

          p.error {
            color: #ed5565;
            font-size: 11px;
            line-height: 1em;
            margin: 0px;
            margin-top: 2px;
          }
        }

        .ant-select {
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important;

          &.ant-select-focused {
            .ant-select-selector {
              border-color: #70d1bd;
            }
          }

          &.error {
            .ant-select-selector {
              border-color: #ed5565 !important;
            }
          }

          .ant-select-selector {
            cursor: pointer;
            border: 1px solid #d9d9d9;
            padding: 0px 3px;
            padding-right: 20px;
            /*
                            height: 30px;
                            overflow-y: scroll;
                        */
            outline: none !important;
            box-shadow: none !important;
            border-radius: 0px;

            &:hover,
            &:focus {
              border-color: #70d1bd;
            }

            .ant-select-selection-overflow-item {
              height: 22px;
              min-height: auto;

              .ant-select-selection-item {
                height: auto;
                margin: 0px;
                padding: 2px 3px;
                background-color: transparent;
                border: 1px solid #e7eaec;
                line-height: 1em;
                margin: 1px 0;
                margin-right: 3px;
                border-radius: 0px;

                .ant-select-selection-item-content {
                  color: #5d6062;
                  font-size: 13px;
                  line-height: 1em;
                  height: auto;
                }
                .ant-select-selection-item-remove {
                  transition: 0.2s;
                  height: auto;

                  svg {
                    path {
                      fill: rgb(73, 80, 87) !important;
                    }
                  }

                  &:hover {
                    opacity: 0.8;
                    transition: 0.2s;
                  }
                }
              }

              .ant-select-selection-search {
                height: auto;
                margin: 0px;
                color: #5d6062;
                font-size: 13px;
                line-height: 1em;
                position: relative;
                top: -1px;
              }
            }
          }
          .ant-select-arrow {
            top: 48%;
            cursor: pointer;
            transform: translateY(-50%);
            right: 8px;
            width: auto;
            height: auto;
            padding: 1px;
            margin-top: 0px;
            line-height: 1em;

            svg {
              width: 5px;
            }
          }
        }
      }
    }
  }
}

.dropdown-canal-atuacao {
  padding: 0px;
  border-radius: 0px;
  margin-top: -3px;
  border: 1px solid #e5e6e7;

  .rc-virtual-list {
    .rc-virtual-list-holder {
      .ant-select-item {
        font-size: 12px;
        padding: 6px 8px;
        color: #676a6c;
        background-color: transparent !important;

        &:hover {
          background-color: #1bb394 !important;
          color: #fff;
        }
        &.ant-select-item-option-selected {
          font-weight: 700;
          color: #676a6c;
          background-color: transparent !important;
        }
        .ant-select-item-option-content {
        }
        .ant-select-item-option-state {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .select {
    min-width: 100%;
  }
}
