.step-icon-container {
  background-color: #e7eaec;
  padding: 10px;
}

.step-icon path {
  fill: #263659;
}

.checkbox-correspondente {
  margin-bottom: 25px;
}

.checkbox-correspondente label {
  display: inline-block;
  font-size: 15px;
  color: #686b6d;
  margin-right: 12px;
  margin-left: 0px;
}

.checkbox-correspondente .ant-radio-group .ant-radio-wrapper {
  transition: 0.2s;
}

.checkbox-correspondente
  .ant-radio-group
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  opacity: 1 !important;
}

.checkbox-correspondente .ant-radio-group .ant-radio-wrapper:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.checkbox-correspondente .ant-radio-group .ant-radio-wrapper span:nth-child(2) {
  padding-left: 5px;
}

.checkbox-correspondente .ant-radio-group .ant-radio-wrapper .ant-radio {
  position: relative;
  top: -1px;
}

.checkbox-correspondente
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio
  .ant-radio-inner {
  width: 16px;
  height: 16px;
  border-color: #e5e6e7;
  border-radius: 0;
}

.checkbox-correspondente
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked:after {
  border-radius: 0;
  border-color: #1ab394;
}

.checkbox-correspondente
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner {
  border-color: #1ab394;
}

.checkbox-correspondente
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner:after {
  background-color: #1ab394;
  border-radius: 0;
  width: 10px;
  height: 10px;
  top: 2px;
  left: 2px;
}

.ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  line-height: 0;
}

.ant-steps.steps-form-correspondente {
  width: 100%;
}

.ant-steps.steps-form-correspondente.steps-conveniada {
  width: 75%;
}

.ant-steps.steps-form-correspondente .ant-steps-item {
  width: 25%;
  position: relative;
  padding-bottom: 26px;
  padding-left: 0px;
}

.ant-steps.steps-form-correspondente.steps-conveniada .ant-steps-item {
  width: 33.3%;
}

.ant-steps.steps-form-correspondente .ant-steps-item:after {
  content: '';
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  position: absolute;
  bottom: 6px;
  height: 1px;
}

.ant-steps.steps-form-correspondente .ant-steps-item:last-child:after {
  display: none;
}

.ant-steps.steps-form-correspondente .ant-steps-item .ant-steps-item-icon {
  position: relative;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item:first-child
  .ant-steps-item-icon:before {
  content: '';
  display: inline-block;
  border-bottom: 1px solid #ffffff;
  width: 50%;
  position: absolute;
  bottom: -20px;
  left: 0px;
  height: 1px;
  z-index: 99;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item:last-child
  .ant-steps-item-icon:before {
  content: '';
  display: inline-block;
  border-bottom: 1px solid #c4c4c4;
  width: 50%;
  position: absolute;
  bottom: -20px;
  left: 0px;
  height: 1px;
  z-index: 99;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item
  .ant-steps-item-title:after {
  display: none;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item.ant-steps-item-process
  .ant-steps-item-icon {
  position: relative;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item.ant-steps-item-process
  .ant-steps-item-icon:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #ff7817;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item:nth-child(2)
  .ant-steps-item-icon:after {
  bottom: -29px;
}

.ant-steps.steps-form-correspondente .ant-steps-item .ant-steps-item-title {
  color: #686b6d;
  font-size: 14px;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item.ant-steps-item-process
  .ant-steps-item-title {
  color: #686b6d;
  font-weight: 600;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item
  .ant-steps-item-icon
  .step-icon
  path {
  fill: #c4c4c4 !important;
}

.ant-steps.steps-form-correspondente
  .ant-steps-item.ant-steps-item-process
  .ant-steps-item-icon
  .step-icon
  path {
  fill: #263659 !important;
}

.wizard-buttons {
  display: flex;
  justify-content: flex-end;
  button {
    border-radius: 0;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-left: 1rem;
  }
}

.wizard-buttons.wizard-buttons-correspondente {
  button {
    color: #fff;
    outline: none !important;
    border: none;
  }
}

.form-uploads-visitas {
  border: 1px solid #d9d9d9;
  padding: 20px 23px;
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    width: 120px;
    height: 140px;
    margin: 0 20px 20px 0;
  }
  .ant-upload {
    .ant-upload-list-item {
      padding: 0px;
    }
    .anticon-plus {
      margin-bottom: 20px;
      svg {
        width: 30px;
        height: 30px;
      }
      svg path {
        fill: #445566;
      }
    }
    .action-anexar {
      font-size: 14px;
      color: #686b6d;
      line-height: 1.1em;
      padding: 0 20px;
    }
  }
}

.modal-preview-upload {
  top: 20px;
  padding: 0px;
  z-index: 9999;
}

.ant-modal.modal-preview-upload {
  width: calc(80vw) !important;
  height: calc(90vh) !important;
  max-height: calc(90vh) !important;
}

.ant-modal.modal-preview-upload .ant-modal-content,
.ant-modal.modal-preview-upload .ant-modal-content .ant-modal-body > div,
.ant-modal.modal-preview-upload .ant-modal-content .ant-modal-body > div > div {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.ant-modal.modal-preview-upload .ant-modal-content .ant-modal-header {
  height: 4em;
}

.ant-modal.modal-preview-upload .ant-modal-content .ant-modal-body {
  height: calc(100% - 4em);
  padding: 15px;
  text-align: center;
  position: relative;
}

.ant-modal.modal-preview-upload object {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-modal.modal-preview-upload img {
  width: 100%;
}

.ant-modal.applicationpdf {
}

.ant-modal.applicationpdf object {
  min-height: 450px;
}

.navbar-default {
  z-index: 99;
}

@media (max-width: 1500px) {
  .ant-steps.steps-form-correspondente .ant-steps-item {
    min-width: 120px;
  }
}

@media (max-width: 1000px) {
  .ant-steps.steps-form-correspondente {
    width: 110% !important;
    margin: 0 -5%;
    .ant-steps-item {
      min-width: auto !important;
      text-align: center;
      width: 25% !important;
      padding-bottom: 17px;
      .ant-steps-item-container {
        display: inline;
        .ant-steps-item-icon {
          display: inline-block;
          margin: 0px;
          width: 100%;
          padding: 0 5px;
          .step-icon-container {
            height: 45px;
          }
        }
        .ant-steps-item-content {
          display: block;
          .ant-steps-item-title {
            font-size: 12px;
            text-align: center;
            padding: 0px;
          }
        }
      }
    }
    .ant-steps-item:after {
      left: 0px;
    }
    .ant-steps-item.ant-steps-item-process .ant-steps-item-icon:after {
      bottom: -49px;
    }
    .ant-steps-item:first-child .ant-steps-item-icon:before {
      bottom: -43px;
      border-width: 2px;
    }
    .ant-steps-item:last-child .ant-steps-item-icon:before {
      bottom: -43px;
    }
  }
}

@media (max-width: 480px) {
  .wizard-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      margin: 5px 0;
      justify-content: center;
    }
  }
}
