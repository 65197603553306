.table thead tr th {
  border: 1px solid transparent;
  border-bottom: 1px solid #e7eaec;
}

.table-responsive > .table-striped {
  border: 0;
}

.full-table tbody {
  border: 1px solid #e7eaec;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.table-filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.filter-input {
  width: 280px;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 576px) {
  .table-pagination {
    justify-content: center;
  }

  .table-filter-container {
    margin-top: 1rem;
  }

  .filter-input {
    width: 100%;
  }
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1ab394;
}

.ant-pagination-item-active {
  border-color: #1ab394;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #1ab394;
}

.ant-pagination-item-active a:not([href]):not([tabindex]) {
  background-color: #1ab394;
  color: #ffffff;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0;
  line-height: normal;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  line-height: 32px;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  line-height: 32px;
  left: -8px;
}

.ant-pagination-jump-next-custom-icon {
  line-height: 28px;
  left: -8px;
}


.columnheader:hover{
  cursor: pointer;
}


thead{
  cursor: pointer;
}
