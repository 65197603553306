.card-chart {
  background: #F7F7F7;
  border-radius: 3px;
  box-sizing: border-box;
  border: 0.5px solid #D9D9D9;
  padding: 10px;
  // text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .card-chart-subtitle {
    display: flex;
    align-items: center;
  }
}

.date {
  font-weight: 400;
  font-style: italic;
  line-height: 13px;
  font-size: 10px;
  color: #686B6D;
  margin-top: 5px;
}
