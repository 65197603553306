.modal-blocklist{
        
    .ant-modal-content {
        justify-self: center;
        margin: 18px;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Open Sans', sans-serif;
        width: 397px;
        left: 50%;
        top: 45%;
        transform: translateX(-50%);


        .ant-modal-close {
            outline: none;
        }
        h3 {
            font-size: 15px;
            color: #26417B;
            font-weight: 600;

            .line-title-blocklist {
                background-color: #FF7817;
                width: 20px;
                height: 1px;
                margin-top: 6px;
            }
            margin-bottom: 18.5px;
        }

        .ant-modal-footer {
            display: none;
        }
        .data-line {
            cursor: pointer;
            font-size: 13px;
            color: #686B6D;
            margin: 0 10px 5px 0px;

            .ant-checkbox-wrapper {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;


                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0;
                    .gray-line {
                        display: flex;

                    }

                    p {
                        margin: 0 0 0 5px;
                    }
                }
            }

            span.gray-line {
                background-color: #D9D9D9;
                flex: 1;
                height: 1px;
                margin-left: 15px;
            }

            p {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                font-size: 13px;
            }

            .data-info {
                min-width: 92px;
                justify-content: flex-start;
            }

            .checkbox-input {
                color: #686B6D;
                font:  13px sans-serif;
                position: relative;
                
                .ant-checkbox{
                    margin-right: 5px;
                }
                .ant-checkbox, .ant-checkbox-input, .ant-checkbox-inner {
                    width: 12px;
                    height: 12px;
                    border-radius: 0;
                    top: 0px;
                }
                .ant-checkbox:hover {
                    border-color: #1AB394;
                    border-radius: 0;
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #1AB394;
                    border-color: #1AB394;
                    border-radius: 0;
                    position: relative;
                }

                .ant-checkbox-inner::after {
                    content: ' ';
                    width: 5px;
                    height: 7px;
                    position: absolute;
                    display: table;
                    border: 1.3px solid #fff;
                    border-top: 0;
                    border-left: 0;
                    -webkit-transform: rotate(45deg) scale(1);
                    transform: rotate(45deg) scale(1);
                    opacity: 1;
                    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                    top: 0px;
                    left: 3px;
                }

                .ant-checkbox-checked::after {
                    border-color: #1AB394;
                }

                .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
                    border-color: #1AB394;
                    border-radius: 0;
                }
            }
            

            &.blocked{

                .checkbox-input{
                    cursor: not-allowed !important;
                }
                
                .ant-checkbox,
                .ant-checkbox-input{
                    cursor: not-allowed !important;
                }
                .ant-checkbox:hover {
                    border-color: #dddddd;
                }
                .ant-checkbox .ant-checkbox-inner,
                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #dddddd !important;
                    border-color: #dddddd;
                }
                .ant-checkbox-checked::after {
                    border-color: #dddddd;
                }
                .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
                    border-color: #dddddd;
                }
            }
        }

        .data-text {
            margin: 0;
            padding: 0;
            .title {
                font-size: 13px;
                margin: 15px 0 2px 1px;
                color: #686B6D;
            }
            .title-obs {
                font-size: 13px;
                margin: 23px 0 7.5px 1px;
                color: #686B6D;
            }
            .textarea-obs {
                width: 100%;
                resize: none;
                height: 70px;
                border-color: #D9D9D9;
                outline: none;
            }

            
        }
        .align-right {
            margin-top: 13px;
            display: flex;
            justify-content: flex-end;
            
            .p-1 {
                width: 20px;
            }
        }

        
        form.ant-form{

            .inputs-checkbox{
                margin-bottom: 22px;

                p.error-checkbox{
                    color: #ff4d4f;
                    font-size: 10px;
                    margin-top: 10px;
                }
            }

            .ant-form-item{
                margin-bottom: 17px;

                .ant-form-item-label{
                    line-height: 1.4em;
                    padding: 0px;
                    margin-bottom: 2px;

                    label{
                        line-height: 1em;
                        font-size: 13px;
                        color: #686B6D;
                    }
                }
                .ant-form-item-control{

                    input,
                    textarea{
                        border: 1px solid #D9D9D9;
                        height: auto;
                        border-radius: 0px;
                        outline: none !important;
                        box-shadow: none !important;
                        line-height: 1.1em;
                        padding: 8px 10px;
                        font-size: 13px;
                        color: #6D757D;

                        &::placeholder{
                            color: #BBBDBF;
                        }
                    }
                    textarea{
                        line-height: 1.3em;
                        resize: none;
                        height: 70px;
                    }

                    .ant-select{
                        border: none !important;
                        outline: none !important;

                        .ant-select-selector{
                            border: 1px solid #D9D9D9 !important;
                            outline: none !important;
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }

        .botoes-acao{
            margin-top: 10px;

            button{
                outline: none !important;
                padding: 10px 17px;
                width: auto;
                height: auto;
                line-height: 1em;

                &.btn-cancelar{
                    border: 1px solid #CFD2D4;
                }
                &.btn-salvar{

                    svg{
                        width: 14px;
                        margin-right: 10px;
                    }
                }
            }
        }
        
    }
}