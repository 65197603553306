.form-historico-edit{

  .react-tabs__content-holder{
    min-height: 320px;
  }

  .form-row{
    display: flex;

    label{
      margin-bottom: 7px;
      line-height: 1em;
      padding-bottom: 0px;
      color: #686B6D;
      font-size: 13px;
      margin-top: -1px;
    }

    .ant-form-item{
      margin-bottom: 13px;

      .ant-form-item-label{
        margin-bottom: 7px;
        line-height: 1em;
        padding-bottom: 0px;
        overflow: initial;

        label{
          margin: 0px;
        }
      }
      .ant-form-item-control-input-content{
        line-height: 1em;
      }
      .ant-form-item-control{

        .ant-picker{
          padding: 4px 8px;

          .ant-picker-suffix{
            padding-left: 0px;
            margin-left: 0px;
          }
        }
      }

      .ant-select{

        .ant-select-selector{
          padding-left: 10px;

          .ant-select-selection-item{
            padding-right: 6px;
          }
          .ant-select-arrow{

            right: 10px;
          }
        }
      }
    }
  }

  .add-telefones{
    margin: 0px;

    .form-row{
      margin: 0 -5px;
    }
  }

  .btnAddInputs{
    background-color: #26417B;
    color: white;
    width: auto;
    height: 32px;
    padding-left: 22px;
    padding-right: 22px;
    margin-top: 21px;
    text-transform: uppercase;
    outline: none !important;

    svg{
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus{
      color: #26417B;
      background-color: #fff;
      border-color: #26417B;
    }

    &:hover svg path,
    &:focus svg path{
      fill: #26417B !important;
    }
  }

  .btnRemoveInputs{
    background-color: #ED5565;
    border: 1px solid #D44C5A;
    color: white;
    width: auto;
    height: 31px;
    padding: 0 7px;
    border-radius: 0px;
    margin-top: 21px;
    text-transform: uppercase;
    outline: none !important;

    svg{
      width: 13px;
      margin-right: 6px;
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus{
      color: #D44C5A;
      background-color: #fff;
      border-color: #D44C5A;
    }

    &:hover svg path,
    &:focus svg path{
      fill: #D44C5A !important;
    }

    &:disabled{
      background-color: #eeeeee;
      border-color: #cccccc;

      svg path{
        fill: #cccccc !important;
      }
    }
  }

  svg {
    margin-left: 5px;
  }
  .react-tabs__content-holder {
    border-left: 1px solid #d9d9d9;
  }
  .react-tabs__tab--selected {
    border-right: 1px solid #FFFF;
    position: relative;
    right: -1px;
  }
  .react-tabs__tab {
    border-right: 1px solid #FFFF;
    line-height: 1.35em;
  }
}
// ----------------------------------------------------------------------------------------------------
.collapse-historicos{

  @media (max-width: 1300px){
    .table-responsive {
      width: 100%;
      overflow-x: scroll;
      .table-striped {
        white-space: nowrap;
      }
    }
  }

  .btn-orange>svg path {
    fill: #FFF;
  }

  .ant-input-suffix{
    color: #c8c8c8;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background-color: #fafafa;
  }

  .filter-input {
    background-color: #fafafa;
    padding-right: 8px;

    .ant-input{
      font-size: 12.5px;
    }
    .ant-input-suffix{
      span{

        svg{
          width: 12px !important;
          height: 12px !important;
        }
      }
    }
  }
  .ant-collapse {
    background-color: #ffffff;
    border: 0;
    border-bottom: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    line-height: 0.7em;
    font-size: 13.5px;

    position: relative;

    .ant-collapse-extra{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      float: none;
    }
  }

  .ant-collapse-content {
    border-top: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding: 5px 16px;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-bottom: 15px;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }

  .table thead tr th {
    font-size: 13px;
    color: #686b6d;
    text-align: center;
  }

  .table tbody tr td {
    font-size: 13px;
    color: #686b6d;
    text-align: center;
    cursor: default;
    padding: 5px 4px;
  }

  .badge-status {
    min-width: 75px;
    min-height: 19.66px;
    font-size: 10px;
    font-weight: 400;
    padding-top: 5px;
  }

  .ant-collapse-item{
    height: 35px;
  }
  .ant-collapse-item-active{
    height: auto;
  }
  .ant-collapse-header{
    height: 35px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
  .tabelaListagem tbody {
    border: 0;
  }
  .table-responsive {
    border: 1px solid #d9d9d9;
    border-top: 0;
    margin-bottom: 8px;
  }
  .table-pagination{
    .pagination{
      margin-bottom: 2px;

      li{

        a{
          font-size: 12px;
        }
      }
    }
  }
  .filter-input{
    height: 25px;
  }
  .ant-input:placeholder-shown {
    font-style: italic;
  }
}

.tooltip-historico{

  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 1px;

  .ant-tooltip-content{

    .ant-tooltip-arrow{
      left: 45px;
      width: 19px;
      height: 19px;
      top: -14px;

      .ant-tooltip-arrow-content{
        width: 12px;
        height: 12px;
      }
    }

    .ant-tooltip-inner{
      padding: 13px 15px;
      padding-right: 8px;

      .table-responsive{
        margin: 0px;
        max-height: 250px;
        padding-right: 6px;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar {
            width: 3px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb{
            background: #FF7817;
            border-radius: 5px;
        }

        .table{
          background-color: #fff;

          thead tr th {
            font-size: 13px;
            color: #686b6d;
            text-align: center;
          }

          tbody tr td {
            font-size: 13px;
            color: #686b6d;
            text-align: center;
            cursor: default;
            padding: 5px 4px;
          }
        }
      }
    }
  }
}

.ant-select-disabled.selectTipoTelefone{

  .ant-select-selector{
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: #fff !important;
    cursor: default !important;

    input{
      cursor: default !important;
    }
  }
}

.cadastroPessoaContainer{

  .content-tab-conveniadas{

    .form-row.item-row{
      padding-bottom: 5px;
      margin-bottom: 15px;
      position: relative;

      &:after{
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: inline-block;
        width: 18%;
        height: 0px;
        border-bottom: 1px solid #E7EAEC;
        margin-left: 5px;
      }

      &:last-child:after{
        display: none;
      }
    }
  }
}

.buttons-container {

    .config-icon-svg {
      height: 21.19px;
      width: 21.19px;
    }
      
}

.cadastroPessoaContainer{

  .toolbar-container{

    .btn{
      outline: none !important;
    }

    .btn-orange{
      svg path{
        fill: #fff !important;
      }
    }
  }
}