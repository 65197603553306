.ProdutoDrivers {
    width: 100%;
    .NomeProduto {
        margin-left: 5px;
        font-size: 18px;
        margin-top: 4px;
        width: 100%;
    }
    hr {
        margin: 0;
        background-color: #c4c4c4;
        width: 358px;
    }
    .item {
        font: 400 14px/19px;
        margin: 6px 0;
    }
    .item.resp {
        margin-bottom: 0;
    }
    .line {
        display: flex;
        align-items: center;
        width: 100%;
        height: fit-content;
        border: none;
        color: #686B6D;
        padding-left: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        .circle {
            width: 10px;
            height: 10px;
            background-color: #ED5565;
            border-radius: 50%;
            margin-right: 5px;
        }
        .crit {
            display: flex;
            .strong {
                margin-right: 3px;
            }
            margin-right: 10px;
        }
        .return {
            display: flex;
            .strong {
                margin-right: 3px;
            }
            margin-left: 10px;
        }
        .strong {
            font: bold 14px/19px sans-serif;
        }
        .response {
            margin: 0;
        }
        .bar {
            width: 1px;
            height: 18px;
            background-color: #585656;
        }
    }
}