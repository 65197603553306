.col-etapa-atual {
  .card-icon-title {
    margin-bottom: 24px;

    .tag-status {
      margin-right: 0px;
      color: #fff;
      background-color: #ccc;
      border-radius: 2.5px;
      font-size: 10px;
      padding: 4px 9px;
      font-weight: 400;
      line-height: 1em;
    }
  }

  .card-icon-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 2px;
    margin-left: -2px;

    .historico {
      .btn {
        border: 1px solid #cfd2d4;
        font-size: 13px;
        padding: 5px 10px;
        height: auto;
        outline: none;
        letter-spacing: 0.01em;

        .anticon {
          vertical-align: middle;
          margin-left: 5px;
          svg {
            width: 8px;
          }
        }
      }
    }

    .tempo-proposta {
      color: #7e919e;
      font-style: italic;
      font-size: 12px;
      margin-top: 6px;

      svg {
        position: relative;
        top: -1px;
      }
    }
  }
}

.ant-tooltip.tooltip-historico-mesa-credito {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      right: 7px;
      width: 15px;
      .ant-tooltip-arrow-content {
        width: 11px;
        height: 11px;
      }
    }

    .ant-tooltip-inner {
      .outsideBorder {
        padding: 10px 20px;
        padding-right: 12px;

        p {
          font-size: 13px;
          line-height: 1.55em;
          font-weight: 600;
          margin: 0px;
          margin-bottom: 10px;
        }

        .tabelaHistorico {
          background-color: transparent !important;

          .table-responsive {
            max-height: 350px;
            padding-right: 6px;
            margin-bottom: 5px;

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            &::-webkit-scrollbar {
              width: 3px;
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background: #ff7817;
              border-radius: 5px;
            }
          }
          .table {
            background-color: #fff;
            thead {
              tr {
                th {
                  color: #646b70;
                  font-size: 13px;
                  font-weight: 400;

                  span {
                    font-size: 11px;
                    color: #373b3e;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  color: #686b6d;
                  font-size: 13px;
                  font-weight: 400;
                  padding-top: 5px;
                  padding-bottom: 5px;

                  .tag-color {
                    font-weight: 400;
                    font-size: 10px;
                    letter-spacing: 0.04em;
                    line-height: 1em;
                    padding: 5px 12px;
                    width: 85%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mesa-credito-tabela {
  .ant-collapse-header {
      line-height: 19.5px !important;
      vertical-align: middle;
  }
  .filter-input {
    padding-top: 0 !important;
  }
  .ant-input {
    border: none !important;
    background: transparent !important;
    min-width: 100% !important;
    padding-left: 20px !important;
    min-height: 21px !important;
  }
}
