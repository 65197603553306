.col-status-operatividade{
    
    .card-icon-title{
        margin-bottom: 18px;

        .ant-switch{
            margin-top: 3px;
        }
    }

    .card-icon-content{

        .nome-pessoa{
            margin-left: 10px;
        }
    }
}