.blocklist {
  .col-list {
    .ibox-title {
      align-items: flex-end;

      .title-label {
        width: 35%;
      }

      .filtro-pesquisa {
        width: 65%;
        text-align: right;
        align-items: flex-start;
        display: flex;
        justify-content: flex-end;

        .filtro {
          display: inline-block;
          margin-right: 10px;
          padding-top: 2px;

          label {
            display: inline-block;
            font-size: 13px;
            color: #686b6d;
            margin-right: 6px;
            margin-bottom: 0px;
          }
          button.ant-switch {
            width: 26px;
            min-width: 26px;
            height: 11px;
            position: relative;

            .ant-switch-handle {
              top: -1px;
              left: 0px;
              width: 12px;
              height: 12px;
              background-color: #ffffff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
              border-radius: 50px;
            }

            &.ant-switch-checked .ant-switch-handle {
              right: 0px;
              left: auto;
            }
          }
        }
        .pesquisa {
          display: flex;
          button{
            align-items: center;
            display: flex;
            height: auto;
            padding: 7px 10px;
          }
         
          .pesquisa-input {
            box-shadow: none;
            border: 1px solid #d9d9d9 !important;
            padding: 3px 10px;
            padding-right: 25px;
            padding-top: 2px;
            border-radius: 0px;
            height: auto;
            width: 160px;
            overflow: hidden;

            input {
              color: #999;
              font-size: 12px;
              line-height: 1em;
              padding: 0px;
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              width: 100%;

              &::placeholder {
                font-style: italic;
                color: #bbbdbf;
              }
            }

            span.anticon-search {
              svg {
                width: 13px;
                path {
                  fill: #c4c4c4 !important;
                }
              }
            }
          }
        }
      }
    }

    .ibox-content {
      table.table {
        border: 1px solid #d9d9d9;
        overflow: hidden;

        thead {
          tr {
            th {
              color: #646b70;
              font-size: 13px;
              padding: 9px 5px;
              font-weight: 400;
              text-align: center;
              border: none;

              span {
                font-size: 9.5px;
                margin-left: 7px;
                position: relative;
                top: -1px;
              }

              &:nth-child(5),
              &:nth-child(6) {
                cursor: default;
              }
            }
          }
        }
        tbody {
          border: none;

          tr {
            td {
              text-align: center;
              color: #686b6d;
              font-size: 13px;
              padding: 5px;
              line-height: 1em;

              span.icon-observacoes {
                cursor: pointer;
                svg {
                  width: 15px;
                  height: 15px;

                  path {
                    fill: #d9d9d9;
                    transition: 0.2s;
                  }
                  &:hover {
                    path {
                      fill: #6d757d;
                      transition: 0.2s;
                    }
                  }
                }
              }

              button.btn-icon-editar {
                padding: 1px 2px;
                border: none !important;
                background-color: transparent;
                outline: none !important;
                box-shadow: none !important;
                height: auto;
                position: relative;
                top: -1px;

                svg {
                  width: 15px;
                  height: 15px;

                  path {
                    fill: #d9d9d9;
                    transition: 0.2s;
                  }
                  &:hover {
                    path {
                      fill: #6d757d;
                      transition: 0.2s;
                    }
                  }
                }
              }
              &:nth-child(5),
              &:nth-child(6) {
                padding-left: 4px;
                padding-right: 2px;
              }
            }
          }
        }
      }
    }
  }
}

.tooltip-list-blocklist {
  padding-top: 6px;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: #435361;
        width: 8px;
        height: 8px;
      }
    }
    .ant-tooltip-inner {
      background-color: #435361;
      padding: 7px 12px;
      border-radius: 2px;

      p {
        color: #ffffff;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.25em;
        margin: 0px;
      }
    }
  }
}
