.blocklist {
  @media (max-width: 1075px) {
    .ibox-title {
      display: block;
    }

    .title-label {
      padding-bottom: 10px;
    }

    .filtro-pesquisa {
      width: 100% !important;
    }
  }

  @media (max-width: 955px) {
    .filtro-pesquisa {
      flex-wrap: wrap;
    }
  }
}
