.modal-warning {
  .ant-modal-close-x {
    width: 30px;
    height: 27.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    margin-right: 6.35px;
    font-weight: 600;
    svg {
      path {
        fill: rgba(73, 80, 87, 0.4);
        width: 13px;
        height: 15.7px;
      }
    }
  }
  .ant-modal-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text-modal-posicoes {
      min-height: 89px;
      color: #686b6d;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 25px;
      margin: 10px 25px 11px 25px;
      text-align: left;
    }
  }
  .ant-modal-close:focus {
    outline: 0;
  }
  .ant-modal-close {
    svg {
      path {
        fill: rgba(73, 80, 87, 0.4);
      }
    }
  }
  .icon {
    padding-top: 35px;
  }
  .text-modal {
    min-height: 89px;
    color: #686b6d;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    margin: 10px 25px 11px 25px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .btn-area {
    margin-bottom: 25px;
    display: flex;
    .btn-confirm {
      border-radius: 3px;
      height: 40px;
      width: 100px;
      padding: 10px 15px;
      margin: 0 8px;
      color: #fff;
      background: #26417b;
      border: none;
      text-transform: uppercase;
    }
  }
}

.campanha-modal {
  margin-top: 17.5px;
  .imagemCampanha {
    width: 100%;
    height: 100%;
  }
  .ant-modal-body {
    padding: 0 !important;
    a {
      display: inline-block;
    }
  }
  .ant-modal-close:focus {
    outline: 0;
  } 
  .ant-modal-close {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #1B2A4E;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .ant-modal-close-x {
    color: #1B2A4E;
    font-weight: 700;
    font-size: 22px;  
    line-height: 22px;
    width: 13px;
    height: auto;
    font-family:'Open Sans';
  }
}

