.filter-parametro-produtos{
  display: grid;
  background: #F7F7F7;
  padding: 20px;
  border: 0.5px solid #B8B9BC;
  margin-bottom: 0px;
  margin-top: 5px;
  line-height: 1 !important;

  .row > .col > .row{
   margin: 0 -7px;
  }
  .row > .col > .row > div{
    padding: 0 7px;
  }

  .col-campos{
    padding-top: 10px;

    label{
      color: #6B6B6D;
      font-size: 13px;
      font-weight: 400 !important;
      margin-bottom: 7px;
      line-height: 1em !important;
    }
    .ant-form-item{
      margin-bottom: 15px;

      .ant-form-item-label{
        line-height: 1em !important;
        width: 100%;
        text-align: left;

       label{
          height: auto !important;
       } 
      } 

      .ant-select-selector{
        cursor: pointer;

        .ant-select-selection-overflow{

          .ant-select-selection-overflow-item{

            .ant-select-selection-item{
              padding: 4px 8px;
              border: 1px solid #E7EAEC;
              background-color: #E7EAEC;

              .ant-select-selection-item-content{
                font-size: 13px;
                color: #6D757D;
                line-height: 1em;
                margin-right: 7px;

              }
              .ant-select-selection-item-remove{
                position: relative;
                width: 9px;
                padding-right: 2px;
                transition: .2s;

                &:hover{
                  opacity: .7;
                  transition: .2s;
                }

                span{
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 9px;

                  svg{
                    width: 9px;

                    path{
                      fill: #495057 !important;
                      opacity: 0.8;
                    }
                  }
                } 
              }
            }
          }
        }
      }
    }
  }

  .btn-gray {
    color: #263659 !important;
  }

  .btn-blue {
    background-color: #26417B !important;
    font-size: 12px !important;
  }

  .buttons {

    button {
      height: 35px !important; 
      width: 132px !important;
      font-weight: 400;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      margin-bottom: 10px;

      &:last-child{
        margin-bottom: 0px;
      }
      
      svg{
        margin-right: 6px;
        height: 15px;
      }
      
    }

    margin-top: 0px;
  }
  
}




@media (max-width: 992px){

  .filter-parametro-produtos{

    .buttons{
      direction: rtl;
      button{
        direction: initial;
        margin: 0px;
        margin-left: 12px;
      }

      br{
        display: none;
      }
    }
  }

}