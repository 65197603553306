.RelatorioDrivers {
    .driver {
        width: 100%;
        .title {
            margin-top: 20px;
            margin-left: 5px;
            font-size: 24px;
            line-height: 32.68px;
            color: #383838;
        }
    }
}