.renda-text {
  flex: 1;
  margin-right: 5px;
  white-space: nowrap;
  color: #686b6d;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  svg {
    width: 13.5px;
    height: 13.5px;
    path {
      fill: #1b2a4e;
    }
  }
}

.titleMsg {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.5px;
  color: #263659;
}

.line {
  width: 10px;
  color: #263659;
  height: 2px;
  align-items: stretch;
}

.modalidade-contrato {
  color: #686b6d !important;
  font-size: 13px;
  line-height: 10px;

  .ant-radio-checked .ant-radio-inner {
    border-color: #fa6e0a;
  }

  .ant-radio-inner::after {
    background-color: #fa6e0a;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: -10px;
  }
}

.atalho-tela-impressao {
  button {
    padding: 8px;
  }
}

.proposta-credito {
  .col-form {
    height: 100%;
    position: relative;

    .overlay-loading {
      height: 100%;
      overflow-y: hidden;

      .ibox {
        height: 100%;
        position: relative;
        overflow-y: hidden;
        margin: 0px;

        .ibox-content {
          padding-right: 7px;
          padding-top: 10px;
          height: 100%;
          padding-bottom: 57px;
          position: relative;
          overflow-y: hidden;

          .ant-form-vertical {
            height: 100%;
            position: relative;
            overflow-y: hidden;
            padding-bottom: 10px;
          }
        }
      }
    }
    .select-dados-convenio {
      .ant-select-item-option-content {
        span {
          text-align: left !important;
        }
      }
    }

    .select-valor-parcela {
      font-weight: bold;
      min-width: 100px;

      .ant-select-selector {
        background-color: #e7eaec;
        border-radius: 2px !important;
        border: 0 !important;

        color: #26417b;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        line-height: 25px;
        height: 20px;
      }
    }

    .info-limite-parcela {
      font-size: 9px;
      margin-top: -10px;
    }

    .tag-btn {
      padding: 3px 20px;
      height: auto;
      font-size: 12px;
      margin-right: 5px;
      text-transform: none;

      border-radius: 2.5px;
      line-height: 1em;
      opacity: 1;
      margin-top: 5px;
    }

    .dados-luz-dia-form {
      .header-calculo-luz-dia {
        margin-bottom: 10px;
      }

      .lista-faturas {
        .item-fatura {
          border: 1px solid #d9d9d9;
          margin-bottom: 10px;
          padding: 8px 13px;

          .informacoes {
            position: relative;
            padding-top: 3px;
            padding-right: 0px;

            & > div {
              width: 20%;
              float: left;
            }

            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              background-color: #d9d9d9;
              height: 90%;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              right: 0px;
            }

            h5.titulo {
              font-size: 12.5px;
              color: #686b6d;
              font-weight: 600;
              margin: 0px;
            }
            p.info {
              color: #6d757d;
              font-size: 12.5px;
              font-weight: normal;
              margin: 0px;
            }
          }
          .acoes {
            .descontar-fatura {
              text-align: center;
              padding-right: 20px;

              h5 {
                margin: 0px;
                font-size: 13px;
                font-weight: 600;
                color: #26417b;
              }

              .ant-switch {
                display: inline-block;
                height: 12px;

                .ant-switch-handle {
                  width: 12px;
                  height: 12px;
                  top: 0px;
                  left: -1px;

                  &:before {
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
                  }
                }

                &.ant-switch-checked {
                  .ant-switch-handle {
                    right: 0px !important;
                    left: auto !important;
                  }
                }
              }
            }

            .anexar-comprovante {
              text-align: center;
              padding-left: 0px;

              .ant-upload {
                margin-top: 3px;
                width: auto;

                button.ant-btn {
                  background-color: #26417b;
                  color: #fff;
                  font-size: 13px;
                  text-transform: uppercase;
                  padding: 5px 14px;
                  width: 100%;
                  text-align: center;
                  display: inline-block;
                  border: 1px solid #1e3361;
                  border-radius: 0px;
                  box-shadow: none;

                  svg {
                    width: 11px;
                    margin-right: 10px;
                    display: inline-block;
                    position: relative;
                    top: -1px;

                    path {
                      fill: #fff !important;
                    }
                  }
                }
              }

              .ant-upload-list {
                .ant-upload-list-text-container {
                  .ant-upload-list-item {
                    .ant-upload-list-item-progress {
                      .ant-progress-bg {
                        background-color: #00aaf3;
                      }
                    }
                    .ant-upload-list-item-info {
                      background-color: transparent !important;

                      .ant-upload-span {
                        background-color: transparent !important;
                        cursor: pointer;

                        .ant-upload-text-icon {
                          svg {
                            width: 20px;
                            height: auto;
                            position: relative;
                            top: -1px;

                            path {
                              fill: #c4c4c4 !important;
                            }
                          }
                        }
                        .ant-upload-list-item-name {
                          color: #445566;
                          font-size: 13px;
                          text-transform: uppercase;
                          font-weight: 600;
                          text-align: left;
                          letter-spacing: 0.005em;
                        }
                        .ant-upload-list-item-card-actions {
                          position: relative;
                          top: -2px;
                          .ant-upload-list-item-card-actions-btn {
                            opacity: 1;

                            &:hover {
                              opacity: 0.8;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              p.info-pagamento-descontado {
                color: #fa6e0a;
                font-size: 10.5px;
                line-height: 1.3em;
                letter-spacing: 0.001em;
                font-weight: 600;
                text-align: center;
                margin: 10px 5px;
              }
            }
          }
        }
      }
    }

    .aviso-arquivos {
      margin-top: 17px;
      max-width: 50%;

      p {
        color: #ed5555;
        font-size: 11px;
        margin: 0px;
        text-align: right;
        line-height: 1.4em;
      }
    }

    .container-scroll-form {
      padding-right: 13px;
      padding-bottom: 20px;
      height: 100%;
      position: relative;
      overflow-x: hidden;

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar {
        width: 3px;
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #ff7817;
        border-radius: 5px;
      }
    }

    .ContainerColor {
      width: 12px;
      height: 12px;
      background-color: #ff7817 !important;
      position: relative;
      top: -1px;
      margin-left: 5px;
    }

    .form-row {
      display: flex;

      .form-row {
        margin: 0 -5px;
      }
    }

    .input-align-right {
      text-align: right;

      .ant-select-selection-placeholder {
        text-align: left;
      }

      input {
        text-align: right;
      }
    }

    .input-dinamicos {
      .form-row {
        margin: 0 -5px;
      }
    }

    .input-dinamicos-telefones {
      width: 75%;
    }

    label {
      margin-bottom: 7px;
      line-height: 1em;
      padding-bottom: 0px;
      color: #686b6d;
      font-size: 13px;
      margin-top: -1px;
    }

    .traco-agencia-digito {
      line-height: 1.2em;
      font-size: 20px;
    }

    .ant-form-item {
      margin-bottom: 15px;

      &.disabled-valor-solicitado {
        .ant-form-item-label {
          .ant-select-selector {
            color: #263659;
            cursor: initial;
          }
        }
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input {
              cursor: initial;
              background-color: transparent;
            }
          }
        }
      }

      .ant-form-item-control-input-content {
        line-height: 1em;
      }

      .ant-form-item-label {
        margin-bottom: 7px;
        line-height: 1em;
        padding-bottom: 0px;
        overflow: initial;
        label {
          margin: 0px;
          height: 10px !important;
          width: 100%;
        }
      }
    }

    .tag-color {
      display: inline-block;
      min-width: 75px;
      padding: 3px 20px;
      border-radius: 2.5px;
      font-size: 12px;
      line-height: 1em;
      opacity: 1;
      margin-top: 5px;
      margin-right: 0px;
    }

    .tooltip-info {
      position: relative;
      top: 0px;
      margin-left: 5px;
    }
    .titleMsg .tooltip-info {
      margin-top: 5px;
      top: 0px;
    }

    input:disabled,
    .ant-input-disabled,
    .ant-picker-disabled {
      background-color: #e7eaec;
    }

    .ant-select-disabled {
      background-color: #e7eaec;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #e7eaec;
    }

    .selectTipoTelefoneDisabled.ant-select-disabled .ant-select-selector {
      background-color: #e7eaec !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }

    .dados-fatura-energia {
      margin-top: 10px;

      h4 {
        font-size: 14px;
        line-height: 1.3em;
        color: #686b6d;
        margin-bottom: 1px;
        margin-left: 3px;
      }
      .box {
        border: 1px solid #d9d9d9;
        padding: 15px 13px;
        padding-bottom: 5px;
      }
    }

    .cp-auto {
      margin-top: 10px;

      h4 {
        font-size: 14px;
        line-height: 1.3em;
        color: #686b6d;
        margin-bottom: 1px;
        margin-left: 3px;
      }
      .box {
        border: 1px solid #d9d9d9;
        padding: 15px 13px;
        padding-bottom: 5px;
        & > div > div {
          margin-left: 10px;

          &:first-child {
            margin-left: 5px;
          }

          display: flex;
          gap: 19px;
          div {
            max-width: 160px;
          }
        }
      }
    }


    .btn-cadastrar {
      background-color: #1ab394;
      color: white;
      border-color: #1ab394;
      padding: 4px 25px;
      height: 40px;
      margin-right: auto;
      border-radius: 1px;
      outline: none !important;
      box-shadow: none !important;

      svg {
        margin-right: 7px;
        position: relative;
        top: -2px;
      }

      &.btn-flutuante {
        border-radius: 50%;
        width: 37px;
        height: 37px;
        padding: 4px 11.5px;
        position: absolute;
        z-index: 1;

        right: 16px;
        bottom: 30px;
        display: inline-block;

        border: 1px solid #16997f;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

        svg {
          margin: 0px;
        }
      }
    }
    .btn-cadastrar:hover,
    .btn-cadastrar:focus {
      color: #1ab394;
      border-color: #1ab394;
      background-color: #fff;

      span {
        color: #1ab394 !important;
        }

      svg path {
        fill: #1ab394 !important;
      }
    }

    .btnReenvio {
      background-color: white;
      color: #26417b;
      width: auto;
      height: 24px;
      padding: 0px 10px;
      margin: 5px 0px 0px 5px;
      text-transform: uppercase;
      border-color: #26417b;

      svg {
        width: 9px;
        margin-right: 6px;
        position: relative;
        top: -1px;
      }

      &:hover,
      &:focus {
        color: #fff;
        background-color: #26417b;
      }

      &:hover svg path,
      &:focus svg path {
        fill: #fff !important;
      }
    }

    .btnAddInputs {
      background-color: #26417b;
      color: white;
      width: auto;
      height: 32px;
      padding-left: 22px;
      padding-right: 22px;
      margin-top: 21px;
      text-transform: uppercase;
      outline: none !important;

      svg {
        width: 13px;
        margin-right: 6px;
        position: relative;
        top: -2px;
      }

      &:hover,
      &:focus {
        color: #26417b;
        background-color: #fff;
        border-color: #26417b;
      }

      &:hover svg path,
      &:focus svg path {
        fill: #26417b !important;
      }
    }

    .btnRemoveInputs {
      background-color: #dc3545;
      color: white;
      width: 100%;
      height: 32px;
      padding-left: 2px;
      padding-right: 2px;
      margin-top: 21px;
      text-transform: uppercase;
      outline: none !important;

      svg {
        width: 13px;
        margin-right: 6px;
        position: relative;
        top: -2px;
      }

      &:hover,
      &:focus {
        color: #dc3545;
        background-color: #fff;
        border-color: #dc3545;
      }

      &:hover svg path,
      &:focus svg path {
        fill: #dc3545 !important;
      }
    }
  }
  .col-central-comunicacao {
    padding-left: 0px;
    padding-right: 7px;

    .ibox-content {
      padding-left: 12px;
      padding-right: 17px;
    }
  }

  .simulador-btn {
    color: #fff;
    display: flex;
    margin-right: 16px;
    svg {
      align-self: center;
      margin-right: 8.75px;
      path {
        fill: #fff;
      }
    }
  }
  .btn-aprovar-proposta {
    color: #1ab394;
    border: 1px solid #1ab394;
  }
  .btn-aprovar-proposta:hover,
  .btn-aprovar-proposta:focus {
    color: #fff;
    border-color: #fff;
    background-color: #1ab394;
  }
  .btn-negar-proposta {
    color: #ed5565;
    border: 1px solid #ed5565;
    margin-left: 17px;
  }
  .btn-negar-proposta:hover,
  .btn-negar-proposta:focus {
    color: #fff;
    border-color: #fff;
    background-color: #ed5565;
  }
  .enviar-documento-button {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 16px; 
    margin-bottom: 21px;

    .btn-gray {

      svg path {
        transition: 0.15s;
        fill: #bbbdbf !important;
      }

      &:hover,
      &:focus {
        svg path {
          fill: #26417b !important;
        }
      }
    }

    .btn-enviar {
      margin-right: 3px;
    }
  }

  .wrapper-buttons-cadastrar {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 15px;

    .btn-cadastrar-outro-produto {
      padding: 4px 25px;
      height: 40px;
      margin-right: auto;
      
      background:#26417B;
      border: 1px solid#1E3361;
      color: white;

      border-radius: 1px;
      outline: none !important;
      box-shadow: none !important;

      &:hover {
      background:white;
      border-color: #26417b;
      }

      &:hover span {
        color: #26417B;
      }
      
      svg {
        margin-right: 7px;
        position: relative;
        top: -2px;
      }
    }

    .btn-cadastrar-outro-produto:hover svg path {
      fill: #26417B;
  }
    
  }
}

.ballon-tooltip-comments {
  display: none;
}

.ant-tooltip.tooltip-proposta-credito {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      right: 7px;
      width: 15px;
      .ant-tooltip-arrow-content {
        width: 11px;
        height: 11px;
      }
    }

    .ant-tooltip-inner {
      .outsideBorder {
        padding: 10px 20px;
        padding-right: 12px;

        p {
          font-size: 13px;
          line-height: 1.55em;
          font-weight: 600;
          margin: 0px;
          margin-bottom: 10px;
        }

        .tabelaHistorico {
          background-color: transparent !important;

          .table-responsive {
            max-height: 350px;
            padding-right: 6px;
            margin-bottom: 5px;

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
            &::-webkit-scrollbar {
              width: 3px;
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background: #ff7817;
              border-radius: 5px;
            }
          }
          .table {
            background-color: #fff;
            thead {
              tr {
                th {
                  color: #646b70;
                  font-size: 13px;
                  font-weight: 400;

                  span {
                    font-size: 11px;
                    color: #373b3e;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  color: #686b6d;
                  font-size: 13px;
                  font-weight: 400;
                  padding-top: 5px;
                  padding-bottom: 5px;

                  .tag-color {
                    font-weight: 400;
                    font-size: 10px;
                    letter-spacing: 0.04em;
                    line-height: 1em;
                    padding: 5px 12px;
                    width: 85%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.input-debito-concorrente {
  text-align: right;
}
.ant-picker {
  padding-left: 7px;

  .ant-picker-suffix {
    margin-right: 7px;
  }
}

.modal-aprovacao {
  text-align: center;

  .icon-aprovacao {
    color: #1ab394;
  }

  .icon-negacao {
    color: #ed5565;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    color: #686b6d;
    margin-top: 40px;
  }

  .modal-aprovacao-footer {
    margin-top: 40px;

    .btn {
      text-transform: none;
      font-size: 14px;
      padding: 10px 0px;
      width: 100px;
      height: 40px;
      line-height: 10px;
      margin: 0 8px;
      font-weight: 400;
      border-radius: 3px;
    }

    .btn-cancelar {
      background: #e7eaec;
      color: #445566;
    }

    .btn-aprovar {
      background: #26417b;
      color: #ffffff;
    }
  }
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #455667;
}

@media (max-width: 1440px) {
  .btn-aprovar-proposta {
    width: 189px;
    height: 35px;
    font-size: 14px;
    padding: 7px 6px;
  }
  .btn-negar-proposta {
    width: 176px;
    height: 35px;
    font-size: 14px;
    padding: 7px 9px;
  }
}

@media (max-width: 1305px) {
  .col-input-agencia {
    div:nth-child(2) {
      padding: 0px;
    }
    div:nth-child(3) {
      padding-left: 0px;
    }
  }

  .proposta-credito .col-form .input-dinamicos-telefones {
    width: 100%;
  }
  .proposta-credito .col-form .btnAddInputs {
    width: 100%;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 1125px) {
  .btn-aprovar-proposta {
    width: 189px !important;
    height: 35px !important;
  }
  .btn-negar-proposta {
    width: 176px;
    height: 35px;
  }
}

@media (max-width: 1024px) {
  .btn-aprovar-proposta {
    width: 189px !important;
    height: 35px !important;
    font-size: 14px;
    padding: 7px 6px;
  }
  .btn-negar-proposta {
    width: 176px !important;
    height: 35px !important;
    font-size: 14px;
    padding: 7px 9px;
  }
}

@media (max-width: 992px) {
  .btn-cadastrar.btn-flutuante {
    bottom: 30px !important;
    width: 42px !important;
    height: 42px !important;
    padding: 4px 8px !important;
    right: 0px !important;
    svg {
      width: 17px !important;
      height: 17px !important;
    }
  }

  .proposta-credito {
    .col-central-comunicacao {
      display: none;
    }

    .btnRemoveInputs {
      margin-bottom: 10px !important;
    }

    // .traco-agencia-digito {
    //   display: none;
    // }

    input.ant-input[name='bancario.digito'] {
      left: 5px;
    }
  }

  .ballon-tooltip-comments {
    display: inline-block;
    background-color: #26417b;
    width: 42px;
    height: 42px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    position: fixed;
    bottom: 100px;
    right: 34px;
    padding: 0px;
    text-align: center;

    svg {
      width: 18px;
      height: 18px;
      position: relative;
      top: 1px;
    }
    &:hover,
    &:focus {
      color: #26417b;
      border-color: transparent;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.35);
      outline: none !important;

      svg path {
        fill: #26417b !important;
      }
    }
  }
}

@media (max-width: 798px) {
  .ibox-title {
    display: flex;
    width: 100%;
  }

  .title-label {
    margin-bottom: 10px;
    margin-right: 3px;
  }

  .justify-content-end {
    display: inline-block !important;
  }
}

@media (max-width: 800px) {
  .d-flex.justify-content-end.align-items-center {
    display: flex !important;
    flex-direction: row;
  }
  .btn-cadastrar.btn-flutuante {
    bottom: 50px !important;
  }
}

@media (max-width: 786px) {
  .btn-cadastrar.btn-flutuante {
    bottom: 60px !important;
  }
}

@media (max-width: 780px) {
  .btn-negar-proposta {
    margin-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .btnRemoveInputs {
    margin-bottom: 5px !important;
  }
  .btn-cadastrar.btn-flutuante {
    bottom: 40px !important;
  }
}
@media (max-width: 767px) {
  .ant-btn.btnRemoveInputs {
    margin-top: 0 !important;
    margin-bottom: 30px !important;
  }
}

@media (max-width: 642px) {
  .ant-form-vertical {
    padding-bottom: 50px !important;
  }
}

@media (max-width: 596px) {
  .ibox-title {
    display: flex;
  }
  .btn-negar-proposta,
  .btn-aprovar-proposta {
    width: 190px !important;
  }
  .btn-cadastrar.btn-flutuante {
    position: fixed !important;
    margin-right: 17px !important;
    svg {
      width: 17px !important;
      height: 17px !important;
    }

    .form-row .pt-3 {
      border: #00aaf3 solid;
    }
  }
  .ballon-tooltip-comments {
    bottom: 100px !important;
  }
}

@media (max-width: 550px) {
  .ibox-title {
    display: flex;
    flex-direction: column;
  }
  .d-flex {
    flex-direction: column !important;
    flex: 1;
    width: 100%;
    justify-content: center !important;
  }
  .btn-aprovar-proposta {
    width: 100% !important;
    height: 35px !important;
  }
  .btn-negar-proposta {
    width: 100% !important;
    height: 35px !important;
    margin: 10px 0 0 0 !important;
  }
  .ant-form-vertical {
    padding-bottom: 100px !important;
  }
}

@media (max-width: 450px) {
  .btn-cadastrar.btn-flutuante {
    margin-bottom: 10px !important;
  }
}

.modal-proposta-credito-aguarde {
  padding: 50px 30px;
  p {
    color: #474747;
    font-size: 19px;
    margin-bottom: 30px;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .d-flex {
    flex-direction: column !important;
    flex: 1;
    width: 100%;
    justify-content: center !important;
  }
  .btn-negar-proposta {
    margin-top: 10px !important;
  }

  .ant-btn-primary.btn-cadastrar.btn-flutuante {
    width: 42px !important;
    height: 42px !important;
  }
  .ant-btn-primary.btn-cadastrar {
    width: 100%;
    height: 35px !important;
  }
}
