div .table > thead:first-child > tr:first-child > th > span.proposal-listing-clock-icon {
  margin-left: 0px !important;
}

.ibox-content {
  padding-left: 20px;
  padding-right: 20px;
}

.estagio-select {
  width: 104px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  background: #c4c4c4 !important;
}

.tabelaAnaliseList .ant-tag {
  margin: 0px;
}

.icon-title path {
  fill: #212529 !important;
}

.icon-table-text {
  margin-right: 5px !important;
  margin-bottom: 3.5px;
}

.icon-table-text path {
  fill: #686b6d !important;
}

.insert-icon {
  margin-right: 5px !important;
  margin-bottom: 3.5px;
}

.icon-table-text path {
  fill: #686b6d !important;
}

.icon-table-white {
  margin-right: 5px !important;
  margin-bottom: 3.5px;
}

.icon-table-white path {
  fill: white !important;
}

.writing-icon {
  margin-bottom: 3.5px;
}

.table > tbody > tr > td {
  padding-left: 4px;
  padding-right: 4px;
}

@media (max-width: 1024px) {
  .tabelaAnaliseList {
    display: block;
  }
}

@media (max-width: 768px) {
  .tabelaAnaliseList {
    table {
    }

    th {
      display: table-cell !important;
      white-space: nowrap;
    }
    td {
      position: initial !important;
      display: table-cell !important;
      right: auto;
      word-wrap: break-word;
      white-space: nowrap;
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }
}
