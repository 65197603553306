.credenciado-page-styles {

    .ibox-content{
        padding-left: 15px;
        padding-right: 31px;
        .body-content{
            .react-tabs__tab-list{
                width: 177px;
                span{
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #686B6D;
                }
                .react-tabs__tab {
                    span.tab-error {
                        color: #ED5565;
                        font: normal 700 14px/19px 'Open Sans', sans-serif !important;
                    }
                }
                .react-tabs__tab--selected{
                    border-radius: 5px 0px 0px 5px;
                    span{
                        font-weight: 600;
                        
                    }
                }
            }
        }
    }
}