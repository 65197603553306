.body-content {
  .react-tabs__tab-list {
    margin: 0;
    padding: 0;
    list-style: none;
    min-width: 112px;
    width: 112px;
    padding-top: 6px;
    border-right: 1px solid #d9d9d9;
  }

  .react-tabs__content-holder {
    width: 100%;
    background-color: #ffffff;
    padding: 15px;
    min-height: 550px;
    height: auto;

    .react-tabs__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .react-tabs__icon-title-group {
        display: flex;
        align-items: center;
      }
    }
  }

  .react-tabs__tab {
    position: relative;
    border-right: 1px solid transparent;
    padding: 10px 15px;
    padding-right: 5px;
    line-height: 1.2em;
    cursor: pointer;
    font-size: 13px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
  }

  .react-tabs__tab--selected {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-right: 1px solid transparent;
    border-radius: 0px;
    font-weight: 600;
    margin-right: -1px;
  }

  .question-icon {
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 15px;
    width: 15px;
    height: 15px;
    circle {
      fill: #2a8bff;
    }
    path {
      fill: #fff;
    }
  }
  .destaque-alterados {
    color: #2a8bff;
  }
}
