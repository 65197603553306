.toolbar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.form-container {
  background-color: #f7f7f7;
  border: 1px solid #d9d9d9;
  padding: 1rem;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .buttons-container {
    display: inline-block;
  }
}

.react-tabs {
  display: flex;
}

.react-tabs__tab-list {
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 150px;
}

.react-tabs__content-holder {
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  padding: 1rem 1.75rem;
}

.react-tabs__tab {
  border-right: 1px solid #d9d9d9;
  padding: 8px 16px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid transparent;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
