@media (max-width: 768px) {
  .header-actions {
    margin-top: 1rem;
  }
}

.icon-title path {
  fill: #212529;
}

@media (max-width: 500px) {
  .tabela-fila-credito {
    .table-custom-pagination {
      justify-content: center;
    }
    a.page-link {
      padding-left: 17px;
      padding-right: 17px;
    }
    li.page-item {
      display: none;
    }

    li.page-item:nth-child(-n + 2) {
      display: flex;
    }

    li.page-item:nth-last-child(-n + 2) {
      display: flex;
    }

    li.page-item.active {
      display: flex;
    }
  }
}
