.listagem-historico-gravacoes {
    .ibox-title {
        padding: 20px !important;
        padding-bottom: 0px !important;
        .btn-filtrar {
            outline: none !important;
            box-shadow: none !important;
            border: 1px solid #cfd2d4 !important;
            svg {
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }
        }
    }
    .ibox-content {
        padding-top: 10px;
        .box-filtros {
            background-color: #f7f7f7;
            border: 1px solid #b8b9bc;
            padding: 15px;
            margin-bottom: 20px;
            .col-inputs {
                .form-row {
                    margin: 0 -7px;
                    &>div {
                        padding: 0 7px;
                    }
                }
                .ant-form-item {
                    margin-bottom: 19px;
                    .ant-form-item-label {
                        margin: 0px;
                        padding: 0px;
                        margin-bottom: 6px;
                        line-height: 1em;
                        label {
                            line-height: 1em;
                            margin: 0px;
                            color: #686b6d;
                            font-size: 13px;
                        }
                    }
                    .ant-form-item-control {
                        outline: none !important;
                        border: none !important;
                        box-shadow: none !important;
                        .ant-form-item-control-input {
                            outline: none !important;
                            border: none !important;
                            box-shadow: none !important;
                            input {
                                border: 1px solid #d9d9d9;
                                padding: 6px 10px;
                                background-color: #ffffff;
                                border-radius: 0px;
                                font-size: 13px;
                                line-height: 1em;
                                min-height: 0px;
                                color: #6d757d;
                                outline: none !important;
                                box-shadow: none !important;
                                width: 100%;
                                &::placeholder {
                                    color: #bbbdbf;
                                }
                            }
                            .ant-picker {
                                border: 1px solid #d9d9d9 !important;
                                background-color: #ffffff;
                                border-radius: 0px;
                                padding: 3px 8px;
                                box-shadow: none !important;
                                input {
                                    border: none;
                                    padding: 3px 6px;
                                    &::placeholder {
                                        color: #6d757d;
                                    }
                                }
                                .ant-picker-range-separator {
                                    padding-left: 0px;
                                    svg {
                                        width: 22px;
                                        height: auto;
                                        position: relative;
                                        left: -3px;
                                        top: -2px;
                                        path {
                                            fill: #6d757d !important;
                                        }
                                    }
                                }
                                .ant-picker-active-bar {
                                    display: none;
                                }
                                .ant-picker-suffix {
                                    margin-right: 0px;
                                }
                            }
                        }
                        .ant-select {
                            border-radius: 0px;
                            border: none;
                            .ant-select-selector {
                                border: 1px solid #d9d9d9 !important;
                                background-color: #ffffff;
                                border-radius: 0px;
                                padding: 3px 8px;
                                box-shadow: none !important;
                                input {
                                    border: none;
                                    padding: 3px 6px;
                                }
                                .ant-select-selection-placeholder,
                                .ant-select-selection-item {
                                    line-height: 1em;
                                    padding: 6px 6px;
                                    color: #6d757d;
                                    font-size: 13px;
                                }
                            }
                        }
                        .ant-input-affix-wrapper {
                            border: 1px solid #d9d9d9 !important;
                            background-color: #ffffff;
                            border-radius: 0px;
                            padding: 3px 8px;
                            box-shadow: none !important;
                            input {
                                border: none;
                                padding: 3px 6px;
                            }
                            .ant-input-suffix {
                                svg {
                                    width: 12px;
                                    height: auto;
                                    path {
                                        fill: #c4c4c4 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .col-botoes {
                padding-top: 20px;
                padding-left: 5px;
                button {
                    width: 100%;
                    display: inline-block;
                    box-shadow: none !important;
                    outline: none !important;
                    text-align: left;
                    height: auto;
                    padding: 9px 15px;
                    padding-right: 5px;
                    line-height: 1.1em;
                    svg {
                        margin-right: 10px;
                        width: 15px;
                        height: auto;
                    }
                    &.btn-pesquisar {
                        margin-bottom: 15px;
                        border: 1px solid #1e3361;
                        background-color: #26417b;
                        &:hover {
                            background-color: #1e3361;
                        }
                    }
                    &.btn-limpar {
                        border: 1px solid #cfd2d4;
                    }
                }
            }
        }
        .table-responsive {
            margin-top: 5px;
            table.table {
                border: 1px solid #d9d9d9;
                thead {
                    border: none;
                    tr {
                        border: none;
                        th {
                            text-align: center;
                            border: none;
                            padding: 10px 7px;
                            color: #646b70;
                            font-size: 13px;
                            font-weight: 600;
                            white-space: nowrap;
                            span {
                                margin-left: 5px;
                                .fa {
                                    font-size: 10.5px;
                                    color: #373b3e;
                                }
                            }
                        }
                    }
                }
                tbody {
                    border: none;
                    tr {
                        border: none;
                        td {
                            &:nth-child(1) {
                                min-width: 120px;
                            }
                            &:nth-child(2) {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                text-transform: capitalize;
                                max-width: 110px;
                                padding-right: 5px !important;
                            }
                            &:last-child {
                                padding-left: 4px !important;
                            }
                            text-align: center;
                            border: none;
                            white-space: nowrap;
                            color: #686b6d;
                            font-size: 13px;
                            padding: 6px 7px;
                            .ant-tag {
                                font-size: 10px;
                                line-height: 1em;
                                padding: 3px 10px;
                                padding-bottom: 4px;
                                margin: 0px;
                                border-radius: 2.5px;
                                letter-spacing: 0.03em;
                                color: #ffffff;
                                &.tag-horario {
                                    color: #686b6d;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    border-color: #667788;
                                    svg {
                                        width: 10px;
                                        height: 10px;
                                        margin-right: 5px;
                                        path {
                                            fill: #686b6d !important;
                                        }
                                    }
                                }
                            }
                            .btn-download {
                                cursor: pointer;
                                transition: 0.2s;
                                width: 13px;
                                height: 15px;
                                path {
                                    fill: #c4c4c4 !important;
                                }
                                &:hover {
                                    opacity: 0.8;
                                    transition: 0.2s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .tooltip-chamada {
        cursor: pointer;
    }
}