.ibox-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pagination-follow-up {
  display: flex;
  align-items: center;
  gap: 16px;

  .button-pagination {
    background-color: #fff;
    border: 1px solid #ddd;
    float: left;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
    color: rgba(33, 37, 41, 0.6);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }

  button:hover {
    cursor: pointer;
    background-color: #e9ecef;
  }

  .button-current-page {
    background-color: #263659;
    color: #fff;
    border: 1px solid #ddd;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
    font-size: 13px;
    font-family: 'Helvetica Neue';
  }
}