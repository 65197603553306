.card-listagem-usuarios {
  h3 {
    color: #26417b;
    margin-top: 0 !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }
  button {
    margin-left: 16px;
  }

  .ibox-title {
    padding-bottom: 10.5px !important;
    .title-line {
      width: 50px;
    }
    .btn-adicionar-usuario,
    .btn-redefinir-senha {
      background-color: #26417b;
      border: 1px solid #1e3361;
      padding: 8px 16px;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
    }
    .btn-filtrar {
      background-color: #e7eaec;
      border: 1px solid #cfd2d4;
      svg {
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
    }
  }

  .ibox-content {
    padding-top: 0;
    .box-filtros {
      margin-top: 5px;
      margin-bottom: 10.5px;
      border: 1px solid #d9d9d9;
      padding: 20px 36px 0px 15px;

      .col-lg-3,
      .col-lg-4,
      .col-lg-5,
      .col-lg-6 {
        margin-bottom: -6px;
        padding-left: 15px;
        padding-right: 5px;
      }
      .col-lg-6 {
        margin-bottom: 15px;
      }

      .ant-form-item-label {
        padding-bottom: 5px;
        label {
          color: #686b6d;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
      }

      .col-buttons {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        button {
          margin-left: 8px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.btn-pesquisar {
            margin-bottom: 16px;
            color: #fff;
            background-color: #26417b;
            border: 1px solid #1e3361;
            text-align: left;

            svg {
              width: 12px;
              height: 12px;
              margin-right: 3px;
              margin-top: 0.5px;
            }
          }
          &.btn-limpar {
            background-color: transparent;
            color: #26417b;
            border: 1px solid #26417b;
            text-align: left;
            svg {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }
          }
        }
      }
    }

    .tabela-listagem-usuarios {
      padding-top: 5px;
      table {
        border: 1px solid #e7eaec;
        thead th {
          background-color: #fff;
          .ml-1 {
            margin-left: 5px !important;
          }
          &:first-child {
            border-left: 1px solid #e7eaec;
            min-width: 51px !important;
          }
          &:nth-child(2) {
            min-width: 142px !important;
          }
        }
        th:last-child {
          border-right: 1px solid #e7eaec;
        }

        tr:nth-child(even) {
          background: #fff;
        }
        tr:nth-child(odd) {
          background: #f7f7f7;
        }

        tr {
          text-align: center;
          th {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #3d3d3e;
            padding: 12.5px 0;
            span {
              margin-left: 4px !important;
              i {
                color: #373b3e;
              }
            }
          }
          td {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #3d3d3e;
            padding: 6.5px 10px;
            white-space: nowrap;

            .ant-checkbox-inner {
              height: 14px;
              width: 14px;
              border-radius: 0px !important;
              border: 1px solid #b8b8b9;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #1ab394;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              content: ' ';
              width: 5px;
              height: 7.5px;
              position: absolute;
              display: table;
              border: 1.3px solid #fff;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(45deg) scale(1);
              transform: rotate(45deg) scale(1);
              opacity: 1;
              -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
                0.1s;
              transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
              top: 0.5px;
              left: 3px;
              margin-left: 1px;
            }

            .form-button {
              .btn-icon-editar-list {
                margin: 0;
                height: 20px;
                width: 20px;
                position: relative;
                padding: 0;

                svg {
                  width: 15px;
                  height: 15px;
                  margin: 0;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  path {
                    fill: #d9d9d9;
                  }
                }
              }
            }
          }
        }
      }
      .pagination-footer {
        .per-page {
          display: flex;
          height: fit-content;
          align-items: center;
        }
        p {
          font: normal 400 14px/19px 'Open Sans', sans-serif;
          color: #3d3d3e;
        }
        .ant-select-selector {
          border: 1px solid #dbdbdc;
          height: 28px;
          padding: 0 10.5px !important;
          border-radius: 4px;
          span,
          input {
            height: 100% !important;
          }

          .ant-select-selection-item {
            color: #3d3d3e;
            font: normal 400 12px/12px 'Open Sans', sans-serif;
            display: flex;
            align-items: center;
          }
        }
        .ant-select-arrow {
          display: flex;
          align-items: center;
          svg {
            width: 11px !important;
            height: 7px !important;
          }
        }
      }
    }
  }
}
.redefinicao-senha-modal-usuarios,
.redefinicao-senha-modal-usuarios-no-email-users {
  max-width: 450px;
  max-height: 285px;
  border-radius: 0;
  .swal-icon--warning {
    border: none;
    margin: 35px auto 10px auto;
    height: 75px;
    background-image: url("data:image/svg+xml,%3Csvg width='76' height='75' viewBox='0 0 76 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 6.25C55.2312 6.25 69.25 20.2688 69.25 37.5C69.25 54.7312 55.2312 68.75 38 68.75C20.7688 68.75 6.75 54.7312 6.75 37.5C6.75 20.2688 20.7688 6.25 38 6.25ZM38 0C17.2906 0 0.5 16.7906 0.5 37.5C0.5 58.2094 17.2906 75 38 75C58.7094 75 75.5 58.2094 75.5 37.5C75.5 16.7906 58.7094 0 38 0ZM33.3125 18.75H42.6875L39.5625 43.75H36.4375L33.3125 18.75ZM38 57.0312C35.8438 57.0312 34.0938 55.2812 34.0938 53.125C34.0938 50.9688 35.8438 49.2188 38 49.2188C40.1562 49.2188 41.9062 50.9688 41.9062 53.125C41.9062 55.2812 40.1562 57.0312 38 57.0312Z' fill='%23FA6E0A'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    .swal-icon--warning__body {
      display: none;
    }
  }
  .swal-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal 400 19px/25px 'Open Sans', sans-serif;
    color: #686b6d;
    margin: auto;
    vertical-align: middle;
    max-width: 400px;
    min-height: 89px;
  }
  .swal-footer {
    padding: 0 0 25px 0;
    margin-top: 11px;
    .swal-button-container {
      margin: 0 8px;
      .swal-button {
        font: normal 400 14px/19px 'Open Sans', sans-serif;
        padding: 9px 31px;
        color: #fff;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        &.swal-button--cancel {
          border: 1px solid #cfd2d4;
          color: #263659;
          background-color: #e7eaec;
        }
        &.swal-button--confirm {
          border: 1px solid #26417b;
          background-color: #26417b;
          border-radius: 3px;
        }
      }
    }
  }
}
.redefinicao-senha-modal-usuarios-no-email-users {
  max-height: initial !important;
  .swal-text {
    color: #686b6d;
    font: normal 400 19px/25px 'Open Sans', sans-serif;
    text-align: left;
  }
}
