.btn-list {
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
  padding: 0.35rem;
  width: 100%;
  border-top: 0;
  border-radius: 2px;
}

.btn-list:enabled:hover {
  background-color: darken(#f7f7f7, 3%);
}
