.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-switch-checked {
  background-color: #1ab394;
}

.p-invalid {
  color: red;
}

.ant-input-affix-wrapper {
  pad: 80px;
}

.switchApenasAtivos {
  top: 0px;
}

@media (max-width: 582px) {
  .form-buttons-tarifa {
    padding-left: 14px;
  }
}
