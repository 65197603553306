#spinnerCircle {
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  from {
      transform: rotate(0deg)
  }

  to {
      transform: rotate(360deg)
  }
}
