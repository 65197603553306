.pre-analise-form {
  max-width: 840px;
  margin-bottom: 100px;
}

.modal-pre-analise {
  padding: 50px 30px;

  p {
    color: #474747;
    font-size: 19px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.modal-proposta-andamento {
  text-align: center;

  .icon-proposta-andamento {
    color: #1ab394;
  }

  .icon-negacao {
    color: #ed5565;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #686b6d;
    margin-top: 40px;
  }

  .modal-proposta-andamento-footer {
    margin-top: 40px;

    .btn {
      font-size: 14px;
      padding: 10px 0px;
      width: 110px;
      height: 40px;
      line-height: 10px;
      margin: 0 8px;
      font-weight: 400;
      border-radius: 3px;
      color: #fff;
    }

    .btn-prosseguir {
      background: #ff7817;
    }

    .btn-verificar {
      background: #26417b;
    }
  }
}

.row-convenio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;

  .ant-row {
    width: 100%;
    max-width: 385px;
  }


  .classificao {
    flex: 1;
    max-width: 100%;
  }

  label {
    margin-right: 14px;
  }
}

.plugins {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.label-form {
  & > div:first-child {
    label {
      color: #686B6D;
    }
    padding: 0;
  }
}
