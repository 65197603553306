.widgets-followup {

  .col,
  [class*='col-'] {
    padding-right: 25px;
    padding-left: 25px;
  }

  padding-left: 10px;
  display: flex;
  justify-content: left;
  gap: 5px;

  @media (min-width: 1424px) {
    gap: 10px;
  }

}

.card-relatorio {
  flex-basis: calc(25% - 10px);
  display: flex;
}

.expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px 8px 16px;
  background-color: #1E3361;
  color: white;

  svg {
    width: 15px;
    height: 15px;
  }

  cursor: pointer;

  @media (min-width: 2560px) {
    font-size: 32px;

    svg {
      width: 21px;
      height: 21px;
    }
  }
}

body#fullscreen {
  #page-wrapper {
    width: 100%;
  }
}

.analitico {
  .tabs-center {

    .ant-tabs-nav {
      width: 100%;
    }

    .ant-tabs-nav::before {
      border: none;
      width: 0;
    }
  }

  .tabs {
    .ant-tabs-tab {
      @media (min-width: 2560px) {
        font-size: 30px;
        padding:  14px 16px;
      }
    }
  }

  .tabs-fullscreen {
    width: calc(100% - 340px);

    @media (min-width: 2560px) {
      width: calc(100% - 600px);
    }
  }

  .title-card {
    .title-label {
      h3 {
        @media (min-width: 2560px) {
          font-size: 32px;
        }
      }
    }
  }

  .ibox-title {
    padding-bottom: 0 !important;
    padding-right: 15px !important;
  }

  .fullscreen-card {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    .ibox-content{
      padding-top: 0;
    }
  }
}

.fullscreen-pagination {

  width: min-content;
  display: flex;
  gap: 10px;
  padding: 10px;
  text-align: center;  
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px #00000033;
  box-shadow: 0px 3px 3px 0px #0000001f;
  box-shadow: 0px 3px 4px 0px #00000024;

  position: relative;
  top: -12px;
  left: 50%;
  margin-bottom: -40px;
  transform: translate(-50%, 0%);

  span {
    min-width: 28px;
    font-size: 18px;
    font-weight: 600;
    line-height: 19.5px;
    color: #26417b;

  }
}