
.tooltip-hierarquia {
  .ant-tooltip-content,
  .ant-tooltip-arrow {
    margin-bottom: -7px !important;
    margin-left: 1px !important;
  }
  .ant-tooltip-inner {
    min-height: 0px;
  }
}


.card-hierarquias {
  h3 {
    color: #26417b;
    margin-top: 0 !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }

  .ibox-title {
    padding-bottom: 0 !important;

    .title-line {
      width: 50px;
    }

    .menu-hierarquias {
      display: flex;
      align-items: center;

      .filtro {
        display: inline-block;
        margin-right: 10px;
        padding-top: 2px;

        .ant-switch {
          background-color: #ed5565;
        }
        .ant-switch-checked {
          background-color: #1ab394;
        }

        label {
          display: inline-block;
          font-size: 13px;
          color: #3d3d3e;
          margin-left: 5px;
          margin-bottom: 0px;
        }
        button.ant-switch {
          width: 26px;
          min-width: 26px;
          height: 11px;
          position: relative;

          .ant-switch-handle {
            top: 0px;
            left: 0px;
            width: 11px;
            height: 11px;
            background-color: #fff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
            border-radius: 50px;
          }

          &.ant-switch-checked .ant-switch-handle {
            right: 0px;
            left: auto;
          }
        }
      }

      button {
        margin-left: 15px;
        &.btn-hierarquia {
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #26417b;
          border: 1px solid #1e3361;
          border-radius: 0;
          color: #fff;
          text-align: left;

          svg {
            width: 10px;
            height: 10px;
            margin-right: 6px;
          }
        }
      }
      .filter-input {
        margin-left: 15px;
        background-color: #fff;
        padding: 0px 11px;
        height: 25px;
        border-radius: 0;

        .ant-input::placeholder {
          font-style: italic;
        }
        .ant-input {
          font-size: 12px;
          line-height: 13px;
        }
        .ant-input-suffix {
          span {
            svg {
              width: 12px !important;
              height: 12px !important;
              path {
                fill: #c4c4c4;
              }
            }
          }
        }
      }
    }
  }

  .ibox-content {
    padding-top: 5px;

    //Estilização do collapse
    .ant-collapse-header {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
      .ant-collapse-arrow {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
      }
      .ant-collapse-arrow ~ svg {
        margin-left: 6px;
        height: 18px;
        width: 18px;
        margin-bottom: 1px;
      }
      .ant-collapse-extra {
        width: fit-content;
        .info-headers {
          display: flex;

          div {
            margin-right: 18px;
            &:last-child {
              margin-right: 6px;
            }
            span {
              font-size: 14px;
              color: #646b70;
              font-weight: 600;
              margin-right: 6px;
            }
          }
        }
      }
    }

    //Estilização da árvore de hierarquias de dentro do collapse
    .ant-collapse-content-box {
      .hierarchical-tree {
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        .ant-collapse-content-box {
          background-color: #fff;
        }
        .no-tree {
          display: flex;
          margin-top: 15px;
          padding: 6px 0;
          .no-tree-text {
            color: #bbbdbf;
            text-transform: uppercase;
            font: 600 14px/19px "Open Sans", sans-serif;
            width: 250px;
            margin-left: 11.26px;
          }
        }
        .col-header {
          padding-left: 15px;
          div {
            display: flex !important;
            align-items: center;
            padding: 0 !important;
            svg {
              margin-left: 8px;
              margin-right: 6px;
            }
          }
          .sub-title-form {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #26417b;
          }
        }

        .tree-empty {
          svg {
            margin-left: 8px;
            margin-right: 6px;
          }
          .tree-empty-message {
            display: flex;
            align-items: center;
            color: #bbbdbf;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-top: 15px;
          }
        }

        .col-tree {
          margin-left: 8px;
          margin-top: 15px;
          .ant-collapse {
            background-color: transparent;
            border: none;

            &.no-children {
              .ant-collapse-header {
                padding-left: 8px !important;
              }
            }
            &.no-children-first-level {
              .ant-collapse-header {
                padding-left: 28px !important;
              }
            }

            .ant-collapse-item {
              border: none;
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              padding: 0;
              color: #686b6d;
              background-color: #fff;
              .ant-collapse-header {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: left;
                border-radius: 0;
                padding-top: 2.5px !important;
                padding-bottom: 2.5px !important;
                padding-left: 27px !important;
                width: fit-content;
                &:hover,
                &.selected {
                  background-color: #26417b;
                  color: #fff !important;
                  cursor: pointer;
                  ::placeholder {
                    color: #fff;
                  }

                  .info-tree {
                    .col-icons {
                      svg {
                        path {
                          fill: #fff !important;
                        }
                      }
                    }
                  }

                  .ant-collapse-arrow {
                    color: #fff !important;
                    cursor: pointer;
                  }
                }
                .ant-collapse-arrow {
                  padding-top: 3.5px;
                  position: absolute;
                  left: 7.5px;
                }
                .info-tree {
                  display: flex;
                  align-items: center;

                  .input-tree-posicao {
                    pointer-events: none;
                    caret-color: #fff;
                    padding: 1px 6px 1px 0;
                    transition: none;
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                    outline: none;
                    background-color: transparent;

                    &:hover {
                      cursor: pointer;
                    }
                    .editando-header,
                    &.editando {
                      pointer-events: all;
                    }
                  }
                  .col-icons {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    svg {
                      display: flex;
                      width: 15px;
                      height: 15px;
                      path {
                        fill: transparent !important;
                        fill-opacity: 1 !important;
                      }
                      &.icon-history {
                        margin-right: 12px;
                      }
                      &.icon-edit-opcao {
                        width: 13px;
                        height: 13px;
                        margin-left: 13.47px;
                        margin-right: 14.95px;
                      }
                      &.icon-cancel-opcao {
                      }
                      &.icon-add-opcao {
                        margin-left: 14.95px;
                        width: 11px;
                        height: 11px;
                      }
                    }
                  }
                  &.editing-info {
                    .ant-input {
                      pointer-events: all !important;
                    }
                  }
                }
              }

              .ant-collapse-content {
                border: none;
                .ant-collapse-content-box {
                  padding-top: 0 !important;
                  padding-bottom: 0 !important;
                  padding-left: 24px !important;
                }
              }
            }
          }
        }

        .col-button {
          .btn-posicao {
            display: flex;
            align-items: center;
            margin-top: 20px;
            margin-left: 34px;
            background-color: #ffffff;
            border: 1px solid #26417b;
            color: #26417b;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            padding: 2px 10px;
            height: auto !important;

            svg {
              margin-right: 6px;
              width: 8.75px;
              height: 8.75px;
              path {
                fill: #26417b;
              }
            }
          }
        }
      }
    }
  }
}
.modal-transferencia-dependencia {
  .ant-modal-close-x {
    height: fit-content;
    width: fit-content;
    svg {
      margin: 15.58px 11.58px;
    }
  }
  .ant-modal-body {
    padding: 10px 10px 15px !important;
  }
  .btn-acao {
    height: fit-content !important;
    padding: 0;
    vertical-align: top;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    font: normal 400 14px/19px "Open Sans", sans-serif;
    margin: 0;
    padding: 6.5px 16px;
    svg {
      margin: 3px 0;
      display: block;
      text-align: center;
      path {
        fill: #fff;
      }
    }
    background-color: #26417b;
    color: #fff;
    border-radius: 0;
    border: 1px solid #1e3361;
    &:hover,
    &:focus {
      color: #26417b;
      background-color: transparent;
      border-color: #26417b;

      svg path {
        fill: #26417b !important;
      }
    }
    &[variant="grey"] {
      background-color: #e7eaec;
      border: 1px solid #cfd2d4;
      color: #263659;
      svg {
        path {
          fill: #263659;
        }
      }
      &:hover,
      &:focus {
        color: #26417b;
        background-color: transparent;
        border-color: #26417b;

        svg path {
          fill: #26417b !important;
        }
      }
    }
    margin-left: 16px;
  }
  .modal-title {
    display: flex;
    align-items: center;
    color: #fa6e0a;
    font: normal 600 16px/22px "Open Sans", sans-serif;
    margin-bottom: 15px;
    .icon-warning {
      margin-right: 8px;
    }
  }
  .modal-grid {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    .icon-camp {
      margin: auto;
    }
    .options-camp {
      padding: 10px;
      border: 1px solid #c5d0d9;
      border-radius: 4px;
      .label-option-camp {
        color: #686b6d;
        font: normal 400 12px/16.34px "Open Sans", sans-serif;
      }
      .select-all-camp {
        cursor: pointer;
        font: normal 400 10px/13.62px "Open Sans", sans-serif;
        color: #3d3d3e;
        .styled-checkbox {
          margin-right: 5px;
        }
      }
      .posicao-component {
        border: 1px solid #c5d0d9;
        border-radius: 5px;
        padding: 1px 8px;
        display: flex;
        align-items: center;
        background-color: #f7fbff;
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0 !important;
        }
        &.parent-elem {
          cursor: pointer !important;
        }
        .posicao-dots {
          margin-right: 10px;
        }
        .arrow-back {
          cursor: pointer;
        }
        .posicao-label {
          width: 100%;
          font: normal 400 14px/19px "Open Sans", sans-serif;
          color: #686b6d;
        }
        &.clickable {
          background-color: #fff;
        }
        &.parent-selected {
          border: 1px solid #888;
        }
        &.sub-item {
          margin-left: 30px;
          .arrow-back {
            width: 15px;
          }
        }
      }
    }
  }
  .styled-checkbox {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 15px;
        height: 15px;
        border-color: #b8b8b9;
        border-radius: 0;
        &::after {
          width: 4px;
          height: 7.8px;
          border: 1.2px solid #fff;
          border-top: none;
          border-left: none;
          top: 45%;
          left: 28%;
        }
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #1ab394;
          border: 1px solid #b8b8b9;
        }
      }
    }
  }
  .group-btns {
    margin-top: 20px;
    .icon-btn {
      padding: 6.5px 9.5px;
    }
  }
}
.tooltip-styled-crefaz {
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: #17222d;
      }
    }
    .ant-tooltip-inner {
      font: normal 400 14px/19px "Open Sans", sans-serif;
      background-color: #435361;
      padding: 10px;
    }
  }
  &.ant-tooltip-placement-topRight {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        transform: translateX(-7px);
      }
    }
  }
  &.ant-tooltip-placement-topLeft {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        transform: translateX(7px);
      }
    }
  }
  &.tree-hierarquia-tooltip-option {
    .ant-tooltip-inner {
      padding: 8px 10px !important;
    }
  }
}

.users-info {
  width: 364px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  position: relative;
  padding: 10px;
  margin-bottom: 3rem;
  top: -25px;
  .input-position-details-card {
    margin-top: 5px;
    label {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #686b6d;
      margin-bottom: 4px;
    }
  }

  .header {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    margin: 0;

    h2 {
      font-family: "Open Sans";
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      vertical-align: top;
      line-height: 22px;
      margin: 0;
    }
  }
  button.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;
    height: fit-content;
    padding: 0px 2px 5px 5px;
    border: none;
    z-index: 10;
    background-color: transparent;
    vertical-align: top;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }
  .info-container {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px 0 5px;

    .ant-form-item {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        text-align: start;
      }
    }

    p {
      line-height: 19px;
      margin: 0;
      font-family: "Open Sans";
      font-style: normal;
      font-size: 14px;
      line-height: 19px;

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .buttons-container {
      padding: 15px 0px 10px 0;

      button {
        border-radius: 0;
        border: none;
        padding: 2px 10px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        svg {
          margin-right: 2px;
        }
      }
      button:focus {
        outline: 0;
      }
      .button-ver-mais {
        color: #ff7817;
        border: 1px solid #ff7817;
        margin-right: 16px;
        background-color: white;
        height: 23px;
      }
      .button-editar {
        color: #fff;
        border: 1px solid #26417b;
        background-color: #26417b;
        height: 23px;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
  .info-container-editing {
    @extend .info-container;
    padding: 12px 10px 0 10px;
    border-radius: 4px;
  }
  .input-position-container {
    .input-position-details-card {
      margin-top: 10px;
    }
    .ant-form-item {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        text-align: start;
      }
    }
  }
  .user-info-panel {
    background-color: #f7f7f7;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 13px;
    color: #445566;
  }
  .ant-collapse-content {
    background-color: #f7f7f7;
    padding: 0;
  }
  .ant-collapse-content-box {
    padding: 10px 5px;
    padding-bottom: 0;
  }

  .list-item {
    list-style: none;
    color: #686b6d;

    &::before {
      content: "\2022";
      color: #686b6d;
      margin-right: 5px;
    }
  }
}
.users-info-editing {
  @extend .users-info;
  top: 5px;
  margin-bottom: 150px;
}

.modal-mask {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.modal-warning-hierarquia,
.modal-warning-hierarquia-dependentes {
  .ant-modal-close-x {
    width: 30px;
    height: 27.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    margin-right: 6.35px;
    font-weight: 600;
    svg {
      path {
        fill: rgba(73, 80, 87, 0.4);
        width: 13px;
        height: 15.7px;
      }
    }
  }
  .ant-modal-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text-modal-posicoes {
      min-height: 89px;
      color: #686b6d;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 25px;
      margin: 10px 25px 11px 25px;
      text-align: left;
    }
    .bloco {
      padding-right: -13px;
      margin-left: -25px;
      margin-right: -70px;
      .spacing {
        width: 400px;
        max-height: 314px;
        font: 400 13px/17px "Open Sans", sans-serif;
        overflow: scroll;
        &::-webkit-scrollbar-track {
          scroll-padding: 100px;
          border: 13px solid #fff;
          background-color: #fff;
          width: 1px;
        }
        &::-webkit-scrollbar {
          width: 30px;
          scroll-padding: 100px;
          height: 30px;
          margin-left: 10px;
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          border: 13px solid #fff;
          scroll-padding: 100px;
          width: 10px;
          height: 10px;
          background: #c1c1c1;
          border-radius: 50px;
        }
      }
      .dependente-item {
        width: 100%;
        background-color: #f7f7f7;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 5px 6px;
        margin-bottom: 10px;
        color: #686b6d;
        .position {
          font-size: 14px;
        }
      }
      .dependente-sub-item {
        width: calc(100% - 30px) !important;
        margin-left: 30px;
        .dependente-item {
          background-color: #fff;
        }
      }
    }
  }
  .ant-modal-close:focus {
    outline: 0;
  }
  .ant-modal-close {
    svg {
      path {
        fill: rgba(73, 80, 87, 0.4);
      }
    }
  }
  .icon {
    padding-top: 35px;
  }
  .text-modal {
    min-height: 89px;
    color: #686b6d;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    margin: 10px 25px 11px 25px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .text-modal-dependencia {
    min-height: 89px;
    color: #686b6d;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    margin-top: 29px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    b {
      color: #fa6e0a;
    }
  }
  .btn-area {
    margin-bottom: 25px;
    display: flex;
    .modal-btn {
      border-radius: 3px;
      height: 40px;
      padding: 10px 15px;
      margin: 0 8px;
      color: #fff;
      background: #26417b;
      display: flex;
      border: none;
      align-items: center;
      text-transform: uppercase;
      &.btn-cancel {
        background-color: #f3f4f5;
        color: #686b6d;
        border: none;
      }
      &.btn-inativar {
        border: none;
        background-color: #ed5565;
        &:focus {
          outline-color: #ed5565 !important;
        }
      }
    }
  }
}
.modal-warning-hierarquia-dependentes {
  .ant-modal-content {
    .text-modal {
      height: auto;
      min-height: auto;
    }
    padding: 25px;
    .btn-area {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
      .modal-btn {
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
.modal-data-inativacao {
  .ant-modal-content {
    border: 1px solid #d9d9d9;
    .ant-modal-body {
      padding: 15px !important;
      label {
        width: 100%;
        color: #686b6d;
        font: 400 14px/19px "Open Sans", sans-serif;
        margin-bottom: 25px;
        .ant-picker {
          margin-top: 2.5px;
        }
      }

      .btn-area {
        display: flex;
        justify-content: flex-end;
        .modal-btn {
          border-radius: 0px;
          height: 40px;
          padding: 10px 15px;
          color: #fff;
          background: #26417b;
          display: flex;
          border: none;
          align-items: center;
          text-transform: uppercase;
          border: 1px solid #1e3361;
          &:first-child {
            margin-right: 16px;
          }
          &.btn-cancel {
            background-color: #e7eaec;
            border-color: #cfd2d4;
            color: #263659;
          }
        }
      }
    }
    .ant-modal-close {
      outline: none;
      .ant-modal-close-x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 39px;
        svg {
          path {
            fill: #b6b9bc;
          }
        }
      }
    }
  }
  .title {
    font: normal 600 14px/13px "Open Sans", sans-serif;
    color: #26417b;
    margin-bottom: 6px;
  }
  .line {
    border-color: #ff7817;
    width: 20px;
    border: none;
    background-color: #ff7817;
    margin-bottom: 15px;
  }
}
.discard-modal-hierarquia {
  max-width: 452px;
  border-radius: 0;
  border: 1px solid #d9d9d9;
  .swal-icon--warning {
    border: none;
    margin: 35px auto 10px auto;
    height: 75px;
    background-image: url("data:image/svg+xml,%3Csvg width='76' height='75' viewBox='0 0 76 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 6.25C55.2312 6.25 69.25 20.2688 69.25 37.5C69.25 54.7312 55.2312 68.75 38 68.75C20.7688 68.75 6.75 54.7312 6.75 37.5C6.75 20.2688 20.7688 6.25 38 6.25ZM38 0C17.2906 0 0.5 16.7906 0.5 37.5C0.5 58.2094 17.2906 75 38 75C58.7094 75 75.5 58.2094 75.5 37.5C75.5 16.7906 58.7094 0 38 0ZM33.3125 18.75H42.6875L39.5625 43.75H36.4375L33.3125 18.75ZM38 57.0312C35.8438 57.0312 34.0938 55.2812 34.0938 53.125C34.0938 50.9688 35.8438 49.2188 38 49.2188C40.1562 49.2188 41.9062 50.9688 41.9062 53.125C41.9062 55.2812 40.1562 57.0312 38 57.0312Z' fill='%23FA6E0A'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    .swal-icon--warning__body {
      display: none;
    }
  }
  .swal-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal 400 19px/25px "Open Sans", sans-serif;
    color: #686b6d;
    margin: auto;
    vertical-align: middle;
    max-width: 400px;
    min-height: 89px;
  }
  .swal-footer {
    padding: 0 0 25px 0;
    margin-top: 11px;
    .swal-button-container {
      margin: 0 8px;
      .swal-button {
        font: normal 400 14px/19.5px "Open Sans", sans-serif;
        padding: 10px 15px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        &.swal-button--cancel {
          background-color: #ed5565;
        }
        &.swal-button--confirm {
          background-color: #26417b;
          border-radius: 3px;
        }
      }
    }
  }
}
.modal-hierarquia,
.modal-hierarquia-inativacao,
.modal-hierarquia-inativacao-confirmacao {
  max-width: 450px;
  max-height: 285px;
  border-radius: 0;
  .swal-icon--warning {
    border: none;
    margin: 35px auto 10px auto;
    height: 75px;
    background-image: url("data:image/svg+xml,%3Csvg width='76' height='75' viewBox='0 0 76 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 6.25C55.2312 6.25 69.25 20.2688 69.25 37.5C69.25 54.7312 55.2312 68.75 38 68.75C20.7688 68.75 6.75 54.7312 6.75 37.5C6.75 20.2688 20.7688 6.25 38 6.25ZM38 0C17.2906 0 0.5 16.7906 0.5 37.5C0.5 58.2094 17.2906 75 38 75C58.7094 75 75.5 58.2094 75.5 37.5C75.5 16.7906 58.7094 0 38 0ZM33.3125 18.75H42.6875L39.5625 43.75H36.4375L33.3125 18.75ZM38 57.0312C35.8438 57.0312 34.0938 55.2812 34.0938 53.125C34.0938 50.9688 35.8438 49.2188 38 49.2188C40.1562 49.2188 41.9062 50.9688 41.9062 53.125C41.9062 55.2812 40.1562 57.0312 38 57.0312Z' fill='%23FA6E0A'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    .swal-icon--warning__body {
      display: none;
    }
  }
  .swal-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal 400 19px/25px "Open Sans", sans-serif;
    color: #686b6d;
    margin: auto;
    vertical-align: middle;
    max-width: 400px;
    min-height: 89px;
  }
  .swal-footer {
    padding: 0 0 25px 0;
    margin-top: 11px;
    .swal-button-container {
      margin: 0 8px;
      .swal-button {
        font: normal 400 14px/19.5px "Open Sans", sans-serif;
        padding: 10px 15px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        &.swal-button--cancel {
          background-color: #ed5565;
        }
        &.swal-button--confirm {
          background-color: #26417b;
          border-radius: 3px;
        }
      }
    }
  }
}
.modal-hierarquia-inativacao-confirmacao {
  .swal-footer {
    .swal-button-container {
      .swal-button {
        &.swal-button--cancel {
          background-color: #f3f4f5;
          color: #686b6d;
        }
        &.swal-button--confirm {
          background-color: #ed5565;
        }
      }
    }
  }
}
.collapse-hierarquia {
  .ant-collapse-content-box {
    position: relative;
    padding-top: 58px;
    border: 0;
    .cabecalho-dinamico-hierarquia {
      background-color: #fafbff;
      border: 1px solid #26417b;
      margin-left: -1px;
      margin-right: -1px;
      margin-top: -1px;
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      .nivel-hierarquico {
        .ant-input {
          transition: none;
        }
        &:nth-child(2) {
          margin-left: 4px;
        }
        position: relative;
        display: flex;
        align-items: center;
        margin: auto 0;
        min-width: 143px;
        max-width: 143px;
        margin-right: 6px;
        padding: 10px 3px 6px 6px;
        border-radius: 4px;
        .text-input {
          background-color: transparent;
          flex: 1;
          border: none !important;
          padding: 0 8.5px 0 4px;
          max-width: 100%;
          font: 600 12px/16px "Open Sans", sans-serif !important;
          &:focus {
            box-shadow: none;
          }
          &::placeholder {
            font: 600 12px/16px "Open Sans", sans-serif !important;
            color: #445566;
          }
        }
        .line-nivel {
          border-radius: 10px;
          height: 2px;
          width: 143px;
          z-index: 1;
          position: absolute;
          top: 4px;
        }
        .number {
          text-align: center;
          border-radius: 50%;
          font: normal 600 10px/15px "Open Sans", sans-serif;
          width: 15px;
          height: 15px;
          color: #fff;
        }
        &.item-on-focus {
          .line-nivel {
            background-color: #fa6e0a !important;
          }

          .number {
            background-color: #fa6e0a !important;
          }
        }
        .cancel-btn,
        .edit-btn {
          background-color: transparent;
          height: 0;
          padding: 0;
          border: 0;
          box-shadow: 0;
          outline: none !important;
        }
        .edit-btn {
          position: relative;
          width: 10px;
          height: 10px;
          svg {
            position: absolute;
            margin-left: auto;
            cursor: pointer;
            width: 9.5px;
            height: 9.5px;
            right: 0;
            top: 0;
            path {
              fill: #26417b;
            }
          }
        }
      }
      .adding-nivel,
      .editing-nivel {
        position: relative;
        background-color: #26417b;
        display: flex;
        margin: auto 6px;
        margin-right: 6px;
        font: normal 600 12px/16px "Open Sans", sans-serif;
        padding: 10px 4.5px 6px 3px;
        border-radius: 4px;
        max-width: 174px;
        min-width: 174px;
        &:nth-child(2) {
          margin-left: 10px;
        }
        .line-nivel {
          background-color: #fff !important;
        }
        &::before {
          content: "";
          background-color: #fff !important;
          border-radius: 10px;
          height: 2px;
          width: 133px;
          z-index: 1;
          position: absolute;
          top: 4px;
          left: 3px;
        }
        .number {
          text-align: center;
          background-color: #fff !important;
          border-radius: 50%;
          font: normal 600 10px/15px "Open Sans", sans-serif;
          width: 15px;
          height: 15px;
          color: #26417b;
        }
        .ant-input {
          background-color: #26417b;
          border: none;
          height: 16px;
          color: #fff;
          padding: 0 8.5px 0 4px;
          font: normal 600 12px/16px "Open Sans", sans-serif;
          max-width: auto;
          flex: 1;
          &:focus {
            box-shadow: none !important;
          }

          &::placeholder {
            font: normal 600 12px/16px "Open Sans", sans-serif;
            color: #fff;
          }
        }
        svg {
          position: absolute;
          margin-left: auto;
          cursor: pointer;
          width: 9.5px;
          right: 0;
        }
        .cancel-btn {
          background-color: transparent;
          height: 0;
          margin-bottom: auto;
          padding: 0;
          border: 0;
          box-shadow: 0;
          outline: none !important;
        }
      }
      .cabecalho-dinamico-hierarquia-select {
        font: normal 600 14px/19px "Open Sans", sans-serif;
        background-color: #26417b;
        width: fit-content !important;

        .ant-select-selector {
          width: fit-content !important;
          height: 43px;
          padding: 5px 13px;
          background-color: #26417b;
          border-color: #26417b;
          border-radius: 0;
          color: #fff;
          margin: -1px;
        }
        .ant-select-arrow {
          svg {
            position: relative;
            top: -2px;
            left: -1px;
            path {
              fill: #fff;
            }
          }
        }
      }
      .add-btn {
        background-color: #fff;
        margin: auto 14px;
        white-space: nowrap;
        color: #26417b;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        border: 1px solid #26417b;
        .add-text {
          cursor: pointer;
          margin: auto 6px;
        }
        .ant-select-selector {
          padding: 0;
          height: 22px;
          border-radius: 0;
          border-left: 1px solid #26417b !important;
          border: none;
          padding: 0 8px;
        }
        .ant-select {
          .ant-select-arrow {
            svg {
              position: relative;
              top: 3px;
              left: 3px;
            }
          }
        }

        &.add-perfil-btn {
          position: relative;
          .plus-perfil {
            vertical-align: revert;
            margin: 0 6px;
          }
          .ant-select-selector {
            padding: 0;
            padding-left: 28px;
            border-left: none !important;
          }
          .visible-select {
            .ant-select-selection-item {
              width: 40px;
              overflow: visible;
              line-height: 22px;
              position: relative;
              left: -33px;
            }
          }

          .hidden-select {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
          }
        }
        span {
          display: flex;
        }
      }
      .perfil-tag-area {
        display: flex;
        margin-left: 7px;
        margin-right: -7px;
        .perfil-tag {
          display: flex;
          color: #686b6d;
          height: fit-content;
          margin: auto 3px;
          padding: 2px 5px;
          border-radius: 5px;
          white-space: nowrap;
          font: normal 400 14px/19px "Open Sans", sans-serif;
          background-color: #e7eaec;
          .x-btn {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .editing-label, .visualizing-label {
      display: none;
      position: absolute;
      font: 600 normal 14px/19px "Open Sans", sans-serif;
      top: 10px;
      right: 15px;
      color: #fa6e0a;
      svg {
        margin-right: 7.68px;
        path {
          fill: #fa6e0a;
        }
      }
    }
  }
  .ant-collapse-item {
    &.panel-hierarquia {
      margin: -1px;
      & > .ant-collapse-content {
        border: 1px solid #d9d9d9 !important;
        .cabecalho-dinamico-hierarquia {
          .cabecalho-dinamico-hierarquia-select {
            .ant-select-selector {
              width: fit-content;
            }
          }
        }

        .editing-label {
          &.show-label {
            display: block;
          }
        }

        .visualizing-label {
          &.show-label {
            display: block;
          }
        }
      }

      &.editing-pos, &.visualizing-pos {
        .ant-input {
          pointer-events: all;
        }
        & > .ant-collapse-content {
          border: 1px solid #fa6e0a !important;
          .cabecalho-dinamico-hierarquia {
            border: 1px solid #fa6e0a !important;
          }
        }
      }
    }
  }
}
.hierarquias-accordion-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  min-height: 135px;
  position: relative;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;

  .ant-form-horizontal {
    position: relative;
    top: 25px;
    height: fit-content !important;
  }
}

.action-buttons-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 60px;
  align-items: flex-end;

  position: absolute;
  right: 15px;

  button {
    border-radius: 100%;
    box-shadow: -2px 2px 4px #a09d9d;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    transition: filter 0.2s;

    &.close {
      border: 1px solid #cfd2d4;
      background-color: #e7eaec;
    }
    &.save {
      background-color: #1ab394;
      border: 1px solid #16997f;
      margin: 0px;
      &:disabled {
        cursor: not-allowed;
      }
    }
    &:focus {
      outline: 0;
    }
    &:hover {
      filter: brightness(0.8);
    }
  }
}

.states-list {
  padding: 0 15px;
  margin: 0;
  margin-top: -45px;
}
.ant-collapse-header {
  display: flex;
  .hierarquia-collapse {
    display: flex;
    .hierarquia-name {
      font: 400 normal 13px/19.5px "Open Sans", sans-serif;
      color: #445566;
      display: flex;
      align-items: center;
      svg {
        margin-left: 6px;
      }
      &.can-edit {
        cursor: text;
      }
    }
    .ant-input {
      pointer-events: none !important;
      &.editando-header {
        pointer-events: all !important;
      }
    }
  }
  .ant-input {
    background-color: #fafafa !important;
    color: #445566;
    border: none;
    font: 400 normal 13px/19.5px "Open Sans", sans-serif;
    height: 19.5px !important;
    padding: 4px 0px;
    box-shadow: none;
    min-width: 300px;
    &::placeholder {
      color: #bbbdbf;
    }
  }
  .ant-collapse-extra {
    margin-left: auto;
  }
}
.tooltip-add-nivel {
  min-width: fit-content;
  .ant-tooltip-inner {
    text-transform: none;
    white-space: nowrap;
    min-width: 100%;
  }
}

.card-historico {
  * {
    font-family: "Open Sans";
    box-sizing: border-box;
    margin: 0;
    color: #686b6d;
  }

  background-color: #fff;

  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  height: max-content;

  .card-historico-head {
    border-bottom: 1px solid #d9d9d9;
    margin: 0 -10px 0 -10px;
    padding: 0 10px 0 10px;

    .card-historico-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #686b6d;
      }

      button {
        background-color: transparent;
        border: 0;
        margin: 0;
        display: flex;
        align-items: flex-start;
        margin-top: 4px;

        &:focus {
          outline: 0;
        }
      }
    }

    .card-historico-details {
      margin: 0 0 18px 0;

      span,
      p {
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 2px;
      }
      span {
        font-weight: bold;
      }
    }
  }
  .card-historico-info {
    padding: 5px 6px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #f7f7f7;
    width: 344px;
    margin-top: 10px;

    span,
    p {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 2px;
    }

    span {
      font-weight: bold;
    }
  }
}
.card-historico-editing {
  @extend .card-historico;
  margin-bottom: 100px !important;
  margin-top: 25px;
}
.ant-tree-list {
  .ant-tree-list-holder {
    .ant-tree-list-holder-inner {
      .ant-tree-treenode-selected {
        background-color: #26417b !important;
        z-index: 110;
        .ant-tree-indent-unit {
          background-color: #fff;
        }
        .ant-tree-switcher {
          background-color: #26417b !important;
          svg {
            path {
              fill: #fff !important;
            }
          }
        }
        .ant-tree-node-content-wrapper {
          background-color: #26417b;
        }
        .input-tree-posicao-text {
          white-space: nowrap;
          margin-right: 5px;
        }
        .input-tree-posicao {
          background-color: #26417b;
          color: #fff;
        }
        .col-icons {
          svg {
            display: flex !important;
          }
        }
      }
    }
  }
}

button:disabled {
  cursor: not-allowed !important;
}

.modal-notification-hierarquia {
  .btn-area {
    gap: 8px;

    button {
      outline: none !important;
      border: none !important;
      border-radius: 3px;
      padding: 10px 15px;
      height: auto;
    }
  }
}

.btn-hierarquia {
  .ant-btn:disabled,
  .ant-btn-button:disabled {
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    background-color: #26417b;
    border: 1px solid #1e3361;
    border-radius: 0;
    color: #fff;
    text-align: left;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
  }
}
