.form-text {
  color: red;
}

.new-item {
  padding-left: 40px;
  background-color: #f7f7f7;
  outline: none;
  border-width: 0px;
  border: none;
  width: 85%;
}

.btns {
  background-color: #f7f7f7;
  border: 1px solid #dee2e6;
  height: 40px;
  display: flex;
  align-items: center;
}

.cancel-icon {
  width: 15px;
  height: 15px;
  margin-right: 18px !important;
  margin-left: 5px;
  cursor: pointer;
}
.insert-new-icon {
  width: 15px;
  height: 15px;
  margin-left: 40px;
  cursor: pointer;
}

.new-item:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.new-item:hover {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
