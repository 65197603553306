.card-integracoes {
  max-width: 500px;

  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #686b6d;
    margin-top: -24px;
    margin-left: -5px;
  }

  .token {
    background-color: #f3f4f5;
    color: #686b6d;
    width: 470px;
    height: 105px;
    margin-left: -5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
    padding: 4.5px 10px;
    border: #d9d9d9 solid 1px;
    resize: none;
    overflow-y: hidden;
  }

  .info-token {
    display: flex;
    font-size: 10px;
    margin-top: -5px;
    margin-bottom: 15px;
    span {
      margin-left: 3px;
      color: #ff7817;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 470px;
    height: 34px;
    margin-left: -5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    letter-spacing: 0.0125em;
    text-align: center;
    color: #26417b;
    border: #26417b solid 1px;
  }

  .copied {
    color: white;
    background: #17222d;
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    color: #bbbdbf;
    margin-top: 15px;
    gap: 6px;

    div {
      width: 300px;
    }
  }
}
