.login-container {
  background-color: #293846;
  width: 100vw;
  height: 100%;
}

.login-brand {
  background-color: #f3f3f4;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #293846;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-family: 'Open Sans';
}

.login-title {
  margin-bottom: 0 !important;

  & h2 {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 7px !important;
  }

  & > .underline {
    border: 2px solid #ffffff;
    width: 35px;
    margin-left: 2px;
  }
  .title-tooltip{
    display: flex;
    gap: 8px;
    align-items: baseline;
  }
}

.login-input {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #7e7e7e;
  color: #7e7e7e;
  padding: 0 15px;
  height: 50px;

  &:focus {
    background-color: white;
  }

  &:valid {
    background-color: white;
  }
}

.login-input:focus,
.login-input:hover {
  border-color: #7e7e7e;
  box-shadow: none;
}

.login-item {
  &.ant-form-item{  
    margin-bottom: 15px;

  } 
  &.ant-form-item-with-help{
    margin-bottom: 0;
  }
  .ant-form-item-label{
    padding-bottom: 10px !important;
  }
  label {
    color: #FFFFFF !important;
    font-size: 13px;
    padding-left: 10px;
    line-height: 19.5px;
    font-weight: 400;
  }
}

.login-error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d62121;
  font-size: 14px;
  &.redefinicao{
    text-align: center;
  }
}

.brand-white {
  display: none;
}

.btn-login{
  font-weight: 600 !important;
  font-size: 18px;
  padding: 0;
  &.redefinir.disabled{
    span{
      color: #263659 !important;
    }
  }
  
}

  .info-login{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FFFFFF;
    &.confirmacao{
      margin-bottom:40px !important ;
    }
    .tipo-redefinicao{
      font-weight: 600 !important;
    }
  }
  .footer-itens{
    display: flex;
    justify-content: center;
    .link-reset-col{
      &.disabled{
          cursor: not-allowed;
          .link-reset{
            pointer-events: none;
          }
      }
      .link-reset{
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 19.5px;
        text-transform: uppercase;
        color: #ff7817 !important;
        text-align: center;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }

  
  .footer-form{
    display: flex;
    justify-content: center;
    display: flex;
    gap:18px;
    margin-top: 16px;
    .link-login{
      color: #ff7817 !important;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.175px;
      font-style: normal;
      text-transform: uppercase;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .ant-form-item.radio-tipo-envio{
    margin-bottom: 30px;
    .ant-radio-wrapper{

      margin-top: 20px;
      display: flex;
      align-items: center;
      .ant-radio-input:focus + .ant-radio-inner{
        box-shadow: none !important;
        -webkit-box-shadow:none !important;
      }
      &:hover{
        .ant-radio-inner{
          border-color: #1ab394;
        }
      }
      .ant-radio{
        &:hover{
          .ant-radio-inner{
            border-color: #1ab394;
          }
        }
        &.ant-radio-checked{
          .ant-radio-inner{
            border-color: #1ab394 ;
          }

          &::after{
            animation: none;
            border: 1px solid #1ab394;
          }
        }
        .ant-radio-input{
          width: 20px;
          height: 20px;
        
        }
        .ant-radio-inner{
          transition: none;
          width: 20px;
          height: 20px;
          &::after{
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
            background-color: #1ab394;
          }
        }
      }
      &.ant-radio-wrapper-disabled{
        .item-tipo-redefinicao{
          color: #9D9D9D;
        }
      }
      .item-tipo-redefinicao{
        max-width: 100%;
        text-wrap: wrap;
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.79px;
        color: #FFFFFF;
        
      }
    }
  }

  


@media (max-width: 992px) {
  .brand {
    display: none;
  }

  .brand-white {
    display: block;
    margin: auto;
    width: 80%;
  }

  .login-brand {
    background-color: #293846 !important;
    height: 30% !important;
    align-items: flex-end !important;
  }
  .login-form {
    height: auto !important;
    padding: 2rem !important;
    align-items: flex-start !important;
  }
}
