.processamentoCrivo {

    .header {

        width: 100%;
        height: fit-content;
        background-color: #26417B;
        color: #E1E1E1;
        display: flex;
        justify-content: space-between;
        padding: 0 22px 0 17px;
        font: 400 13px sans-serif;
        .title {
            color: #fff;
            font: 500 24px sans-serif;
            margin: 14px 0 4px 0;
        }
        .data {
            margin-bottom: 14px;
        }

        img {
            width: 58px;
            height: 65px;
            margin-top: 7px;
        }

    }
}