:root {
  --threshold: 75;
  --border-threshold: 75;
}

.tag-color {
  min-width: 150px;
  --switch: calc((var(--light) - var(--threshold)) * -100%);
  --border-light: calc(var(--light) * 0.2%);
  --border-alpha: calc((var(--light) - var(--border-threshold)) * 10);

  color: hsl(0, 0%, var(--switch));

  border: 0.1em solid
    hsla(
      var(--hue),
      calc(var(--sat) * 1%),
      var(--border-light),
      var(--border-alpha)
    );
}
