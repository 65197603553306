.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}

tbody {
  border: 1px solid #dee2e6;
}

tbody.row-click tr {
  cursor: pointer;
}

.pagination-footer {
  display: flex;
  justify-content: space-between;

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .per-page {
    display: inline-flex;
    align-items: center;

    p {
      color: #3d3d3e;
      margin: 0;
    }

    .ant-select {
      width: 72px;
      border-radius: 4px;
      margin: 0 5px 0 8px;

      .ant-select-arrow {
        color: #26417b;
        svg {
          width: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .pagination-container {
    display: inline-block;
    width: 100%;

    .pagination {
      display: inline-block;
      width: 100%;
      text-align: center;

      li {
        display: inline-block;
      }
    }
  }
}
