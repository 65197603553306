.wrapper-pagination {
  display: flex;
  line-height: 16px;

  .button-pagination {
    background-color: #fff;
    border: 1px solid #DEE2E6;
    margin-left: -1px;
    position: relative;
    text-decoration: none;
    padding: 10px 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #686b6d;
    outline: none;
  }

  button:focus {
    outline: none;
  }

  button:not(.button-current-page):hover {
    cursor: pointer;
    background-color: #e9ecef;
  }

  .preview-button:disabled:hover,
  .next-button:disabled:hover {
    background-color: #fff;
  }

  .button-current-page {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    background-color: #263659;
    padding: 10px 12px;
  }

  .preview-button,
  .next-button {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    padding: 10px 12px;
    border: 1px solid #DEE2E6;
    background-color: #fff;
  }

 .next-button {
  border-radius: 0px 2px 2px 0px;
  margin-left: -1px;
 }
  .preview-button {
     border-radius: 2px 0px 0px 2px;
    background-color: #fff;
  }

  button.preview-button:disabled,
  button.next-button:disabled {
    cursor: context-menu !important;
  }
}