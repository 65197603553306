.switch-margin {
  button {
    margin-left: 10px;
  }
}
.details {
  width: 100%;
  height: auto;
}
.cards-position {
  margin-top: 15px;
  margin-bottom: 15px;
  .col-lg-4 .col-lg-5 {
    padding-right: 0;
  }

  .item {
    margin: 0;

    p {
      font-size: 12.5px;
      display: inline-block;
      margin: 0px;
      position: relative;
      line-height: 1em;
    }

    .titulo {
      padding-right: 0px !important;
      padding-left: 0px !important;
      position: relative;

      &:before {
        border-bottom: 1px solid #d9d9d9;
        content: '';
        display: inline-block;
        width: 100%;
        left: 0px;
        top: 55%;
        position: absolute;
      }

      p {
        background-color: #fff;
        z-index: 999;
        padding-right: 7px;
        line-height: 15px;
      }
    }
    .info {
      padding-left: 10px !important;
    }
  }
}
