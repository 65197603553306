.blocklist{

    .col-inclusao-edicao{

        .ibox-content{

            form.ant-form{

                .ant-form-item{
                    margin-bottom: 17px;

                    .ant-form-item-label{
                        line-height: 1.4em;
                        padding: 0px;
                        margin-bottom: 2px;

                        label{
                            line-height: 1em;
                            font-size: 13px;
                            color: #686B6D;
                        }
                    }
                    .ant-form-item-control{

                        input,
                        textarea{
                            border: 1px solid #D9D9D9;
                            height: auto;
                            border-radius: 0px;
                            outline: none !important;
                            box-shadow: none !important;
                            line-height: 1.1em;
                            padding: 8px 10px;
                            font-size: 13px;
                            color: #6D757D;

                            &::placeholder{
                                color: #BBBDBF;
                            }
                        }
                        textarea{
                            line-height: 1.3em;
                            resize: none;
                            height: 50px;
                        }

                        .ant-select{
                            border: none !important;
                            outline: none !important;

                            .ant-select-selector{
                                border: 1px solid #D9D9D9 !important;
                                outline: none !important;
                                box-shadow: none !important;
                            }
                        }
                    }
                }
            }

            .botoes-acao{
                margin-top: 10px;

                button{
                    margin-left: 10px;
                    outline: none !important;
                    padding: 10px 17px;
                    width: auto;
                    height: auto;
                    line-height: 1em;

                    &.btn-cancelar{
                        border: 1px solid #CFD2D4;
                    }
                    &.btn-salvar{

                        svg{
                            width: 14px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}