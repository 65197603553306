.modal-upload-notas-fiscais {
  .ant-modal-content {
    justify-self: center;
    border: 1px solid #e7eaec;
    box-sizing: border-box;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: "Open Sans", sans-serif;
    width: 810px;
    left: 50%;
    top: 45%;
    transform: translateX(-50%);

    .ant-modal-close {
      outline: none;

      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 34px;
      }
    }

    .ant-modal-body {
      padding: 15px;
      padding-right: 22px;
      padding-bottom: 23px;

      h3 {
        font-size: 18px;
        color: #26417b;
        font-weight: 600;
        margin-bottom: 22px;
        line-height: 1em;
      }

      h4 {
        margin-bottom: 7px;
        font-size: 13px;
        color: #676a6c;
        font-weight: 400;
      }

      .ant-upload-picture-card-wrapper {
        border: 1px solid #d9d9d9;
        padding: 18px;
        padding-bottom: 10px;
        padding-right: 0px;
        padding-left: 14px;
        margin-bottom: 5px;

        .ant-upload-list-picture-card-container,
        .ant-upload.ant-upload-select-picture-card {
          width: 130px;
          height: 150px;
          margin: 0 15px 22px 5px;
          &:hover {
            border-color: #455667;
          }
        }
        .ant-upload-list-picture-card-container {
          height: auto;

          p.data-upload-item {
            color: #495057;
            opacity: 0.8;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-size: 13px;

            padding-right: 3px;
            margin-top: 6px;
          }
        }
        .ant-upload {
          .ant-upload-list-item {
            padding: 0px;
          }
          .anticon-plus {
            margin-bottom: 20px;
            svg {
              width: 30px;
              height: 30px;
            }
            svg path {
              fill: #445566;
            }
          }
          .action-anexar {
            font-size: 14px;
            color: #686b6d;
            line-height: 1.3em;
            padding: 0 20px;
            svg {
              width: 32px;
              height: 32px;
              margin-bottom: 22px;

              path {
                fill: #445566;
              }
            }
          }
        }
      }

      p.text-observacao {
        font-size: 13px;
        color: #676a6c;
      }
    }

    .ant-modal-footer {
      display: none;
    }

    .error-upload {
      background-color: #ffc2c2;
      border-color: #ed5555 !important;
    }

    .ant-form-item-explain-error {
      margin-top: 0;
    }

    .botoes-acao {
      margin-top: 10px;

      button {
        outline: none !important;
        padding: 10px 17px;
        width: auto;
        height: auto;
        line-height: 1em;

        &.btn-cancelar {
          border: 1px solid #cfd2d4;
        }
        &.btn-upload {
          background-color: #26417b;
          padding-top: 9.5px;
          padding-bottom: 9.5px;
          svg {
            max-width: 12px;
            max-height: 17px;
            margin-right: 10px;
          }
          &:hover {
            background-color: #263659;
          }
        }
        &:hover {
          outline: 2px solid #2ea9fb !important;
        }
      }
    }
  }
}
