.card-bordero {
    box-sizing: border-box;
    border-radius: 0;
    border: 1px solid #D9D9D9;
    background: #FFFFFF;
    height: 160px;
    width: 100%;
    margin-bottom: 15px;
    .card-bordero-header {
        padding: 12px 10px;
        border-bottom: 1px solid #D9D9D9;
        background: #F3F3F4;
        margin-left: 0;
        margin-right: 0;

        .widget-title {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 17px;
            color: #445566;
        }
    }
    .card-bordero-content {
        margin-top:20px;
        font-size: 10.5px;
        line-height: 20px;
        color: #686B6D;
        margin-left: 10px;
        .size-td-left{
          min-width: 80px;
        }
        .size-td-middle{
          min-width: 80px;
        }
        .size-td-rigth{
          min-width: 100px;
        }
        .size-td-left-date{
          min-width: 140px;
        }
        .size-td-middle-date{
          min-width: 60px;
        }
        .size-td-rigth-date{
          min-width: 100px;
        }
        .size-td-left-saldo{
          min-width: 110px;
        }
        .size-td-middle-saldo{
          min-width: 85px;
        }
        .size-td-rigth-saldo{
          min-width: 100px;
        }
    }
}
