.table {
  margin-bottom: 0;
}

.table thead tr th {
  border: 1px solid transparent;
  border-bottom: 1px solid #e7eaec;
  color: #646b70;
}

.table-responsive {
  margin-bottom: 1rem;
}

.table-responsive > .table-striped {
  border: 0;
}

.table tbody {
  border: 1px solid #e7eaec;
}

.table-custom-header {
  margin-bottom: 1.25rem;
}

.table-custom-pagination {
  display: flex;
  justify-content: flex-end;
}
