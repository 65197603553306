.oferta-produto {
  .renda-text{ 
    padding-left: 15px; 
    flex: 1; 
    margin-right: 5px; 
    text-align: end; 
    white-space: nowrap; 
    color: #263659; 
    font-family: 'Open Sans';
    font-weight: 600; 
    font-style: normal; 
    font-size: 14px; 
    line-height: 20px;
    display: flex;
    align-items: center;
    svg{
      width: 15px;
      height: 15px;
      path{
        fill: #1B2A4E;
      }
    }
  }
  .main-area {
    margin-top: 10px;
  }

  .response {
    margin-top: 10px;
  }
  .ibox-title {
    padding: 16px 16px 8px 16px !important;

    .title-oferta {
      display: flex;
      flex: 1;
      justify-content: space-between;
      .btn-orange {
        border: 1px solid #e56c15;
        width: 35px;
        path {
          fill: #fff;
        }
      }
    }
  }

  .dados-cliente {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #495057;
    .title-cliente {
      color: #707070;
    }
  }

  .wrapper-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    line-height: 25px;
  }

  @media (max-width: 581px) {
    .wrapper-grid {
      grid-template-columns: 1fr;
    }
  }

  .renda-text{
    padding-left: 15px;
    flex: 1;
    margin-right: 5px;
    text-align: end;
    white-space: nowrap;
    color: #263659;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
  }
}
.ant-tooltip.tooltip-selecao-oferta {
  max-width: 452px;
  .ant-tooltip-arrow{
    width: 22px;
    .ant-tooltip-arrow-content{
        width: 20px;
        height: 13px;
        bottom: -7px;
        background:#191919 !important;
    }
  } 
  .ant-tooltip-inner{
      padding: 10px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: #FFFFFF;
      .itens-list-tooltip{
          margin-left: 30px;
      }
  }
  
}
