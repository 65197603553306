.title-hr {
  width: auto;
  hr {
    display: inline-block;
    width: 100%;
    margin: 5px 10px;
    border-top: 1px solid #e5e5e5;
  }
}

.nome-cliente {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
}

.tooltip-body{
  h1{
    font-size: 28px;
    line-height: 38px;
    color: #fff;
    margin: 0 0 5px 0;
    font-weight: 400;
  }
  .nome-data{
    color: #fff;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
  .txta-motivo{
    margin-bottom: 17.5px;
    resize: none;
    padding: 10px 10px 12px 10px;
  }
  .proposta-credito-uploads{
    margin-bottom: 18px;
    background-color: #fff;
  }
  .label-motivo-historico{
    font-size: 14px;
    line-height: 19px;
  }
}

.z-index-0{
  z-index: 0 !important;
}

.tooltip-historico-reanalise{
  max-height: 500px;
  overflow-y: scroll;
}

.tooltip-historico-reanalise::-webkit-scrollbar{
  width: 5px;
  height: calc(1em - 50px);
}

.tooltip-historico-reanalise::-webkit-scrollbar-thumb{
  background: #FF7817;
  border-radius: 6px;
  height: 172px;
  border-right: 5px solid transparent;
}

.ant-collapse{
  margin-top: 5px !important;
  label{
    color: #686B6D;
    margin: 0;
    margin-top: 12px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 19px;
  }
  .ant-collapse-header{
    color: #686B6D !important;
    font-size: 14px;
  }
  .txta-motivo{
    background: #F3F4F5;
    border: 1px solid #D9D9D9;
    margin-bottom: 18px;
  }
  .ant-collapse-content-box{
    background: #F7F7F7;
    border: 1px solid #DEE2E6;
    padding: 10px;

    label{
      margin: 0;
      margin-bottom: 13.5px;
    }
  }
}

.col-informacoes-cliente {
  .card-icon-title {
    margin-bottom: 10px;

    span.tag-reanalise {
      border: 1px solid #e56c15;
      padding: 4px 12px;
      border-radius: 3px;
      background-color: transparent;
      color: #e56c15;
      font-size: 10px;
      line-height: 1em;
      font-family: 'Open Sans';
      font-weight: 400;
      margin-top: 1px;
      cursor: pointer;
    }
  }
  .informacoes {
    p {
      font-size: 12.5px;
      color: #686b6d;
      line-height: 1.25em;
      margin-bottom: 5px;
      letter-spacing: 0.001em;
    }

    .rating {
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }

    .square {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-top: 3px;
      margin-top: -0.8px;
      border-radius: 2px;
    }
  }
}
