.modal-tabela-inativa {
  width: 691px !important;
  header {
    display: flex;
    justify-content: center;
    margin-top: 42px;
    svg {
      width: 90px;
      height: 90px;
    }
  }
  main {
    margin: 0 auto;
    height: 176px;
    max-width: 592px;
    margin-top: 42px;
    padding: 10px 11px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    ul {
      list-style: disc inside;
      text-align: left;
      li {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 19px;
        line-height: 25px;
        color: #686b6d;
        ul {
          padding-left: 36px ;
        }
      }
    }
    & > ul {
      & > li:first-child {
        margin-bottom: 10px;
      }
    }
  }
  footer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 16px;

    button  {

      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      outline: none;
      border: none;
      color: white;
      width: 125px;
      height: 45px;
      background-color: #26417b;
      border-radius: 3px;
    }
  }
}
