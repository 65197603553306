.container-filtro-relatorio-controle-propostas {
  .title-filtro-relatorio-controle-propostas {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.5px;
    color: #263659;
    margin: 0;
  }

  .filter-controle-propostas {
    background-color: #f7f7f7;
    border: 0.5px solid #b8b9bc;
    margin-top: 10px;
    padding: 15px;

    .ant-form-item {
      margin-bottom: 15px;
    }

    .form-row .col-lg-4,
    .filter-controle-propostas .form-row .col-lg-2 {
      padding-right: 7px;
      padding-left: 7px;
    }

    .ant-form-item-label {
      padding-bottom: 8px;
    }
    .ant-form {
      label {
        font-size: 13px;
      }
    }
    .ant-picker {
      input {
        border: none;
      }
      .ant-picker-range-separator {
        padding-left: 0px;

        svg {
          width: 22px;
          height: auto;
          position: relative;
          left: -5px;
          top: -2px;

          path {
            fill: #6d757d !important;
          }
        }
      }
      .ant-picker-active-bar {
        display: none;
      }
      .ant-picker-suffix {
        margin-right: 0px;
      }
    }

    .ant-input-number {
      width: 132px;
    }

    .input-numero-proposta-tela-controle-propostas {
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      svg {
        position: absolute;
        left: 55px;
        bottom: 46px;
      }
    }
  }

  .filtro-cpf-controle-proposta {
    .ant-form-item-control-input-content {
      position: relative;
    }

    .ant-form-item-control-input-content svg {
      display: flex;
      position: absolute;
      bottom: 46px;
      right: 0;
      left: 25px;;
    }
  }
}

.container-buttons-controle-proposta {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 5px;
  .button-limpar-controle-proposta {
    margin-top: 0;
    padding: 0;
    width: 161px;
  }

  .button-enviar-csv-controle-proposta {
    margin-top: 0;
    padding: 0;
    width: 132px;

    span {
      width: 79px !important;
    }
  }
}

.input-numero-proposta-tela-controle-propostas svg {
  position: absolute;
  left: 55px;
  bottom: 46px;
}

@media only screen and (max-width: 767.5px) {
  .container-buttons-controle-proposta {
    flex-direction: column-reverse !important;
  }

  .button-enviar-csv-controle-proposta, .button-limpar-controle-proposta {
    width: 100% !important;
  }

  .container-filtro-relatorio-controle-propostas .filter-controle-propostas {
    margin-top: 15px;
  }

  .filter-controle-propostas .ant-form-vertical {
    padding-bottom: 0px !important;
}

}

