.form-upload-documentos-credenciamento {
  .ant-upload-list {
    .ant-upload-list-picture-card-container {
      .ant-select {
        height: 35px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        .ant-select-selector {
          padding: 5.5px 14px 8.5px 9px;
        }
      }
    }
  }
}

.credenciado-page-styles {
  .ant-select-selector {
    .ant-select-selection-placeholder {
      font: normal 400 14px/19px 'Open Sans', sans-serif !important;
      padding: 4px 0px;
    }
  }

  .form-row {
    margin-left: -4px !important;
    margin-right: -4px !important;

    .row {
      &>div {
        padding: 0 8px !important;
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 2px;
  }

  .col-tab-endereco,
  .col-tab-dados-gerais,
  .col-tab-dados-bancarios {
    .ant-row.ant-form-item {
      margin-bottom: 10px;
    }
  }

  .ant-form-item-label {
    margin-bottom: 5px !important;
  }

  .ant-form-item {
    .ant-form-item-control-input {
      min-height: 30px;
    }

    .ant-form-item-control-input-content,
    .ant-select-single,
    .ant-select-selector {
      height: 30px;

      .ant-picker {
        height: 30px;
      }
    }
  }

  .ant-form-item-control-input-content,
  .ant-form-item-label {

    label,
    textarea,
    textarea::placeholder,
    input::placeholder,
    .ant-select-selection-placeholder,
    .ant-picker-input {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 19px;
    }

    input {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    textarea {
      resize: none;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0;
      height: 97px !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      height: 30px;
    }

    input[type='search'] {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .col-tab-dados-gerais {
    .col-switchs {
      display: flex;

      label {
        cursor: pointer;
      }

      .ant-form-item {
        margin-right: 15px;

        button {
          margin-right: 4px;
        }
      }
    }
  }

  .btn-trash {
    cursor: pointer;
    background-color: #ed5565;
    border: 1px solid #d44c5a;
    color: white;
    border-radius: 0px;
    outline: none !important;
    padding: 7px 13px;
    margin-top: 2px !important;

    svg {
      width: 13px;
    }

    &:hover,
    &:focus {
      color: #d44c5a;
      background-color: #fff;
      border-color: #d44c5a;
    }

    &:hover svg path,
    &:focus svg path {
      fill: #d44c5a !important;
    }

    &:disabled {
      background-color: #eeeeee;
      border-color: #cccccc;

      svg path {
        fill: #cccccc !important;
      }
    }
  }

  .btn-acao {
    font: 400 normal 14px/19.5px 'Open Sans', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    outline: none !important;
    color: white;
    padding: 7px 15px;
    height: auto;

    svg {
      margin-right: 10px;
      width: 16.5px;
      height: 16.5px;

      path {
        fill: #fff !important;
      }
    }

    &.btn-enviar {
      background-color: #26417b;

      &:hover,
      &:focus {
        color: #26417b;
        background-color: transparent;
        border-color: #26417b;

        svg path {
          fill: #26417b !important;
        }
      }

      &.btn-plus-contato {
        margin-top: 10px;
      }
    }
  }

  .col-tab-endereco {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .contact {
    display: flex;
    margin-left: -8px !important;
    margin-right: -8px !important;
    position: relative;

    &+.contact {
      margin-top: 13px;
      padding-top: 15px;
    }

    .gray-line {
      position: absolute;
      width: calc(100% - 24px);
      top: 0;
      left: 8px;
      height: 1px;
      background-color: #d9d9d9;
    }

    .contact-form {
      width: 90%;
    }

    .delete-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
    }
  }

  .col-tab-socios {
    .item-socios {
      display: flex;
      margin-left: -4px;

      .grid-socios {
        display: grid;
        flex: 1;
        grid-template-columns: 3fr 4fr 3fr;

        .pep-select {
          .bullet-info-icon {
            position: relative;
            top: -3px;
          }

          .ant-form-item-explain {
            position: relative;
            bottom: -24px;
          }
        }
      }

      .col-button-remove {
        display: flex;
        align-items: center;
        justify-content: center;

        .btnRemoveInputs {
          margin-left: 12px;
          margin-right: 6px;
          width: 41px;
          height: 35px;
          background-color: #ed5565;

          .fa-trash {
            color: #ffff;
          }
        }
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .col-button-socios {
      .btn-socios {
        background-color: #26417b;
        border: 1px solid #1e3361;

        svg {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }

  .col-tab-dados-bancarios {
    .grid-dados-bancarios {
      display: grid;
      grid-template-columns: minmax(0, 3fr) 2fr 2fr 3fr;

      .tempo-conta-item-grid {
        grid-row: 2 / 3;
        grid-column: 3 / 5;
      }

      .chave-pix-item-grid {
        grid-column: 2 / 5;
      }

      .conta-dados-bancarios {
        .bullet-info-icon {
          position: relative;
          top: -3px;
        }

        .ant-form-item-explain {
          position: relative;
          bottom: -24px;
        }
      }
    }

    .col-input-agencia {
      padding: 0 7px !important;
      flex-direction: column;

      .traco-agencia-digito {
        width: 9px;
        height: 2px;
        color: #c4c4c4;
      }

      label {
        margin-top: 6px;
        margin-bottom: 5px !important;
      }
    }
  }

  .comercial-tab {
    margin-right: -8px !important;
    margin-left: -8px !important;

    .comercial-title {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #26417b;
      margin-top: 9.5px;
      margin-bottom: 14px;
    }

    .table-responsive {
      border: 1px solid #dbdbdc !important;

      table {
        margin-bottom: 0;
        width: 100%;

        th,
        td {
          width: 1%;
          text-align: center;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          border: none;
        }

        thead {
          border-bottom: 1px solid #dbdbdc !important;

          th {
            font-weight: 600;
            color: #3d3d3e;
            height: 44px;

            span {
              margin-left: 10px !important;
            }
          }
        }

        tbody {
          border: none;

          tr {
            td {
              color: #3d3d3e;
            }

            td:first-child {
              color: #686b6d;
              font-weight: 400;
            }

            &:nth-child(2n - 1) {
              background-color: #f7f7f7;
            }
          }
        }
      }
    }

    .rc-virtual-list {
      background-color: #fff;
      max-height: 128px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 20px;
        /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #dbdbdc;
        /* color of the scroll thumb */
        border-radius: 20px;
        /* roundness of the scroll thumb */
        border: 6px solid #fff;
        /* creates padding around scroll thumb */
      }
    }
  }

  .col-tab-produtos {
    .col-button-remove {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      padding-right: 0 !important;
    }

    .col-new-info {
      padding: 0 !important;
      display: flex;

      .item-dados {
        padding-right: 0 !important;
      }
    }

    .col-button {
      display: flex;
      justify-content: right;
      padding: 0;

      .btn-produtos {
        margin-top: 5px;
        background-color: #ffffff;
        border: 1px solid #26417b;
        color: #26417b;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        svg {
          margin-right: 6px;

          path {
            fill: #26417b;
          }
        }
      }
    }

    .col-modalidade {
      display: flex;
      align-items: center;
      margin-bottom: 9px;

      .label-modalidade {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #686b6d;
        margin-right: 5px;
      }

      .ant-radio-group {
        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #e5e6e7 !important;
          }
        }

        .ant-radio-inner::after {
          background-color: #1ab394;
        }
      }
    }

    .ant-select-selection-item {
      .ant-select-selection-item-content {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #686b6d;
      }

      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;

        svg {
          width: 5px;
          height: 5px;

          path {
            fill: #686b6d;
          }
        }
      }
    }
  }

  .tab-visita-credenciamento,
  .col-tab-dados-gerais {
    .switch-label {
      margin-right: 10px;
      color: #676a6c;
      font: 400 normal 13px/20px 'Open Sans', sans-serif;
      margin-bottom: 12px;
    }

    .switch-red-if-off {
      height: 12px;

      .ant-switch-handle {
        left: -1px;

        svg {
          path {
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
          }
        }

        top: 0px;
      }

      &.ant-switch-checked {
        .ant-switch-handle {
          left: 100%;
          transform: translateX(calc(-100% + 1px));
        }
      }
    }

    .time-picker {
      .ant-picker-suffix {
        svg {
          path {
            fill: #263659;
          }
        }
      }
    }

    .form-upload-visita-credenciamento {
      .ant-upload {
        border-color: #afb5c2;
        background-color: #ffffff;

        .action-anexar {
          .text {
            margin-top: 24px;
            width: 72px;
            color: #686b6d;
          }
        }
      }

      .ant-upload-picture-card-wrapper {
        border: 1px solid #d9d9d9;
        padding: 20px 20px 0 0;

        .ant-upload-list-picture-card-container,
        .ant-upload-select-picture-card {
          margin-left: 21px;
          margin-bottom: 20px;
          margin-right: 0;
          height: 140px;
          width: 122px;

          .icone-arquivo {
            height: 100%;

            .ant-upload-list-item {
              padding: 0;
              border: 1px dashed #afb5c2;
            }

            .ant-upload-list-item-actions {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;

              button.ant-btn {
                height: fit-content;
                width: fit-content;
                display: flex !important;
                align-items: center;
                justify-content: center;

                span.anticon {
                  width: fit-content;
                  height: fit-content;
                }
              }

              .ant-upload-list-item-card-actions-btn {
                path {
                  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
                }

                .anticon-eye {
                  svg {
                    width: 47px;
                    height: 35px;
                  }
                }

                .anticon-delete {
                  svg {
                    width: 39px;
                    height: 37px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .visita-form-grid {
      display: grid;
      gap: 15px;
      grid-template-columns: 1fr 1fr 3fr !important;
      margin-bottom: 10px;
    }
  }

  .contact,
  .comercial-tab,
  .col-tab-produtos,
  .col-tab-dados-bancarios,
  .col-tab-dados-gerais,
  .col-tab-endereco,
  .col-tab-socios {
    margin-left: -4px;
    margin-right: -4px;

    .item-dados {
      padding: 0 8px !important;
      margin-bottom: 10px;
    }

    .double-item-dados {
      padding: 0 8px !important;

      .ant-col {
        margin-bottom: 10px;
      }
    }
  }

  .tab-visita-credenciamento {
    .form-upload-visita-credenciamento {
      .label-upload {
        font: 400 normal 13px/20px 'Open Sans', sans-serif;
      }
    }
  }

  .col-tab-regiao {
    .row {
      .item-dados {
        padding: 0 8px !important;
      }
    }

    .sub-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #26417b;
      margin-bottom: 15px;
      padding: 0 !important;

      &.regioes {
        margin-top: 15px;
      }
    }

    .col-button-plus {
      display: flex;
      align-items: center;
      justify-content: center;

      .btnPlusRegiao {
        width: 30px;
        height: 30px;
        background-color: #26417b;
        position: relative;
        padding: 0;
        margin-top: 15px;

        svg {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 15px;
          height: 15px;
        }
      }
    }

    .table-responsive {
      border: 1px solid #dbdbdc !important;

      table {
        margin-bottom: 0;
        width: 100%;

        th,
        td {
          width: 1%;
          text-align: center;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          border: none;
        }

        thead {
          border-bottom: 1px solid #dbdbdc !important;
        }
      }
    }
  }

  .tab-documentos-credenciado {
    .anexos-documentos {
      label {
        font: normal 600 16px/15.6px 'Open Sans', sans-serif;
        color: #445566;
        margin-left: 9.6px;
        margin-bottom: 20px;
      }

      .bullet-info-icon {
        width: 13px;
        height: 13px;
      }
    }

    .ant-col,
    .ant-form-item-control-input-content {
      height: 100%;
    }

    .ant-upload-picture-card-wrapper {
      border: 1px solid #d9d9d9;
      padding: 0 2px;

      .ant-upload-list-picture-card-container,
      .ant-upload-select-picture-card {
        margin: 15px 20px;
      }

      .ant-upload-select-picture-card {
        margin: 20px 20px;
      }

      .ant-upload-list-picture-card-container:nth-last-child(-n + 2) {
        margin-right: 10px;
      }

      .ant-upload-list-picture-card-container {
        width: 140px;

        .ant-upload-list-item {
          height: 90px !important;
          width: 63.5px;
          margin: 0 auto;
          border: 1px solid #263659;
          border-radius: 2.5px;
          padding: 0;
          margin-bottom: 10px;

          .ant-upload-list-item-actions {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            .ant-btn {
              width: fit-content;
              height: fit-content;

              .anticon {
                width: fit-content;
                height: fit-content;

                &.anticon-eye {
                  svg {
                    width: 28px !important;
                  }
                }

                &.anticon-delete {
                  svg {
                    height: 25px;
                    width: 25.5px !important;
                  }
                }
              }
            }
          }
        }

        div.ant-select.ant-select-single.ant-select-show-arrow {
          .ant-select-selector {
            height: fit-content;
            font: normal 400 11.5px 'Open Sans', sans-serif;
            color: #fff;

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
              opacity: 1;
            }
          }
        }

        .data-upload-item {
          color: #495057;
          margin: 6.5px 0 0 0;
          opacity: 0.8;
          font: 400 normal 11.5px/11.5px 'Open Sans', sans-serif;
        }
      }
    }
  }

  .visita-form-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr 3fr !important;
    margin-bottom: 10px;
  }

  .contact,
  .col-tab-produtos,
  .col-tab-dados-bancarios,
  .col-tab-dados-gerais,
  .col-tab-endereco,
  .col-tab-socios {
    margin-left: -4px;
    margin-right: -4px;

    .item-dados {
      padding: 0 8px !important;
      margin-bottom: 10px;
    }

    .num-conta {
      label {
        font-size: 12.5px !important;
      }

      padding-left: 3px !important;
      padding-right: 3px !important;
    }

    .double-item-dados {
      padding: 0 8px !important;

      .ant-col {
        margin-bottom: 10px;
      }
    }
  }

  .tab-visita-credenciamento {
    .form-upload-visita-credenciamento {
      .label-upload {
        font: 400 normal 13px/20px 'Open Sans', sans-serif;
      }
    }
  }

  .col-tab-regiao {
    .row {
      .item-dados {
        padding: 0 8px !important;
      }
    }

    .sub-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #26417b;
      margin-bottom: 15px;
      padding: 0 !important;

      &.regioes {
        margin-top: 15px;
      }
    }

    .col-button-plus {
      display: flex;
      align-items: center;
      justify-content: center;

      .btnPlusRegiao {
        width: 30px;
        height: 30px;
        background-color: #26417b;
        position: relative;
        padding: 0;
        margin-top: 15px;

        svg {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 15px;
          height: 15px;
        }
      }
    }

    .table-responsive {
      border: 1px solid #dbdbdc !important;
      overflow: hidden;

      table {
        margin-bottom: 0;
        width: 100%;

        th,
        td {
          width: 1%;
          text-align: center;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          border: none;
        }

        thead {
          border-bottom: 1px solid #dbdbdc !important;
        }
      }
    }
  }

  .tab-documentos-credenciado {
    .anexos-documentos {
      label {
        font: normal 600 16px/15.6px 'Open Sans', sans-serif;
        color: #445566;
        margin-left: 9.6px;
      }

      .bullet-info-icon {
        width: 13px;
        height: 13px;
      }
    }

    .ant-col,
    .ant-form-item-control-input-content {
      height: 100%;
    }

    .ant-upload-picture-card-wrapper {
      border: 1px solid #d9d9d9;
      padding: 0 2px;

      .ant-upload-list-picture-card-container,
      .ant-upload-select-picture-card {
        margin: 15px 20px;
      }

      .ant-upload-select-picture-card {
        width: 120px !important;
        height: 140px !important;
        border: 1px dashed #afb5c2;

        .action-anexar {
          div {
            font: 400 normal 13px/13px 'Open Sans', sans-serif;
            color: #686b6d !important;
          }

          svg {
            margin-bottom: 24px;
          }
        }
      }
    }

    .tooltip-anexos-documentos-left,
    .ant-tooltip {
      .ant-tooltip-arrow {
        display: none;
      }

      &.ant-tooltip-placement-topLeft {
        &::before {
          transform: rotate(45deg);
          position: absolute;
          content: '';
          bottom: 6.5px;
          left: 2px;
          background-color: #17222d;
          width: 10px;
          height: 10px;
          z-index: -1;
        }

        .ant-tooltip-inner {
          z-index: 10;
          transform: translateX(-10px);
          white-space: nowrap;
          padding: 15px;
          background-color: #435361;
          font: normal 400 14px/19px 'Open Sans', sans-serif;
          min-width: 391px;
          border-radius: 2px;
        }
      }
    }

    .ant-form-item-explain {
      min-height: 10px;
      margin-top: 3px;
    }
  }

  .col-tab-unidades {
    .box-filtros {
      .ant-form-vertical {
        margin-left: -4px;
        margin-right: -4px;
      }

      .col-buttons {
        padding-left: 15px !important;
        margin-right: -4px;
        margin-left: -4px;
      }
    }

    .col-header {
      display: flex !important;
      margin-bottom: 10px;
      align-items: center;
      padding: 0 !important;

      .col-sub-title {
        display: flex !important;
        align-items: center;
        padding: 0 !important;

        svg {
          margin-right: 8px;
        }

        .sub-title-form {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #26417b;
        }
      }

      .col-button {
        padding: 0 !important;
        display: flex;
        justify-content: flex-end;

        .btn-filtrar {
          background-color: #e7eaec;
          border: 1px solid #cfd2d4;
          white-space: nowrap;

          svg {
            margin-right: 10px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .col-button {
      .btn-unidade {
        margin-top: 12px;
        background-color: #ffffff;
        border: 1px solid #26417b;
        color: #26417b;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        padding: 2px 10px;
        height: auto !important;

        svg {
          margin-right: 6px;
          width: 8.75px;
          height: 8.75px;

          path {
            fill: #26417b;
          }
        }
      }
    }

    .box-filtros {
      margin-top: 0;
      margin-bottom: 15px;
      border: 0.5px solid #b8b9bc;
      padding: 15px;

      .col-itens-filter {
        flex: 1;
        padding-right: 0;

        .form-row {
          display: grid;
          grid-template-columns: 3fr 4fr 3fr;

          .item-filter {
            max-width: initial;
            padding: 0 8px;
            width: 100%;

            .ant-row.ant-form-item {
              margin-bottom: 19px;

              .ant-col.ant-form-item-label {
                padding-bottom: 5px;

                label {
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #686b6d;
                }
              }
            }
          }
        }
      }

      .col-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 15px;
        padding-left: 0;
        white-space: nowrap;

        .btn-pesquisar {
          margin-bottom: 16px;
          background-color: #26417b;
          text-align: left;
          border: 1px solid #1e3361;

          svg {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
        }

        .btn-limpar {
          text-align: left;
          background-color: #e7eaec;
          border: 1px solid #cfd2d4;

          svg {
            width: 16px;
            height: 15px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  // Estilização do elemento de Árvore da aba de Unidades

  .ant-tree-list {
    padding: 0;

    .ant-tree-list-holder {
      .ant-tree-list-holder-inner {
        .ant-tree-treenode {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #686b6d;

          .ant-tree-indent-unit-start,
          .ant-tree-indent-unit {
            position: relative;
            height: 100%;

            &::before {
              position: absolute;
              top: 0;
              right: 12px;
              bottom: -4px;
              border-right: 1.4px solid #1ab394;
              content: '';
            }
          }

          .anticon-caret-down {
            svg {
              path {
                fill: #1ab394;
              }
            }
          }

          .ant-tree-node-content-wrapper {
            background-color: transparent;
            padding: 3px 5px;
            border-radius: 0;
            transition: none;

            svg {
              display: none;
            }
          }
        }

        .ant-tree-treenode:hover,
        .ant-tree-treenode-selected {
          background-color: transparent !important;
          z-index: 110;

          .ant-tree-indent-unit {
            background-color: #fff;
          }

          .ant-tree-switcher {
            background-color: #26417b !important;

            svg {
              path {
                fill: #fff !important;
              }
            }
          }

          .ant-tree-node-content-wrapper {
            background-color: #26417b;
            display: flex !important;
            color: #fff;

            .info-tree {
              // min-width: 200px !important;
              display: flex;
              align-items: center;

              .col-icons {
                margin-left: 13px;
                display: flex;
                // height: 100%;
                align-items: center;
                justify-content: center;

                svg {
                  display: flex;
                  margin-left: 15px;
                  width: 11px;
                  height: 11px;

                  path {
                    fill: #ffff !important;
                    fill-opacity: 1 !important;
                  }
                }
              }
            }
          }

          .input-tree-posicao-text {
            white-space: nowrap;
            margin-right: 5px;
          }

          .col-icons {
            svg {
              display: flex !important;
            }
          }
        }
      }
    }
  }
}

.alter-hierarquia-comercial-modal {
  max-width: 450px;
  border-radius: 0;

  .swal-icon--warning {
    border: none;
    margin: 35px auto 10px auto;
    height: 75px;
    background-image: url("data:image/svg+xml,%3Csvg width='76' height='75' viewBox='0 0 76 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38 6.25C55.2312 6.25 69.25 20.2688 69.25 37.5C69.25 54.7312 55.2312 68.75 38 68.75C20.7688 68.75 6.75 54.7312 6.75 37.5C6.75 20.2688 20.7688 6.25 38 6.25ZM38 0C17.2906 0 0.5 16.7906 0.5 37.5C0.5 58.2094 17.2906 75 38 75C58.7094 75 75.5 58.2094 75.5 37.5C75.5 16.7906 58.7094 0 38 0ZM33.3125 18.75H42.6875L39.5625 43.75H36.4375L33.3125 18.75ZM38 57.0312C35.8438 57.0312 34.0938 55.2812 34.0938 53.125C34.0938 50.9688 35.8438 49.2188 38 49.2188C40.1562 49.2188 41.9062 50.9688 41.9062 53.125C41.9062 55.2812 40.1562 57.0312 38 57.0312Z' fill='%23FA6E0A'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;

    .swal-icon--warning__body {
      display: none;
    }
  }

  .swal-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal 400 19px/25px 'Open Sans', sans-serif;
    color: #686b6d;
    margin: auto;
    vertical-align: middle;
    max-width: 400px;
    min-height: 89px;
  }

  .swal-footer {
    padding: 0 0 25px 0;
    margin-top: 20px;

    .swal-button-container {
      margin: 0 8px;

      .swal-button {
        font: normal 400 14px/19px 'Open Sans', sans-serif;
        padding: 9px 31px;
        color: #fff;
        border-radius: 3px;
        text-transform: uppercase;
        letter-spacing: 0.25px;

        &.swal-button--cancel {
          border: 1px solid #cfd2d4;
          color: #263659;
          background-color: #e7eaec;
        }

        &.swal-button--confirm {
          border: 1px solid #26417b;
          background-color: #26417b;
          border-radius: 3px;
        }
      }
    }
  }
}

.input-classificacao-credenciado {
  svg {
    margin-top: -6px !important;
  }

  .ant-select-selector {
    height: auto !important;
  }
}

.cpf-cnpj-tooltip-unidade {
  * {
    font-family: 'Open Sans' !important;
  }

  margin-bottom: 5px !important;
  margin-top: 5px;
}

.ativo-tooltip-unidade {
  * {
    font-family: 'Open Sans' !important;
  }

  margin-bottom: 5px !important;
}

.documentos-error-span {
  font-size: 10px;
  color: red;
  margin-left: 25px;
}

.produtosSelect {

  .ant-select-selector,
  .col-new-info,
  .col-lg-12,
  .col-lg-4,
  .item-dados,
  .col-modalidade,
  .ant-col,
  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: fit-content !important;
  }
}

.produtoSelector {

  .ant-select-selector,
  .ant-select-selection-search,
  .ant-select-selection-search-input,
  .ant-select-selection-item {
    min-height: 32px !important;
  }
}

.percentage-numberfield {
  border: none;
  padding-right: 24px;
  &:hover, &:focus {
    border: none;
    box-shadow: none;
  }
}

.percentage{
  display: flex;
  align-items: center;
  span {
    position: absolute;
    right: 10px;
  }
  transition: 0.5s;
  border: 1px solid #d9d9d9;
  &:hover, &:focus {
    border: 1px solid #40a9ff;
    box-shadow: #40a9ff;
  }
}

.col {
  .ant-form-item-has-error {
    .percentage {
      border-color: #ff4d4f !important;
      span {
        position: absolute;
      }
    }
  }
}

.message-detalhe-produto {
  font-size: 10px;
  color: #ED5565;
  font-family: 'Open Sans';
}


.form-item-modalidade-produtos-form {

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;

  }

  .ant-form-item-explain-error {
    margin-top: 0px;
  }
  .radio-group-modalidade-produtos-form label{
    margin-bottom: 0px;

  }
}
.credenciado-page-styles
  .select-banco-concorrente
  .ant-form-item
  .ant-form-item-control-input-content,
.credenciado-page-styles
  .select-banco-concorrente
  .ant-form-item
  .ant-select-selector {
  height: auto !important;
}