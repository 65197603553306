/*

.ibox-content {
    padding: 6px 15px 14px 25px !important;
}

*/

.card-operatividade {
    background-color: white;
    padding: 15px;
    height: 100%;
    border: 1px solid #D9D9D9;
}

.card-operatividade-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    color: #445566;
}

.card-time {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;

    color: #667788;
}

.card-operatividade-header{
    display:flex;
    justify-content:space-between;
    align-items: center;
}

.nome-pessoa {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;

}

.status-pessoa {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
}

.card-operatividade-content {

}

.nomeStatus {
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding: 15px 0px 20px 10px !important;
}
