.widget-relatorio {
  box-sizing: border-box;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  border-radius: 6px;
  width: 100%;

  .widget-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 0px 12px;

    .widget-title {
      font-size: 16px;
      font-weight: 700;
    }

    h2 {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
  }

  .widget-content {
    text-align: right;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px 12px 12px;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 20px;
      color: #fff;
      margin-bottom: 3.5px;
    }

    span {
      margin-right: 3px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #fff;
      line-height: 16px;
    }

    i {
      font-family: "Open Sans", sans-serif;
      display: block;
      color: #fff;
      font-size: 13px;
      margin-bottom: 0px;
      margin-top: -10px;
    }

    h3 {
      font-size: 14px;
      color: #fff;
      margin: 0;
    }

    div:last-child {
      padding-right: 10px;
    }
  }

  @media (min-width: 1440px) {
    .widget-header {
      padding: 18px 18px 0px 18px;

      .widget-title {
        font-size: 24px;
      }

      h2 {
        font-size: 32px;
      }
    }

    .widget-content {
      padding: 0px 18px 18px 18px;

      h2 {
        font-size: 32px;
      }

      i {
        font-size: 16px;
      }

      h3 {
        font-size: 18px;
      }

      div:last-child {
        padding-right: 0;
      }
    }
  }

  @media (min-width: 2560px) {
    .widget-header {
      padding: 18px 18px 0px 18px;

      .widget-title {
        font-size: 36px;
      }

      h2 {
        font-size: 50px;
      }
    }

    .widget-content {
      padding: 0px 18px 18px 18px;

      h2 {
        font-size: 32px;
      }

      i {
        font-size: 26px;
      }

      h3 {
        font-size: 32px;
      }

      div:last-child {
        padding-right: 0;
      }
    }
  }

}