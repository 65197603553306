body {
  -webkit-print-color-adjust: exact;
}

* {
  margin: 0;
  padding: 0;
}

.bg-white {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: relative;
}

.headerRelatorioAnalitico {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px;
  width: 100%;
  background-color: #26417b;
  border-right: 0;
}

.headerRelatorioAnalitico .title {
  font-family: sans-serif;
  margin: 7px 0 1px 8px;
font-size: 11.647px;
font-style: normal;
font-weight: 600;
color: #FFF;

}

.headerRelatorioAnalitico .subtitle {  
  font-family: sans-serif;
  margin: 1px 0px 7px 8px;
  font-size: 6.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 7px;
  color: #e1e1e1;
}

.headerRelatorioAnalitico .title,
.headerRelatorioAnalitico .subtitle {
  margin-left: 18px;
}

.logoRelatorioAnalitico {
  margin: 6px 7px 5px 0px;
  width: 21px;
height: 27px;
}

.tabela {
  font: normal 400 9px/10px sans-serif;
  width: calc(100%);
  border: 1px solid #e7eaec;
  color: #646b70;
  border-collapse: collapse;
}

.tHead .tHeadItemRelatorioAnalitico {
  font-family: Open Sans;
  font-size: 9.713px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  font-weight: 400;
  color: #3D3D3E;
  width: 77px;
}

.tBody {
  border-top-color: transparent;
}

.trBody .tBodyItemRelatorioAnalitico {
  padding: 0 1px;
  color: #686B6D;
}

.trBody .tBodyItemRelatorioAnalitico:last-child {
  border-right: 1px solid #e7eaec;
}

.tHead .tHeadItemRelatorioAnalitico:first-of-type {
  padding-left: 10px;
}

.tHead .tHeadItemRelatorioAnalitico:last-of-type {
  padding-right: 10px;
  border-right: 1px solid #e7eaec;
}

.trBody .tBodyItemRelatorioAnalitico:last-of-type {
  padding-right: 10px;
}

.tHeadItemRelatorioAnalitico {
  text-align: center;
  padding-block: 7.5px;
}

.tBody {
  text-align: center;
  font: normal 400 9px/10px sans-serif;
  color: #646b70;
}

.trBody {
  padding: 0 10px;
}

.trBody:nth-child(2n-1) {
  background-color: #f7f7f7;
}

.tBody .tBodyItemRelatorioAnalitico {
  height: 30px;
}

.paginacao {
  margin: 10px 40px 15px 0;
  text-align: right;
  page-break-after: always;
}

@page {
  margin: 45px 30px 45px 30px;
  size: landscape;
  position: relative;
}
