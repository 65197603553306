.table-form {
    .ant-table.ant-table-small .ant-table-title,
    .ant-table.ant-table-small .ant-table-footer,
    .ant-table.ant-table-small .ant-table-thead>tr>th,
    .ant-table.ant-table-small .ant-table-tbody>tr>td,
    .ant-table.ant-table-small tfoot>tr>th,
    .ant-table.ant-table-small tfoot>tr>td {
        padding: 3px;
        color: rgba(38, 54, 89, 0.6);
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        text-align: right;
    }
    .ant-table-tbody>tr.ant-table-row-selected>td {
        background: #f8f8f8;
        border-top: 1px solid #ff7817;
        border-bottom: 1px solid #ff7817;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #ff7817;
        &::after {
            background-color: #ff7817;
        }
    }

    .ant-table-small .ant-table-selection-column{
        width: 30px;
        min-width: 30px;
    }
}

.form-ofertas-produtos {
    .input-debito-concorrente{
        text-align: right;
    }
    .tooltip-info{
        width: 12.5px;
        height: 12.5px;
    }
    .ant-form-item-label>label {
        font-size: 13px;
        font-weight: 400px;
        color: #676a6c;
    }
    .ant-picker-input>input {
        text-align: right;
    }
    .col-valor-solicitado {
        
        .ant-form-item-label{
            padding-bottom: 5px;
        }
        .ant-form-item {
            margin-bottom: 8px;

            &.disabled-valor-solicitado{
                .ant-form-item-label{

                    .ant-select-selector{
                        color: #263659;
                        cursor: initial;
                    }
                }
                .ant-form-item-control-input{
                    .ant-form-item-control-input-content{
                        .ant-input{
                            cursor: initial;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

}

p.info-limite-parcela {
    color: red;
    font-size: 12px;
}

.btn-selecionar-contratos {
    border-radius: 5px;
    width: 100%;
    min-height: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contratos-title {
    font-size: 13px;
    color: #686b6d;
}

.modal-refin {
    .title-label h3 {
        font-size: 14px;
    }
    .title-line {
        width: 20px;
    }
    .table {
        margin-top: 10px;
        font-size: 13px;
    }
    .table thead tr th {
        color: #646b70;
    }
    .table>tbody>tr>td {
        color: #686b6d;
    }
    .btn {
        padding: 2px 10px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #26417b;
        border-color: #26417b;
    }
}

.dataVencimento {
    input {
        text-align: left;
        color: black;
    }
}

.ant-tooltip.tooltip-selecao-oferta-form {
    max-width: 515px;
    .ant-tooltip-arrow{
        width: 22px;
        .ant-tooltip-arrow-content{
            width: 20px;
            height: 13px;
            top: -7px;
            background:#191919 !important;
        }
    }
    .ant-tooltip-inner{
        padding: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #FFFFFF;
        .itens-list-tooltip{
            margin-left: 30px;
        }
    }
    
}
.btn-consultar-valores{
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    color: #FFF;
    justify-content: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.5px; 
    height: 40px;
    align-items: center;
    display: flex;
}